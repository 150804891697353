import {RemoteBase, RemoteEndpoint_Impl_} from "./Remote"
import {Single} from "../../streams/Stream"
import {Writer8} from "../../json/Writer8"
import {RealSourceTools} from "../../io/Source"
import {HeaderField, HeaderValue_Impl_} from "../../http/Header"
import {Promise_Impl_} from "../../core/Promise"
import {Outcome} from "../../core/Outcome"
import {LazyConst} from "../../core/Lazy"
import {SyncFuture} from "../../core/Future"
import {TypedError} from "../../core/Error"
import {ByteChunk} from "../../chunk/ByteChunk"
import {Bytes} from "../../../haxe/io/Bytes"
import {Register} from "../../../genes/Register"

export const Remote20 = Register.global("$hxClasses")["tink.web.proxy.Remote20"] = 
class Remote20 extends Register.inherits(RemoteBase) {
	new(client, endpoint) {
		super.new(client, endpoint);
	}
	link(cognito) {
		let __body__ = new Writer8().write({"_21": cognito});
		let this1 = "link";
		return RemoteEndpoint_Impl_.request(RemoteEndpoint_Impl_.sub(this.endpoint, {"path": [this1], "query": [], "headers": [new HeaderField("content-type", "application/json"), new HeaderField("content-length", HeaderValue_Impl_.ofInt(ByteChunk.of(Bytes.ofString(__body__)).getLength()))].concat([])}), this.client, "POST", new Single(new LazyConst(ByteChunk.of(Bytes.ofString(__body__)))), function (header, body) {
			if (header.statusCode >= 400) {
				return Promise_Impl_.next(RealSourceTools.all(body), function (chunk) {
					return new SyncFuture(new LazyConst(Outcome.Failure(new TypedError(header.statusCode, chunk.toString(), {"fileName": "tink/web/macros/Proxify.hx", "lineNumber": 172, "className": "tink.web.proxy.Remote20", "methodName": "link"}))));
				});
			} else {
				return Promise_Impl_.NOISE;
			};
		});
	}
	static get __name__() {
		return "tink.web.proxy.Remote20"
	}
	static get __super__() {
		return RemoteBase
	}
	get __class__() {
		return Remote20
	}
}

