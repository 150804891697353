import {Outcome} from "./Outcome"
import {Lazy_Impl_, LazyFunc, LazyConst} from "./Lazy"
import {TypedError} from "./Error"
import {Callback_Impl_, CallbackLinkRef, LinkPair, CallbackLink_Impl_, CallbackList, ListCell} from "./Callback"
import {Register} from "../../genes/Register"

export const FutureObject = {}

export const NeverFuture = Register.global("$hxClasses")["tink.core._Future.NeverFuture"] = 
class NeverFuture extends Register.inherits() {
	new() {
	}
	getStatus() {
		return FutureStatus.NeverEver;
	}
	handle(callback) {
		return null;
	}
	eager() {
	}
	static get __name__() {
		return "tink.core._Future.NeverFuture"
	}
	static get __interfaces__() {
		return [FutureObject]
	}
	get __class__() {
		return NeverFuture
	}
}


Register.createStatic(NeverFuture, "inst", function () { return new NeverFuture() })
export const SyncFuture = Register.global("$hxClasses")["tink.core._Future.SyncFuture"] = 
class SyncFuture extends Register.inherits() {
	new(value) {
		this.value = value;
	}
	getStatus() {
		return FutureStatus.Ready(this.value);
	}
	handle(cb) {
		Callback_Impl_.invoke(cb, Lazy_Impl_.get(this.value));
		return null;
	}
	eager() {
		if (!this.value.isComputed()) {
			Lazy_Impl_.get(this.value);
		};
	}
	static get __name__() {
		return "tink.core._Future.SyncFuture"
	}
	static get __interfaces__() {
		return [FutureObject]
	}
	get __class__() {
		return SyncFuture
	}
}


export const Future_Impl_ = Register.global("$hxClasses")["tink.core._Future.Future_Impl_"] = 
class Future_Impl_ {
	
	/**
	*  Creates a new future by applying a transform function to the result.
	*  Different from `flatMap`, the transform function of `map` returns a sync value
	*/
	static map(this1, f, gather = null) {
		let _g = this1.getStatus();
		switch (_g._hx_index) {
			case 3:
				let l = _g.result;
				let this2 = l;
				let f1 = f;
				return new SyncFuture(new LazyFunc(function () {
					return f1(this2.get());
				}, this2));
				break
			case 4:
				return Future_Impl_.NEVER;
				break
			default:
			return new SuspendableFuture(function (fire) {
				return this1.handle(function (v) {
					fire(f(v));
				});
			});
			
		};
	}
	
	/**
	*  Creates a new future by applying a transform function to the result.
	*  Different from `map`, the transform function of `flatMap` returns a `Future`
	*/
	static flatMap(this1, next, gather = null) {
		let _g = this1.getStatus();
		switch (_g._hx_index) {
			case 3:
				let l = _g.result;
				return new SuspendableFuture(function (fire) {
					return next(Lazy_Impl_.get(l)).handle(function (v) {
						fire(v);
					});
				});
				break
			case 4:
				return Future_Impl_.NEVER;
				break
			default:
			return new SuspendableFuture(function ($yield) {
				let inner = new CallbackLinkRef();
				let outer = this1.handle(function (v) {
					let outer = next(v).handle($yield);
					inner.link = outer;
				});
				return new LinkPair(outer, inner);
			});
			
		};
	}
	
	/**
	*  Casts a js Promise into a Surprise
	*/
	static ofJsPromise(promise) {
		return Future_Impl_.irreversible(function (cb) {
			promise.then(function (a) {
				let _g = cb;
				let a1 = Outcome.Success(a);
				Callback_Impl_.defer(function () {
					_g(a1);
				});
			}, function (e) {
				cb(Outcome.Failure(TypedError.withData(null, e.message, e, {"fileName": "tink/core/Future.hx", "lineNumber": 156, "className": "tink.core._Future.Future_Impl_", "methodName": "ofJsPromise"})));
			});
		});
	}
	static processMany(a, concurrency = null, fn, lift) {
		if (a.length == 0) {
			return new SyncFuture(new LazyConst(lift(Outcome.Success([]))));
		} else {
			let this1 = new SuspendableFuture(function ($yield) {
				let links = new Array();
				let _g = [];
				let _g1 = 0;
				while (_g1 < a.length) {
					let x = a[_g1];
					++_g1;
					_g.push(null);
				};
				let ret = _g;
				let index = 0;
				let pending = 0;
				let done = false;
				let concurrency1;
				if (concurrency == null) {
					concurrency1 = a.length;
				} else {
					let v = concurrency;
					concurrency1 = (v < 1) ? 1 : (v > a.length) ? a.length : v;
				};
				let fireWhenReady = function () {
					if (index == ret.length) {
						if (pending == 0) {
							let v = lift(Outcome.Success(ret));
							done = true;
							$yield(v);
							return true;
						} else {
							return false;
						};
					} else {
						return false;
					};
				};
				let step = null;
				step = function () {
					if (!done && !fireWhenReady()) {
						while (index < ret.length) {
							index += 1;
							let index1 = index - 1;
							let p = a[index1];
							let check = function (o) {
								let _g = fn(o);
								switch (_g._hx_index) {
									case 0:
										let v = _g.data;
										ret[index1] = v;
										fireWhenReady();
										break
									case 1:
										let e = _g.failure;
										let _g1 = 0;
										while (_g1 < links.length) {
											let l = links[_g1];
											++_g1;
											if (l != null) {
												l.cancel();
											};
										};
										let v1 = lift(Outcome.Failure(e));
										done = true;
										$yield(v1);
										break
									
								};
							};
							let _g = p.getStatus();
							if (_g._hx_index == 3) {
								let _hx_tmp;
								_hx_tmp = Lazy_Impl_.get(_g.result);
								let v = _hx_tmp;
								check(v);
								if (!done) {
									continue;
								};
							} else {
								pending += 1;
								links.push(p.handle(function (o) {
									pending -= 1;
									check(o);
									if (!done) {
										step();
									};
								}));
							};
							break;
						};
					};
				};
				let _g2 = 0;
				let _g3 = concurrency1;
				while (_g2 < _g3) {
					let i = _g2++;
					step();
				};
				return CallbackLink_Impl_.fromMany(links);
			});
			return this1;
		};
	}
	static async(init, lazy = false) {
		let ret = Future_Impl_.irreversible(init);
		if (lazy) {
			return ret;
		} else {
			ret.eager();
			return ret;
		};
	}
	
	/**
	* Creates an irreversible future:
	* `init` gets called, when the first handler is registered or `eager()` is called.
	* The future is never suspended again. When possible, use `new Future()` instead.
	*/
	static irreversible(init) {
		return new SuspendableFuture(function ($yield) {
			init($yield);
			return null;
		});
	}
	static get __name__() {
		return "tink.core._Future.Future_Impl_"
	}
	get __class__() {
		return Future_Impl_
	}
}


Register.createStatic(Future_Impl_, "NEVER", function () { return NeverFuture.inst })
export const FutureStatus = 
Register.global("$hxEnums")["tink.core.FutureStatus"] = 
{
	__ename__: "tink.core.FutureStatus",
	
	Suspended: {_hx_name: "Suspended", _hx_index: 0, __enum__: "tink.core.FutureStatus"},
	Awaited: {_hx_name: "Awaited", _hx_index: 1, __enum__: "tink.core.FutureStatus"},
	EagerlyAwaited: {_hx_name: "EagerlyAwaited", _hx_index: 2, __enum__: "tink.core.FutureStatus"},
	Ready: Object.assign((result) => ({_hx_index: 3, __enum__: "tink.core.FutureStatus", result}), {_hx_name: "Ready", __params__: ["result"]}),
	NeverEver: {_hx_name: "NeverEver", _hx_index: 4, __enum__: "tink.core.FutureStatus"}
}
FutureStatus.__constructs__ = ["Suspended", "Awaited", "EagerlyAwaited", "Ready", "NeverEver"]
FutureStatus.__empty_constructs__ = [FutureStatus.Suspended, FutureStatus.Awaited, FutureStatus.EagerlyAwaited, FutureStatus.NeverEver]

export const FutureTrigger = Register.global("$hxClasses")["tink.core.FutureTrigger"] = 
class FutureTrigger extends Register.inherits() {
	new() {
		this.status = FutureStatus.Awaited;
		this.list = new CallbackList(true);
	}
	getStatus() {
		return this.status;
	}
	handle(callback) {
		let _g = this.status;
		if (_g._hx_index == 3) {
			let result = _g.result;
			Callback_Impl_.invoke(callback, Lazy_Impl_.get(result));
			return null;
		} else {
			let v = _g;
			let _this = this.list;
			if (_this.disposeHandlers == null) {
				return null;
			} else {
				let node = new ListCell(callback, _this);
				_this.cells.push(node);
				if (_this.used++ == 0) {
					let fn = _this.onfill;
					if (Callback_Impl_.depth < 500) {
						Callback_Impl_.depth++;
						fn();
						Callback_Impl_.depth--;
					} else {
						Callback_Impl_.defer(fn);
					};
				};
				return node;
			};
		};
	}
	eager() {
	}
	
	/**
	*  Triggers a value for this future
	*/
	trigger(result) {
		let _g = this.status;
		if (_g._hx_index == 3) {
			let _g1 = _g.result;
			return false;
		} else {
			this.status = FutureStatus.Ready(new LazyConst(result));
			this.list.invoke(result);
			return true;
		};
	}
	static get __name__() {
		return "tink.core.FutureTrigger"
	}
	static get __interfaces__() {
		return [FutureObject]
	}
	get __class__() {
		return FutureTrigger
	}
}


export const SuspendableFuture = Register.global("$hxClasses")["tink.core._Future.SuspendableFuture"] = 
class SuspendableFuture extends Register.inherits() {
	new(wakeup) {
		this.status = FutureStatus.Suspended;
		let _gthis = this;
		this.wakeup = wakeup;
		this.callbacks = new CallbackList(true);
		this.callbacks.ondrain = function () {
			if (_gthis.status == FutureStatus.Awaited) {
				_gthis.status = FutureStatus.Suspended;
				let this1 = _gthis.link;
				if (this1 != null) {
					this1.cancel();
				};
				_gthis.link = null;
			};
		};
		this.callbacks.onfill = function () {
			if (_gthis.status == FutureStatus.Suspended) {
				_gthis.status = FutureStatus.Awaited;
				_gthis.arm();
			};
		};
	}
	getStatus() {
		return this.status;
	}
	trigger(value) {
		let _g = this.status;
		if (_g._hx_index == 3) {
			let _g1 = _g.result;
		} else {
			this.status = FutureStatus.Ready(new LazyConst(value));
			let link = this.link;
			this.link = null;
			this.wakeup = null;
			this.callbacks.invoke(value);
			if (link != null) {
				link.cancel();
			};
		};
	}
	handle(callback) {
		let _g = this.status;
		if (_g._hx_index == 3) {
			let result = _g.result;
			Callback_Impl_.invoke(callback, Lazy_Impl_.get(result));
			return null;
		} else {
			let _this = this.callbacks;
			if (_this.disposeHandlers == null) {
				return null;
			} else {
				let node = new ListCell(callback, _this);
				_this.cells.push(node);
				if (_this.used++ == 0) {
					let fn = _this.onfill;
					if (Callback_Impl_.depth < 500) {
						Callback_Impl_.depth++;
						fn();
						Callback_Impl_.depth--;
					} else {
						Callback_Impl_.defer(fn);
					};
				};
				return node;
			};
		};
	}
	arm() {
		let _gthis = this;
		this.link = this.wakeup(function (x) {
			_gthis.trigger(x);
		});
	}
	eager() {
		switch (this.status._hx_index) {
			case 0:
				this.status = FutureStatus.EagerlyAwaited;
				this.arm();
				break
			case 1:
				this.status = FutureStatus.EagerlyAwaited;
				break
			default:
			
		};
	}
	static get __name__() {
		return "tink.core._Future.SuspendableFuture"
	}
	static get __interfaces__() {
		return [FutureObject]
	}
	get __class__() {
		return SuspendableFuture
	}
}

