import {RemoteBase, RemoteEndpoint_Impl_, ResponseReader_Impl_} from "./Remote"
import {Single} from "../../streams/Stream"
import {Writer3} from "../../json/Writer3"
import {Parser8} from "../../json/Parser8"
import {HeaderField, HeaderValue_Impl_} from "../../http/Header"
import {LazyConst} from "../../core/Lazy"
import {ByteChunk} from "../../chunk/ByteChunk"
import {Bytes} from "../../../haxe/io/Bytes"
import {Register} from "../../../genes/Register"

export const Remote10 = Register.global("$hxClasses")["tink.web.proxy.Remote10"] = 
class Remote10 extends Register.inherits(RemoteBase) {
	new(client, endpoint) {
		super.new(client, endpoint);
	}
	create(body) {
		let __body__ = new Writer3().write({"_9": body.attachments, "_10": body.text});
		return RemoteEndpoint_Impl_.request(RemoteEndpoint_Impl_.sub(this.endpoint, {"path": [], "query": [], "headers": [new HeaderField("content-type", "application/json"), new HeaderField("content-length", HeaderValue_Impl_.ofInt(ByteChunk.of(Bytes.ofString(__body__)).getLength())), new HeaderField("accept", "application/json")].concat([])}), this.client, "POST", new Single(new LazyConst(ByteChunk.of(Bytes.ofString(__body__)))), ResponseReader_Impl_.ofStringReader((o=>Register.bind(o, o.tryParse))(new Parser8())));
	}
	static get __name__() {
		return "tink.web.proxy.Remote10"
	}
	static get __super__() {
		return RemoteBase
	}
	get __class__() {
		return Remote10
	}
}

