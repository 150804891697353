import {Exception} from "../../haxe/Exception"
import {Register} from "../../genes/Register"

export const Host_Impl_ = Register.global("$hxClasses")["tink.url._Host.Host_Impl_"] = 
class Host_Impl_ {
	static _new(name, port = null) {
		let this1;
		if (port == null) {
			this1 = name;
		} else if (port > 65535 || port <= 0) {
			throw Exception.thrown("Invalid port");
		} else {
			this1 = "" + name + ":" + port;
		};
		return this1;
	}
	static get __name__() {
		return "tink.url._Host.Host_Impl_"
	}
	get __class__() {
		return Host_Impl_
	}
}

