import {Exception} from "./haxe/Exception"
import {Register} from "./genes/Register"

/**
The EReg class represents regular expressions.

While basic usage and patterns consistently work across platforms, some more
complex operations may yield different results. This is a necessary trade-
off to retain a certain level of performance.

EReg instances can be created by calling the constructor, or with the
special syntax `~/pattern/modifier`

EReg instances maintain an internal state, which is affected by several of
its methods.

A detailed explanation of the supported operations is available at
<https://haxe.org/manual/std-regex.html>
*/
export const EReg = Register.global("$hxClasses")["EReg"] = 
class EReg extends Register.inherits() {
	new(r, opt) {
		this.r = new RegExp(r, opt.split("u").join(""));
	}
	
	/**
	Tells if `this` regular expression matches String `s`.
	
	This method modifies the internal state.
	
	If `s` is `null`, the result is unspecified.
	*/
	match(s) {
		if (this.r.global) {
			this.r.lastIndex = 0;
		};
		this.r.m = this.r.exec(s);
		this.r.s = s;
		return this.r.m != null;
	}
	
	/**
	Returns the matched sub-group `n` of `this` EReg.
	
	This method should only be called after `this.match` or
	`this.matchSub`, and then operates on the String of that operation.
	
	The index `n` corresponds to the n-th set of parentheses in the pattern
	of `this` EReg. If no such sub-group exists, the result is unspecified.
	
	If `n` equals 0, the whole matched substring is returned.
	*/
	matched(n) {
		if (this.r.m != null && n >= 0 && n < this.r.m.length) {
			return this.r.m[n];
		} else {
			throw Exception.thrown("EReg::matched");
		};
	}
	static get __name__() {
		return "EReg"
	}
	get __class__() {
		return EReg
	}
}

