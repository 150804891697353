import {Observable_Impl_, ConstObservable} from "../../../tink/state/Observable"
import {ClassName_Impl_} from "../../../tink/domspec/ClassName"
import {Register} from "../../../genes/Register"
import {Slot} from "../../../coconut/ui/internal/Slot"
import {View} from "../../../coconut/react/View"
import {Html} from "../../../coconut/react/Html"
import * as Styles from "@material-ui/core/styles"

export const PngIcon = Register.global("$hxClasses")["letzbig.ui.component.PngIcon"] = 
class PngIcon extends Register.inherits(View) {
	new(__coco_data_) {
		this.__coco_classes = new Slot(this);
		this.__coco_className = new Slot(this, null, null);
		this.__coco_name = new Slot(this, null, null);
		let _gthis = this;
		this.__initAttributes(__coco_data_);
		let snapshot = null;
		super.new(function () {
			return _gthis.__coco_render();
		}, null, null, null, null);
	}
	__coco_render() {
		return Html.h("img", {"className": ClassName_Impl_.add(Observable_Impl_.get_value(this.__coco_className), Observable_Impl_.get_value(this.__coco_classes).image), "src": this.getSrc()});
	}
	getSrc() {
		switch (Math.ceil(window.devicePixelRatio)) {
			case 1:
				return "/image/icon/" + Observable_Impl_.get_value(this.__coco_name) + ".png";
				break
			case 2:
				return "/image/icon/" + Observable_Impl_.get_value(this.__coco_name) + "@2x.png";
				break
			case 3:
				return "/image/icon/" + Observable_Impl_.get_value(this.__coco_name) + "@3x.png";
				break
			default:
			return "/image/icon/" + Observable_Impl_.get_value(this.__coco_name) + "@4x.png";
			
		};
	}
	__initAttributes(attributes) {
		this.__coco_className.setData(attributes.className);
		this.__coco_name.setData(attributes.name);
		let value = attributes.classes;
		this.__coco_classes.setData(new ConstObservable(value, null));
	}
	static styles(theme) {
		return {"image": {"width": theme.spacing(3), "height": theme.spacing(3)}};
	}
	static fromHxx(hxxMeta, attributes) {
		return {"$$typeof": View.TRE, "type": PngIcon.__hoc, "props": attributes, "key": hxxMeta.key, "ref": (!hxxMeta.ref) ? null : hxxMeta.ref};
	}
	static get __name__() {
		return "letzbig.ui.component.PngIcon"
	}
	static get __super__() {
		return View
	}
	get __class__() {
		return PngIcon
	}
}


PngIcon.__hoc = (Styles.withStyles(PngIcon.styles))(PngIcon)