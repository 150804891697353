import {Register} from "./genes/Register"

/**
The `Lambda` class is a collection of methods to support functional
programming. It is ideally used with `using Lambda` and then acts as an
extension to Iterable types.

On static platforms, working with the Iterable structure might be slower
than performing the operations directly on known types, such as Array and
List.

If the first argument to any of the methods is null, the result is
unspecified.

@see https://haxe.org/manual/std-Lambda.html
*/
export const Lambda = Register.global("$hxClasses")["Lambda"] = 
class Lambda {
	
	/**
	Tells if `it` contains an element for which `f` is true.
	
	This function returns true as soon as an element is found for which a
	call to `f` returns true.
	
	If no such element is found, the result is false.
	
	If `f` is null, the result is unspecified.
	*/
	static exists(it, f) {
		let x = Register.iter(it);
		while (x.hasNext()) {
			let x1 = x.next();
			if (f(x1)) {
				return true;
			};
		};
		return false;
	}
	
	/**
	Functional fold on Iterable `it`, using function `f` with start argument
	`first`.
	
	If `it` has no elements, the result is `first`.
	
	Otherwise the first element of `it` is passed to `f` alongside `first`.
	The result of that call is then passed to `f` with the next element of
	`it`, and so on until `it` has no more elements.
	
	If `it` or `f` are null, the result is unspecified.
	*/
	static fold(it, f, first) {
		let x = Register.iter(it);
		while (x.hasNext()) {
			let x1 = x.next();
			first = f(x1, first);
		};
		return first;
	}
	static get __name__() {
		return "Lambda"
	}
	get __class__() {
		return Lambda
	}
}

