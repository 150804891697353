import {SimpleDisposable} from "./Disposable"
import {Timer} from "../../haxe/Timer"
import {Exception} from "../../haxe/Exception"
import {Register} from "../../genes/Register"

export const Callback_Impl_ = Register.global("$hxClasses")["tink.core._Callback.Callback_Impl_"] = 
class Callback_Impl_ {
	static invoke(this1, data) {
		if (Callback_Impl_.depth < 500) {
			Callback_Impl_.depth++;
			this1(data);
			Callback_Impl_.depth--;
		} else {
			Callback_Impl_.defer(function () {
				this1(data);
			});
		};
	}
	static defer(f) {
		Timer.delay(f, 0);
	}
	static get __name__() {
		return "tink.core._Callback.Callback_Impl_"
	}
	get __class__() {
		return Callback_Impl_
	}
}


Callback_Impl_.depth = 0
export const LinkObject = {}

export const CallbackLinkRef = Register.global("$hxClasses")["tink.core.CallbackLinkRef"] = 
class CallbackLinkRef extends Register.inherits() {
	new() {
	}
	cancel() {
		let this1 = this.link;
		if (this1 != null) {
			this1.cancel();
		};
	}
	static get __name__() {
		return "tink.core.CallbackLinkRef"
	}
	static get __interfaces__() {
		return [LinkObject]
	}
	get __class__() {
		return CallbackLinkRef
	}
}


export const CallbackLink_Impl_ = Register.global("$hxClasses")["tink.core._Callback.CallbackLink_Impl_"] = 
class CallbackLink_Impl_ {
	static fromMany(callbacks) {
		let this1 = new SimpleLink(function () {
			if (callbacks != null) {
				let _g = 0;
				while (_g < callbacks.length) {
					let cb = callbacks[_g];
					++_g;
					if (cb != null) {
						cb.cancel();
					};
				};
			} else {
				callbacks = null;
			};
		});
		return this1;
	}
	static get __name__() {
		return "tink.core._Callback.CallbackLink_Impl_"
	}
	get __class__() {
		return CallbackLink_Impl_
	}
}


export const SimpleLink = Register.global("$hxClasses")["tink.core.SimpleLink"] = 
class SimpleLink extends Register.inherits() {
	new(f) {
		this.f = f;
	}
	cancel() {
		if (this.f != null) {
			this.f();
			this.f = null;
		};
	}
	static get __name__() {
		return "tink.core.SimpleLink"
	}
	static get __interfaces__() {
		return [LinkObject]
	}
	get __class__() {
		return SimpleLink
	}
}


export const LinkPair = Register.global("$hxClasses")["tink.core._Callback.LinkPair"] = 
class LinkPair extends Register.inherits() {
	new(a, b) {
		this.dissolved = false;
		this.a = a;
		this.b = b;
	}
	cancel() {
		if (!this.dissolved) {
			this.dissolved = true;
			let this1 = this.a;
			if (this1 != null) {
				this1.cancel();
			};
			let this2 = this.b;
			if (this2 != null) {
				this2.cancel();
			};
			this.a = null;
			this.b = null;
		};
	}
	static get __name__() {
		return "tink.core._Callback.LinkPair"
	}
	static get __interfaces__() {
		return [LinkObject]
	}
	get __class__() {
		return LinkPair
	}
}


export const ListCell = Register.global("$hxClasses")["tink.core._Callback.ListCell"] = 
class ListCell extends Register.inherits() {
	new(cb, list) {
		if (cb == null) {
			throw Exception.thrown("callback expected but null received");
		};
		this.cb = cb;
		this.list = list;
	}
	cancel() {
		if (this.list != null) {
			let list = this.list;
			this.cb = null;
			this.list = null;
			if (--list.used <= list.cells.length >> 1) {
				list.compact();
			};
		};
	}
	static get __name__() {
		return "tink.core._Callback.ListCell"
	}
	static get __interfaces__() {
		return [LinkObject]
	}
	get __class__() {
		return ListCell
	}
}


export const CallbackList = Register.global("$hxClasses")["tink.core.CallbackList"] = 
class CallbackList extends Register.inherits(SimpleDisposable) {
	new(destructive = false) {
		this.onfill = function () {
		};
		this.ondrain = function () {
		};
		this.busy = false;
		this.queue = [];
		this.used = 0;
		let _gthis = this;
		super.new(function () {
			if (!_gthis.busy) {
				_gthis.destroy();
			};
		});
		this.destructive = destructive;
		this.cells = [];
	}
	destroy() {
		let _g = 0;
		let _g1 = this.cells;
		while (_g < _g1.length) {
			let c = _g1[_g];
			++_g;
			c.cb = null;
			c.list = null;
		};
		this.queue = null;
		this.cells = null;
		if (this.used > 0) {
			this.used = 0;
			let fn = this.ondrain;
			if (Callback_Impl_.depth < 500) {
				Callback_Impl_.depth++;
				fn();
				Callback_Impl_.depth--;
			} else {
				Callback_Impl_.defer(fn);
			};
		};
	}
	invoke(data) {
		let _gthis = this;
		if (Callback_Impl_.depth < 500) {
			Callback_Impl_.depth++;
			if (_gthis.disposeHandlers != null) {
				if (_gthis.busy) {
					if (_gthis.destructive != true) {
						let _g = Register.bind(_gthis, _gthis.invoke);
						let data1 = data;
						let tmp = function () {
							_g(data1);
						};
						_gthis.queue.push(tmp);
					};
				} else {
					_gthis.busy = true;
					if (_gthis.destructive) {
						_gthis.dispose();
					};
					let length = _gthis.cells.length;
					let _g = 0;
					let _g1 = length;
					while (_g < _g1) {
						let i = _g++;
						let _this = _gthis.cells[i];
						if (_this.list != null) {
							_this.cb(data);
						};
					};
					_gthis.busy = false;
					if (_gthis.disposeHandlers == null) {
						_gthis.destroy();
					} else {
						if (_gthis.used < _gthis.cells.length) {
							_gthis.compact();
						};
						if (_gthis.queue.length > 0) {
							(_gthis.queue.shift())();
						};
					};
				};
			};
			Callback_Impl_.depth--;
		} else {
			Callback_Impl_.defer(function () {
				if (_gthis.disposeHandlers != null) {
					if (_gthis.busy) {
						if (_gthis.destructive != true) {
							let _g = Register.bind(_gthis, _gthis.invoke);
							let data1 = data;
							let tmp = function () {
								_g(data1);
							};
							_gthis.queue.push(tmp);
						};
					} else {
						_gthis.busy = true;
						if (_gthis.destructive) {
							_gthis.dispose();
						};
						let length = _gthis.cells.length;
						let _g = 0;
						let _g1 = length;
						while (_g < _g1) {
							let i = _g++;
							let _this = _gthis.cells[i];
							if (_this.list != null) {
								_this.cb(data);
							};
						};
						_gthis.busy = false;
						if (_gthis.disposeHandlers == null) {
							_gthis.destroy();
						} else {
							if (_gthis.used < _gthis.cells.length) {
								_gthis.compact();
							};
							if (_gthis.queue.length > 0) {
								(_gthis.queue.shift())();
							};
						};
					};
				};
			});
		};
	}
	compact() {
		if (this.busy) {
			return;
		} else if (this.used == 0) {
			this.resize(0);
			let fn = this.ondrain;
			if (Callback_Impl_.depth < 500) {
				Callback_Impl_.depth++;
				fn();
				Callback_Impl_.depth--;
			} else {
				Callback_Impl_.defer(fn);
			};
		} else {
			let compacted = 0;
			let _g = 0;
			let _g1 = this.cells.length;
			while (_g < _g1) {
				let i = _g++;
				let _g1 = this.cells[i];
				let _g2 = _g1.list;
				if (_g1.cb != null) {
					let v = _g1;
					if (compacted != i) {
						this.cells[compacted] = v;
					};
					if (++compacted == this.used) {
						break;
					};
				};
			};
			this.resize(this.used);
		};
	}
	resize(length) {
		this.cells.length = length;
	}
	static get __name__() {
		return "tink.core.CallbackList"
	}
	static get __super__() {
		return SimpleDisposable
	}
	get __class__() {
		return CallbackList
	}
}

