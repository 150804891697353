import {Register} from "../../genes/Register"

export const Medium = 
Register.global("$hxEnums")["letzbig.types.Medium"] = 
{
	__ename__: "letzbig.types.Medium",
	
	Photo: Object.assign((photo) => ({_hx_index: 0, __enum__: "letzbig.types.Medium", photo}), {_hx_name: "Photo", __params__: ["photo"]}),
	Audio: Object.assign((audio) => ({_hx_index: 1, __enum__: "letzbig.types.Medium", audio}), {_hx_name: "Audio", __params__: ["audio"]}),
	Video: Object.assign((video) => ({_hx_index: 2, __enum__: "letzbig.types.Medium", video}), {_hx_name: "Video", __params__: ["video"]})
}
Medium.__constructs__ = ["Photo", "Audio", "Video"]
Medium.__empty_constructs__ = []
