import {IncomingResponse} from "../Response"
import {IncomingRequest, IncomingRequestHeader, IncomingRequestBody} from "../Request"
import {ClientObject} from "../Client"
import {Outcome} from "../../core/Outcome"
import {LazyConst} from "../../core/Lazy"
import {Future_Impl_, SyncFuture} from "../../core/Future"
import {Url_Impl_} from "../../Url"
import {Register} from "../../../genes/Register"

export const LocalContainerClient = Register.global("$hxClasses")["tink.http.clients.LocalContainerClient"] = 
class LocalContainerClient extends Register.inherits() {
	new(container) {
		this.container = container;
	}
	request(req, handlers = null) {
		let this1 = req.header.url;
		return Future_Impl_.flatMap(this.container.serve(new IncomingRequest("127.0.0.1", new IncomingRequestHeader(req.header.method, Url_Impl_.fromString((this1.query == null) ? this1.path : ((this1.path == null) ? "null" : this1.path) + "?" + ((this1.query == null) ? "null" : this1.query)), "HTTP/1.1", req.header.fields), IncomingRequestBody.Plain(req.body))), function (res) {
			return new SyncFuture(new LazyConst(Outcome.Success(new IncomingResponse(res.header, res.body))));
		});
	}
	static get __name__() {
		return "tink.http.clients.LocalContainerClient"
	}
	static get __interfaces__() {
		return [ClientObject]
	}
	get __class__() {
		return LocalContainerClient
	}
}

