import {PortionArray_Impl_} from "../../../tink/url/PortionArray"
import {Path_Impl_} from "../../../tink/url/Path"
import {Url_Impl_} from "../../../tink/Url"
import {Register} from "../../../genes/Register"

export const Route = 
Register.global("$hxEnums")["letzbig.app.data.Route"] = 
{
	__ename__: "letzbig.app.data.Route",
	
	Home: {_hx_name: "Home", _hx_index: 0, __enum__: "letzbig.app.data.Route"},
	Login: {_hx_name: "Login", _hx_index: 1, __enum__: "letzbig.app.data.Route"},
	Product: Object.assign((id, sub) => ({_hx_index: 2, __enum__: "letzbig.app.data.Route", id, sub}), {_hx_name: "Product", __params__: ["id", "sub"]}),
	NotFound: Object.assign((url) => ({_hx_index: 3, __enum__: "letzbig.app.data.Route", url}), {_hx_name: "NotFound", __params__: ["url"]})
}
Route.__constructs__ = ["Home", "Login", "Product", "NotFound"]
Route.__empty_constructs__ = [Route.Home, Route.Login]

export const Product = 
Register.global("$hxEnums")["letzbig.app.data.Product"] = 
{
	__ename__: "letzbig.app.data.Product",
	
	CreateFeedback: {_hx_name: "CreateFeedback", _hx_index: 0, __enum__: "letzbig.app.data.Product"}
}
Product.__constructs__ = ["CreateFeedback"]
Product.__empty_constructs__ = [Product.CreateFeedback]

export const RouteTools = Register.global("$hxClasses")["letzbig.app.data.RouteTools"] = 
class RouteTools {
	static fromUrl(url) {
		let _g = PortionArray_Impl_.toStringArray(Path_Impl_.parts(url.path));
		switch (_g.length) {
			case 0:
				return Route.Home;
				break
			case 1:
				let _g1 = _g[0];
				if (_g1 == null) {
					return Route.NotFound(url);
				} else if (_g1 == "login") {
					return Route.Login;
				} else {
					return Route.NotFound(url);
				};
				break
			case 4:
				let _g2 = _g[3];
				let _g3 = _g[2];
				let _g4 = _g[0];
				if (_g4 == null) {
					return Route.NotFound(url);
				} else if (_g4 == "products") {
					if (_g3 == null) {
						return Route.NotFound(url);
					} else if (_g3 == "feedbacks") {
						if (_g2 == null) {
							return Route.NotFound(url);
						} else if (_g2 == "new") {
							let id = _g[1];
							return Route.Product(id, Product.CreateFeedback);
						} else {
							return Route.NotFound(url);
						};
					} else {
						return Route.NotFound(url);
					};
				} else {
					return Route.NotFound(url);
				};
				break
			default:
			return Route.NotFound(url);
			
		};
	}
	static toUrl(route) {
		switch (route._hx_index) {
			case 0:
				return Url_Impl_.fromString("/");
				break
			case 1:
				return Url_Impl_.fromString("/login");
				break
			case 2:
				let _g = route.sub;
				let id = route.id;
				return Url_Impl_.fromString("/products/" + id + "/feedbacks/new");
				break
			case 3:
				let _g1 = route.url;
				return Url_Impl_.fromString("/");
				break
			
		};
	}
	static get __name__() {
		return "letzbig.app.data.RouteTools"
	}
	get __class__() {
		return RouteTools
	}
}

