import {Remote12} from "./Remote12"
import {RemoteBase, RemoteEndpoint_Impl_} from "./Remote"
import {Register} from "../../../genes/Register"

export const Remote1 = Register.global("$hxClasses")["tink.web.proxy.Remote1"] = 
class Remote1 extends Register.inherits(RemoteBase) {
	new(client, endpoint) {
		super.new(client, endpoint);
	}
	uploads() {
		let this1 = "uploads";
		return new Remote12(this.client, RemoteEndpoint_Impl_.sub(this.endpoint, {"path": [this1], "query": [], "headers": [].concat([])}));
	}
	static get __name__() {
		return "tink.web.proxy.Remote1"
	}
	static get __super__() {
		return RemoteBase
	}
	get __class__() {
		return Remote1
	}
}

