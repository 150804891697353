import {Browser} from "../../js/Browser"
import {Bytes} from "../io/Bytes"
import {HttpBase} from "./HttpBase"
import {Exception} from "../Exception"
import {Register} from "../../genes/Register"
import {Std} from "../../Std"
import {Lambda} from "../../Lambda"
import {EReg} from "../../EReg"

export const HttpJs = Register.global("$hxClasses")["haxe.http.HttpJs"] = 
class HttpJs extends Register.inherits(HttpBase) {
	new(url) {
		this.async = true;
		this.withCredentials = false;
		super.new(url);
	}
	request(post = null) {
		this.responseAsString = null;
		this.responseBytes = null;
		let r = this.req = Browser.createXMLHttpRequest();
		let _gthis = this;
		let onreadystatechange = function (_) {
			if (r.readyState != 4) {
				return;
			};
			let s;
			try {
				s = r.status;
			}catch (_g) {
				s = null;
			};
			if (s == 0 && typeof(window) != "undefined" && Register.$global.location != null) {
				let protocol = Register.$global.location.protocol.toLowerCase();
				let rlocalProtocol = new EReg("^(?:about|app|app-storage|.+-extension|file|res|widget):$", "");
				let isLocal = rlocalProtocol.match(protocol);
				if (isLocal) {
					s = (r.response != null) ? 200 : 404;
				};
			};
			if (s == undefined) {
				s = null;
			};
			if (s != null) {
				_gthis.onStatus(s);
			};
			if (s != null && s >= 200 && s < 400) {
				_gthis.req = null;
				_gthis.success(Bytes.ofData(r.response));
			} else if (s == null || s == 0 && r.response == null) {
				_gthis.req = null;
				_gthis.onError("Failed to connect or resolve host");
			} else if (s == null) {
				_gthis.req = null;
				let onreadystatechange = (r.response != null) ? Bytes.ofData(r.response) : null;
				_gthis.responseBytes = onreadystatechange;
				_gthis.onError("Http Error #" + r.status);
			} else {
				switch (s) {
					case 12007:
						_gthis.req = null;
						_gthis.onError("Unknown host");
						break
					case 12029:
						_gthis.req = null;
						_gthis.onError("Failed to connect to host");
						break
					default:
					_gthis.req = null;
					let onreadystatechange = (r.response != null) ? Bytes.ofData(r.response) : null;
					_gthis.responseBytes = onreadystatechange;
					_gthis.onError("Http Error #" + r.status);
					
				};
			};
		};
		if (this.async) {
			r.onreadystatechange = onreadystatechange;
		};
		let uri;
		let _g = this.postBytes;
		let _g1 = this.postData;
		if (_g1 == null) {
			if (_g == null) {
				uri = null;
			} else {
				let bytes = _g;
				uri = new Blob([bytes.b.bufferValue]);
			};
		} else if (_g == null) {
			let str = _g1;
			uri = str;
		} else {
			uri = null;
		};
		if (uri != null) {
			post = true;
		} else {
			let _g = 0;
			let _g1 = this.params;
			while (_g < _g1.length) {
				let p = _g1[_g];
				++_g;
				if (uri == null) {
					uri = "";
				} else {
					uri = ((uri == null) ? "null" : Std.string(uri)) + "&";
				};
				let s = p.name;
				let value = ((uri == null) ? "null" : Std.string(uri)) + encodeURIComponent(s) + "=";
				let s1 = p.value;
				uri = value + encodeURIComponent(s1);
			};
		};
		try {
			if (post) {
				r.open("POST", this.url, this.async);
			} else if (uri != null) {
				let question = this.url.split("?").length <= 1;
				r.open("GET", this.url + ((question) ? "?" : "&") + ((uri == null) ? "null" : Std.string(uri)), this.async);
				uri = null;
			} else {
				r.open("GET", this.url, this.async);
			};
			r.responseType = "arraybuffer";
		}catch (_g) {
			let e = Exception.caught(_g).unwrap();
			this.req = null;
			this.onError(e.toString());
			return;
		};
		r.withCredentials = this.withCredentials;
		if (!Lambda.exists(this.headers, function (h) {
			return h.name == "Content-Type";
		}) && post && this.postData == null) {
			r.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
		};
		let _g2 = 0;
		let _g3 = this.headers;
		while (_g2 < _g3.length) {
			let h = _g3[_g2];
			++_g2;
			r.setRequestHeader(h.name, h.value);
		};
		r.send(uri);
		if (!this.async) {
			onreadystatechange(null);
		};
	}
	static get __name__() {
		return "haxe.http.HttpJs"
	}
	static get __super__() {
		return HttpBase
	}
	get __class__() {
		return HttpJs
	}
}

