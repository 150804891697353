import {RemoteBase, RemoteEndpoint_Impl_, ResponseReader_Impl_} from "./Remote"
import {Single} from "../../streams/Stream"
import {Writer4} from "../../json/Writer4"
import {Parser13} from "../../json/Parser13"
import {HeaderField, HeaderValue_Impl_} from "../../http/Header"
import {LazyConst} from "../../core/Lazy"
import {ByteChunk} from "../../chunk/ByteChunk"
import {Bytes} from "../../../haxe/io/Bytes"
import {Register} from "../../../genes/Register"

export const Remote12 = Register.global("$hxClasses")["tink.web.proxy.Remote12"] = 
class Remote12 extends Register.inherits(RemoteBase) {
	new(client, endpoint) {
		super.new(client, endpoint);
	}
	create(mime) {
		let __body__ = new Writer4().write({"_13": mime});
		return RemoteEndpoint_Impl_.request(RemoteEndpoint_Impl_.sub(this.endpoint, {"path": [], "query": [], "headers": [new HeaderField("content-type", "application/json"), new HeaderField("content-length", HeaderValue_Impl_.ofInt(ByteChunk.of(Bytes.ofString(__body__)).getLength())), new HeaderField("accept", "application/json")].concat([])}), this.client, "PUT", new Single(new LazyConst(ByteChunk.of(Bytes.ofString(__body__)))), ResponseReader_Impl_.ofStringReader((o=>Register.bind(o, o.tryParse))(new Parser13())));
	}
	static get __name__() {
		return "tink.web.proxy.Remote12"
	}
	static get __super__() {
		return RemoteBase
	}
	get __class__() {
		return Remote12
	}
}

