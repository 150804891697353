import {ChunkObject} from "./ChunkObject"
import {ChunkBase} from "./ChunkBase"
import {Chunk_Impl_} from "../Chunk"
import {Bytes} from "../../haxe/io/Bytes"
import {Register} from "../../genes/Register"

export const ByteChunk = Register.global("$hxClasses")["tink.chunk.ByteChunk"] = 
class ByteChunk extends Register.inherits(ChunkBase) {
	new(data, from, to) {
		this.data = data;
		this.from = from;
		this.to = to;
	}
	flatten(into) {
		into.push(this);
	}
	getLength() {
		return this.to - this.from;
	}
	blitTo(target, offset) {
		if (this.wrapped == null) {
			this.wrapped = Bytes.ofData(this.data);
		};
		target.blit(offset, this.wrapped, this.from, this.to - this.from);
	}
	toBytes() {
		if (this.wrapped == null) {
			this.wrapped = Bytes.ofData(this.data);
		};
		return this.wrapped.sub(this.from, this.to - this.from);
	}
	toString() {
		if (this.wrapped == null) {
			this.wrapped = Bytes.ofData(this.data);
		};
		return this.wrapped.getString(this.from, this.to - this.from);
	}
	static of(b) {
		if (b.length == 0) {
			return Chunk_Impl_.EMPTY;
		};
		let ret = new ByteChunk(b.b.bufferValue, 0, b.length);
		ret.wrapped = b;
		return ret;
	}
	static get __name__() {
		return "tink.chunk.ByteChunk"
	}
	static get __interfaces__() {
		return [ChunkObject]
	}
	static get __super__() {
		return ChunkBase
	}
	get __class__() {
		return ByteChunk
	}
}

