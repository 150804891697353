import {Source_Impl_, RealSourceTools} from "../io/Source"
import {StdClient} from "./clients/StdClient"
import {LocalContainerClient} from "./clients/LocalContainerClient"
import {JsClient} from "./clients/JsClient"
import {OutgoingRequest, OutgoingRequestHeader} from "./Request"
import {Message} from "./Message"
import {CustomClient} from "./Client"
import {Promise_Impl_} from "../core/Promise"
import {Outcome} from "../core/Outcome"
import {LazyConst} from "../core/Lazy"
import {Future_Impl_, SyncFuture} from "../core/Future"
import {TypedError} from "../core/Error"
import {Url_Impl_} from "../Url"
import {EnumValueMap} from "../../haxe/ds/EnumValueMap"
import {Register} from "../../genes/Register"

export const Fetch = Register.global("$hxClasses")["tink.http.Fetch"] = 
class Fetch {
	static fetch(url, options = null) {
		return Future_Impl_.async(function (cb) {
			let uri = url.path;
			if (url.query != null) {
				uri += "?" + ((url.query == null) ? "null" : url.query);
			};
			let method = "GET";
			let headers = null;
			let body = Source_Impl_.EMPTY;
			let type = ClientType.Default;
			let followRedirect = true;
			if (options != null) {
				if (options.method != null) {
					method = options.method;
				};
				if (options.headers != null) {
					headers = options.headers;
				};
				if (options.body != null) {
					body = options.body;
				};
				if (options.client != null) {
					type = options.client;
				};
				if (options.followRedirect == false) {
					followRedirect = false;
				};
			};
			let client = Fetch.getClient(type);
			if (options != null && options.augment != null) {
				let pipeline = options.augment;
				client = CustomClient.create(client, pipeline.before, pipeline.after);
			};
			client.request(new OutgoingRequest(new OutgoingRequestHeader(method, url, null, headers), body), options.handlers).handle(function (res) {
				switch (res._hx_index) {
					case 0:
						let res1 = res.data;
						let _g = res1.header.statusCode;
						switch (_g) {
							case 301:case 302:case 303:case 307:case 308:
								let code = _g;
								if (followRedirect) {
									let this1 = "location".toLowerCase();
									Promise_Impl_.next(new SyncFuture(new LazyConst(res1.header.byName(this1))), function (location) {
										let this1 = Url_Impl_.resolve(url, Url_Impl_.fromString(location));
										let this2;
										if (code == 303) {
											let __o0 = options;
											let __tink_tmp53 = {"method": "GET"};
											let _g = __o0.headers;
											if (_g != null) {
												let v = _g;
												__tink_tmp53.headers = v;
											};
											let _g1 = __o0.handlers;
											if (_g1 != null) {
												let v = _g1;
												__tink_tmp53.handlers = v;
											};
											let _g2 = __o0.followRedirect;
											if (_g2 != null) {
												let v = _g2;
												__tink_tmp53.followRedirect = v;
											};
											let _g3 = __o0.client;
											if (_g3 != null) {
												let v = _g3;
												__tink_tmp53.client = v;
											};
											let _g4 = __o0.body;
											if (_g4 != null) {
												let v = _g4;
												__tink_tmp53.body = v;
											};
											let _g5 = __o0.augment;
											if (_g5 != null) {
												let v = _g5;
												__tink_tmp53.augment = v;
											};
											this2 = __tink_tmp53;
										} else {
											this2 = options;
										};
										return Fetch.fetch(this1, this2);
									}).handle(cb);
								} else {
									cb(Outcome.Success(res1));
								};
								break
							default:
							cb(Outcome.Success(res1));
							
						};
						break
					case 1:
						let e = res.failure;
						cb(Outcome.Failure(e));
						break
					
				};
			});
		});
	}
	static getClient(type) {
		if (!Fetch.cache.exists(type)) {
			let c;
			switch (type._hx_index) {
				case 0:
					c = new JsClient();
					break
				case 1:
					let c1 = type.container;
					c = new LocalContainerClient(c1);
					break
				case 2:
					c = new StdClient();
					break
				case 3:
					let c2 = type.v;
					c = c2;
					break
				
			};
			Fetch.cache.set(type, c);
		};
		return Fetch.cache.get(type);
	}
	static get __name__() {
		return "tink.http.Fetch"
	}
	get __class__() {
		return Fetch
	}
}


Fetch.cache = new EnumValueMap()
export const ClientType = 
Register.global("$hxEnums")["tink.http.ClientType"] = 
{
	__ename__: "tink.http.ClientType",
	
	Default: {_hx_name: "Default", _hx_index: 0, __enum__: "tink.http.ClientType"},
	Local: Object.assign((container) => ({_hx_index: 1, __enum__: "tink.http.ClientType", container}), {_hx_name: "Local", __params__: ["container"]}),
	StdLib: {_hx_name: "StdLib", _hx_index: 2, __enum__: "tink.http.ClientType"},
	Custom: Object.assign((v) => ({_hx_index: 3, __enum__: "tink.http.ClientType", v}), {_hx_name: "Custom", __params__: ["v"]})
}
ClientType.__constructs__ = ["Default", "Local", "StdLib", "Custom"]
ClientType.__empty_constructs__ = [ClientType.Default, ClientType.StdLib]

export const FetchResponse_Impl_ = Register.global("$hxClasses")["tink.http._Fetch.FetchResponse_Impl_"] = 
class FetchResponse_Impl_ {
	static all(this1) {
		return Promise_Impl_.next(this1, function (r) {
			return Promise_Impl_.next(RealSourceTools.all(r.body), function (chunk) {
				if (r.header.statusCode >= 400) {
					return new SyncFuture(new LazyConst(Outcome.Failure(TypedError.withData(r.header.statusCode, r.header.reason, chunk.toString(), {"fileName": "tink/http/Fetch.hx", "lineNumber": 126, "className": "tink.http._Fetch.FetchResponse_Impl_", "methodName": "all"}))));
				} else {
					return new SyncFuture(new LazyConst(Outcome.Success(new Message(r.header, chunk))));
				};
			});
		});
	}
	static get __name__() {
		return "tink.http._Fetch.FetchResponse_Impl_"
	}
	get __class__() {
		return FetchResponse_Impl_
	}
}

