import {Observable_Impl_, ConstObservable} from "../../../../tink/state/Observable"
import {ReactType_Impl_} from "../../../../react/ReactType"
import * as React from "react"
import {Register} from "../../../../genes/Register"
import {Slot} from "../../../../coconut/ui/internal/Slot"
import {View} from "../../../../coconut/react/View"
import * as Styles from "@material-ui/core/styles"
import {Container} from "@material-ui/core"

/**
* Basic page that defines basic layouts such as width/height
*/
export const Page = Register.global("$hxClasses")["letzbig.ui.page.app.Page"] = 
class Page extends Register.inherits(View) {
	new(__coco_data_) {
		this.__coco_classes = new Slot(this);
		this.__coco_children = new Slot(this, null, null);
		let _gthis = this;
		this.__initAttributes(__coco_data_);
		let snapshot = null;
		super.new(function () {
			return _gthis.__coco_render();
		}, null, null, null, null);
	}
	__coco_render() {
		let props = {"className": Observable_Impl_.get_value(this.__coco_classes).container, "maxWidth": "sm", "disableGutters": true};
		let __r = [];
		let _g = 0;
		let _g1 = Observable_Impl_.get_value(this.__coco_children);
		while (_g < ((_g1 == null) ? 0 : _g1.length)) {
			let _0 = (_g1 == null) ? null : _g1[_g];
			++_g;
			__r.push(_0);
		};
		let tmp = [ReactType_Impl_.fromComp(Container), props].concat(__r);
		return React.createElement.apply(React, tmp);
	}
	__initAttributes(attributes) {
		this.__coco_children.setData(attributes.children);
		let value = attributes.classes;
		this.__coco_classes.setData(new ConstObservable(value, null));
	}
	static styles(theme) {
		return {"container": {"overflow": "hidden", "height": "100%", "display": "flex", "flexDirection": "column"}};
	}
	static fromHxx(hxxMeta, attributes) {
		return {"$$typeof": View.TRE, "type": Page.__hoc, "props": attributes, "key": hxxMeta.key, "ref": (!hxxMeta.ref) ? null : hxxMeta.ref};
	}
	static get __name__() {
		return "letzbig.ui.page.app.Page"
	}
	static get __super__() {
		return View
	}
	get __class__() {
		return Page
	}
}


Page.__hoc = (Styles.withStyles(Page.styles))(Page)