import {ConstObservable, Observable_Impl_} from "../../../tink/state/Observable"
import {ClassName_Impl_} from "../../../tink/domspec/ClassName"
import {StringMap} from "../../../haxe/ds/StringMap"
import {Register} from "../../../genes/Register"
import {Slot} from "../../../coconut/ui/internal/Slot"
import {View} from "../../../coconut/react/View"
import {Html} from "../../../coconut/react/Html"
import * as Styles from "@material-ui/core/styles"

export const SafeAreaView = Register.global("$hxClasses")["letzbig.ui.component.SafeAreaView"] = 
class SafeAreaView extends Register.inherits(View) {
	new(__coco_data_) {
		this.__coco_classes = new Slot(this);
		this.__coco_children = new Slot(this, null, null);
		let _gthis = this;
		this.__coco_top = new Slot(this, null, new ConstObservable(false, null));
		this.__coco_left = new Slot(this, null, new ConstObservable(false, null));
		this.__coco_right = new Slot(this, null, new ConstObservable(false, null));
		this.__coco_bottom = new Slot(this, null, new ConstObservable(false, null));
		this.__initAttributes(__coco_data_);
		let snapshot = null;
		super.new(function () {
			return _gthis.__coco_render();
		}, null, null, null, null);
	}
	__coco_render() {
		let _g = new StringMap();
		let key = Observable_Impl_.get_value(this.__coco_classes).top;
		let value = Observable_Impl_.get_value(this.__coco_top);
		_g.inst.set(key, value);
		let key1 = Observable_Impl_.get_value(this.__coco_classes).right;
		let value1 = Observable_Impl_.get_value(this.__coco_right);
		_g.inst.set(key1, value1);
		let key2 = Observable_Impl_.get_value(this.__coco_classes).bottom;
		let value2 = Observable_Impl_.get_value(this.__coco_bottom);
		_g.inst.set(key2, value2);
		let key3 = Observable_Impl_.get_value(this.__coco_classes).left;
		let value3 = Observable_Impl_.get_value(this.__coco_left);
		_g.inst.set(key3, value3);
		let attributes = {"className": ClassName_Impl_.ofMap(_g)};
		let __r = [];
		let _g1 = 0;
		let _g2 = Observable_Impl_.get_value(this.__coco_children);
		while (_g1 < ((_g2 == null) ? 0 : _g2.length)) {
			let _0 = (_g2 == null) ? null : _g2[_g1];
			++_g1;
			__r.push(_0);
		};
		return Html.h("div", attributes, __r);
	}
	__initAttributes(attributes) {
		this.__coco_children.setData(attributes.children);
		this.__coco_top.setData(attributes.top);
		this.__coco_left.setData(attributes.left);
		this.__coco_right.setData(attributes.right);
		this.__coco_bottom.setData(attributes.bottom);
		let value = attributes.classes;
		this.__coco_classes.setData(new ConstObservable(value, null));
	}
	static styles(theme) {
		let f = (window.CSS && window.CSS.supports && window.CSS.supports("top: constant(safe-area-inset-top)")) ? "constant" : "env";
		return {"top": {"paddingTop": "" + f + "(safe-area-inset-top)"}, "right": {"paddingRight": "" + f + "(safe-area-inset-right)"}, "bottom": {"paddingBottom": "" + f + "(safe-area-inset-bottom)"}, "left": {"paddingLeft": "" + f + "(safe-area-inset-left)"}};
	}
	static fromHxx(hxxMeta, attributes) {
		return {"$$typeof": View.TRE, "type": SafeAreaView.__hoc, "props": attributes, "key": hxxMeta.key, "ref": (!hxxMeta.ref) ? null : hxxMeta.ref};
	}
	static get __name__() {
		return "letzbig.ui.component.SafeAreaView"
	}
	static get __super__() {
		return View
	}
	get __class__() {
		return SafeAreaView
	}
}


SafeAreaView.__hoc = (Styles.withStyles(SafeAreaView.styles))(SafeAreaView)