import {Writer1} from "./Writer1"
import {BasicParser, JsonString_Impl_} from "./Parser"
import {TypedError} from "../core/Error"
import {ProductType} from "../../letzbig/types/ProductType"
import {Medium} from "../../letzbig/types/Medium"
import {Media_Impl_} from "../../letzbig/types/Media"
import {Exception} from "../../haxe/Exception"
import {Register} from "../../genes/Register"
import {Std} from "../../Std"

export const Parser8 = Register.global("$hxClasses")["tink.json.Parser8"] = 
class Parser8 extends Register.inherits(BasicParser) {
	new() {
		super.new();
	}
	process0() {
		let _gthis = this;
		let v_date = null;
		let hasv_date = false;
		let v_id = null;
		let hasv_id = false;
		let v_media = null;
		let hasv_media = false;
		let v_rating = 0;
		let hasv_rating = false;
		let v_target = null;
		let hasv_target = false;
		let v_text = null;
		let hasv_text = false;
		let v_user = null;
		let hasv_user = false;
		let __start__ = this.pos;
		while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
		let tmp;
		if (this.max > this.pos && this.source.charCodeAt(this.pos) == 123) {
			this.pos += 1;
			while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
			tmp = true;
		} else {
			tmp = false;
		};
		if (!tmp) {
			this.die("Expected " + "{");
		};
		while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
		let tmp1;
		if (this.max > this.pos && this.source.charCodeAt(this.pos) == 125) {
			this.pos += 1;
			while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
			tmp1 = true;
		} else {
			tmp1 = false;
		};
		if (!tmp1) {
			while (true) {
				let __name__ = this.parseString();
				while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
				let tmp;
				if (this.max > this.pos && this.source.charCodeAt(this.pos) == 58) {
					this.pos += 1;
					while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
					tmp = true;
				} else {
					tmp = false;
				};
				if (!tmp) {
					this.die("Expected " + ":");
				};
				if ("user".length == __name__.max - __name__.min && __name__.source.substring(__name__.min, __name__.max) == "user") {
					v_user = JsonString_Impl_.toString(this.parseString());
					hasv_user = true;
				} else if ("text".length == __name__.max - __name__.min && __name__.source.substring(__name__.min, __name__.max) == "text") {
					while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
					let v_text1;
					if (this.max > this.pos + 3 && this.source.charCodeAt(this.pos) == 110 && this.source.charCodeAt(this.pos + 1) == 117 && this.source.charCodeAt(this.pos + 2) == 108 && this.source.charCodeAt(this.pos + 3) == 108) {
						this.pos += 4;
						while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
						v_text1 = true;
					} else {
						v_text1 = false;
					};
					v_text = (v_text1) ? null : JsonString_Impl_.toString(this.parseString());
					hasv_text = true;
				} else if ("target".length == __name__.max - __name__.min && __name__.source.substring(__name__.min, __name__.max) == "target") {
					v_target = this.process3();
					hasv_target = true;
				} else if ("rating".length == __name__.max - __name__.min && __name__.source.substring(__name__.min, __name__.max) == "rating") {
					let this1 = this.parseNumber();
					let v = Std.parseInt(this1.source.substring(this1.min, this1.max));
					switch (v) {
						case 0:case 1:case 2:case 3:case 4:
							v_rating = v;
							break
						default:
						let list = [0, 1, 2, 3, 4];
						throw Exception.thrown(new TypedError(422, "Unrecognized enum value: " + v + ". Accepted values are: " + new Writer1().write(list), {"fileName": "tink/json/macros/GenReader.hx", "lineNumber": 392, "className": "tink.json.Parser8", "methodName": "process0"}));
						
					};
					hasv_rating = true;
				} else if ("media".length == __name__.max - __name__.min && __name__.source.substring(__name__.min, __name__.max) == "media") {
					let __start__ = this.pos;
					while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
					let v_media1;
					if (this.max > this.pos && this.source.charCodeAt(this.pos) == 91) {
						this.pos += 1;
						while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
						v_media1 = true;
					} else {
						v_media1 = false;
					};
					if (!v_media1) {
						this.die("Expected " + "[");
					};
					let __ret = [];
					while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
					let v_media2;
					if (this.max > this.pos && this.source.charCodeAt(this.pos) == 93) {
						this.pos += 1;
						while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
						v_media2 = true;
					} else {
						v_media2 = false;
					};
					if (!v_media2) {
						while (true) {
							__ret.push(this.process1());
							while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
							let v_media;
							if (this.max > this.pos && this.source.charCodeAt(this.pos) == 44) {
								this.pos += 1;
								while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
								v_media = true;
							} else {
								v_media = false;
							};
							if (!v_media) {
								break;
							};
						};
						while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
						let v_media;
						if (this.max > this.pos && this.source.charCodeAt(this.pos) == 93) {
							this.pos += 1;
							while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
							v_media = true;
						} else {
							v_media = false;
						};
						if (!v_media) {
							this.die("Expected " + "]");
						};
					};
					let rep = __ret;
					try {
						let this1 = rep;
						v_media = Media_Impl_.ofRepresentation(this1);
					}catch (_g) {
						let e = Exception.caught(_g).unwrap();
						v_media = this.die(Std.string(e), __start__);
					};
					hasv_media = true;
				} else if ("id".length == __name__.max - __name__.min && __name__.source.substring(__name__.min, __name__.max) == "id") {
					v_id = JsonString_Impl_.toString(this.parseString());
					hasv_id = true;
				} else if ("date".length == __name__.max - __name__.min && __name__.source.substring(__name__.min, __name__.max) == "date") {
					let this1 = this.parseNumber();
					v_date = new Date(parseFloat(this1.source.substring(this1.min,this1.max)));
					hasv_date = true;
				} else {
					this.skipValue();
				};
				while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
				let tmp1;
				if (this.max > this.pos && this.source.charCodeAt(this.pos) == 44) {
					this.pos += 1;
					while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
					tmp1 = true;
				} else {
					tmp1 = false;
				};
				if (!tmp1) {
					break;
				};
			};
			while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
			let tmp;
			if (this.max > this.pos && this.source.charCodeAt(this.pos) == 125) {
				this.pos += 1;
				while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
				tmp = true;
			} else {
				tmp = false;
			};
			if (!tmp) {
				this.die("Expected " + "}");
			};
		};
		let __missing__ = function (field) {
			return _gthis.die("missing field \"" + field + "\"", __start__);
		};
		return {"date": (hasv_date) ? v_date : __missing__("date"), "id": (hasv_id) ? v_id : __missing__("id"), "media": (hasv_media) ? v_media : __missing__("media"), "rating": (hasv_rating) ? v_rating : __missing__("rating"), "target": (hasv_target) ? v_target : __missing__("target"), "text": (hasv_text) ? v_text : __missing__("text"), "user": (hasv_user) ? v_user : __missing__("user")};
	}
	process1() {
		let __ret = this.process2();
		let _g = __ret.url;
		let _g1 = __ret.type;
		let _g2 = __ret.thumbnailUrl;
		let _g3 = __ret.size;
		let _g4 = __ret.mime;
		let _g5 = __ret.filename;
		let _g6 = __ret.duration;
		if (_g1 == null) {
			throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "tink/json/macros/GenReader.hx", "lineNumber": 366, "className": "tink.json.Parser8", "methodName": "process1"}));
		} else {
			switch (_g1) {
				case "audio":
					let url = _g;
					let duration = _g6;
					let filename = _g5;
					let mime = _g4;
					let size = _g3;
					if (duration != null && filename != null && mime != null && size != null && url != null) {
						return Medium.Audio({"duration": duration, "filename": filename, "mime": mime, "size": size, "url": url});
					} else {
						throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "tink/json/macros/GenReader.hx", "lineNumber": 366, "className": "tink.json.Parser8", "methodName": "process1"}));
					};
					break
				case "photo":
					let url1 = _g;
					let filename1 = _g5;
					let mime1 = _g4;
					let size1 = _g3;
					let thumbnailUrl = _g2;
					if (filename1 != null && mime1 != null && size1 != null && thumbnailUrl != null && url1 != null) {
						return Medium.Photo({"filename": filename1, "mime": mime1, "size": size1, "thumbnailUrl": thumbnailUrl, "url": url1});
					} else {
						throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "tink/json/macros/GenReader.hx", "lineNumber": 366, "className": "tink.json.Parser8", "methodName": "process1"}));
					};
					break
				case "video":
					let url2 = _g;
					let duration1 = _g6;
					let filename2 = _g5;
					let mime2 = _g4;
					let size2 = _g3;
					let thumbnailUrl1 = _g2;
					if (duration1 != null && filename2 != null && mime2 != null && size2 != null && thumbnailUrl1 != null && url2 != null) {
						return Medium.Video({"duration": duration1, "filename": filename2, "mime": mime2, "size": size2, "thumbnailUrl": thumbnailUrl1, "url": url2});
					} else {
						throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "tink/json/macros/GenReader.hx", "lineNumber": 366, "className": "tink.json.Parser8", "methodName": "process1"}));
					};
					break
				default:
				throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "tink/json/macros/GenReader.hx", "lineNumber": 366, "className": "tink.json.Parser8", "methodName": "process1"}));
				
			};
		};
	}
	process2() {
		let _gthis = this;
		let v_duration = null;
		let v_filename = null;
		let v_mime = null;
		let v_size = null;
		let v_thumbnailUrl = null;
		let v_type = null;
		let v_url = null;
		let __start__ = this.pos;
		while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
		let tmp;
		if (this.max > this.pos && this.source.charCodeAt(this.pos) == 123) {
			this.pos += 1;
			while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
			tmp = true;
		} else {
			tmp = false;
		};
		if (!tmp) {
			this.die("Expected " + "{");
		};
		while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
		let tmp1;
		if (this.max > this.pos && this.source.charCodeAt(this.pos) == 125) {
			this.pos += 1;
			while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
			tmp1 = true;
		} else {
			tmp1 = false;
		};
		if (!tmp1) {
			while (true) {
				let __name__ = this.parseString();
				while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
				let tmp;
				if (this.max > this.pos && this.source.charCodeAt(this.pos) == 58) {
					this.pos += 1;
					while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
					tmp = true;
				} else {
					tmp = false;
				};
				if (!tmp) {
					this.die("Expected " + ":");
				};
				if ("url".length == __name__.max - __name__.min && __name__.source.substring(__name__.min, __name__.max) == "url") {
					while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
					let v_url1;
					if (this.max > this.pos + 3 && this.source.charCodeAt(this.pos) == 110 && this.source.charCodeAt(this.pos + 1) == 117 && this.source.charCodeAt(this.pos + 2) == 108 && this.source.charCodeAt(this.pos + 3) == 108) {
						this.pos += 4;
						while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
						v_url1 = true;
					} else {
						v_url1 = false;
					};
					v_url = (v_url1) ? null : JsonString_Impl_.toString(this.parseString());
				} else if ("type".length == __name__.max - __name__.min && __name__.source.substring(__name__.min, __name__.max) == "type") {
					while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
					let v_type1;
					if (this.max > this.pos + 3 && this.source.charCodeAt(this.pos) == 110 && this.source.charCodeAt(this.pos + 1) == 117 && this.source.charCodeAt(this.pos + 2) == 108 && this.source.charCodeAt(this.pos + 3) == 108) {
						this.pos += 4;
						while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
						v_type1 = true;
					} else {
						v_type1 = false;
					};
					v_type = (v_type1) ? null : JsonString_Impl_.toString(this.parseString());
				} else if ("thumbnailUrl".length == __name__.max - __name__.min && __name__.source.substring(__name__.min, __name__.max) == "thumbnailUrl") {
					while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
					let v_thumbnailUrl1;
					if (this.max > this.pos + 3 && this.source.charCodeAt(this.pos) == 110 && this.source.charCodeAt(this.pos + 1) == 117 && this.source.charCodeAt(this.pos + 2) == 108 && this.source.charCodeAt(this.pos + 3) == 108) {
						this.pos += 4;
						while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
						v_thumbnailUrl1 = true;
					} else {
						v_thumbnailUrl1 = false;
					};
					v_thumbnailUrl = (v_thumbnailUrl1) ? null : JsonString_Impl_.toString(this.parseString());
				} else if ("size".length == __name__.max - __name__.min && __name__.source.substring(__name__.min, __name__.max) == "size") {
					while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
					let v_size1;
					if (this.max > this.pos + 3 && this.source.charCodeAt(this.pos) == 110 && this.source.charCodeAt(this.pos + 1) == 117 && this.source.charCodeAt(this.pos + 2) == 108 && this.source.charCodeAt(this.pos + 3) == 108) {
						this.pos += 4;
						while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
						v_size1 = true;
					} else {
						v_size1 = false;
					};
					if (v_size1) {
						v_size = null;
					} else {
						let this1 = this.parseNumber();
						v_size = Std.parseInt(this1.source.substring(this1.min, this1.max));
					};
				} else if ("mime".length == __name__.max - __name__.min && __name__.source.substring(__name__.min, __name__.max) == "mime") {
					while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
					let v_mime1;
					if (this.max > this.pos + 3 && this.source.charCodeAt(this.pos) == 110 && this.source.charCodeAt(this.pos + 1) == 117 && this.source.charCodeAt(this.pos + 2) == 108 && this.source.charCodeAt(this.pos + 3) == 108) {
						this.pos += 4;
						while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
						v_mime1 = true;
					} else {
						v_mime1 = false;
					};
					v_mime = (v_mime1) ? null : JsonString_Impl_.toString(this.parseString());
				} else if ("filename".length == __name__.max - __name__.min && __name__.source.substring(__name__.min, __name__.max) == "filename") {
					while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
					let v_filename1;
					if (this.max > this.pos + 3 && this.source.charCodeAt(this.pos) == 110 && this.source.charCodeAt(this.pos + 1) == 117 && this.source.charCodeAt(this.pos + 2) == 108 && this.source.charCodeAt(this.pos + 3) == 108) {
						this.pos += 4;
						while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
						v_filename1 = true;
					} else {
						v_filename1 = false;
					};
					v_filename = (v_filename1) ? null : JsonString_Impl_.toString(this.parseString());
				} else if ("duration".length == __name__.max - __name__.min && __name__.source.substring(__name__.min, __name__.max) == "duration") {
					while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
					let v_duration1;
					if (this.max > this.pos + 3 && this.source.charCodeAt(this.pos) == 110 && this.source.charCodeAt(this.pos + 1) == 117 && this.source.charCodeAt(this.pos + 2) == 108 && this.source.charCodeAt(this.pos + 3) == 108) {
						this.pos += 4;
						while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
						v_duration1 = true;
					} else {
						v_duration1 = false;
					};
					if (v_duration1) {
						v_duration = null;
					} else {
						let this1 = this.parseNumber();
						v_duration = Std.parseInt(this1.source.substring(this1.min, this1.max));
					};
				} else {
					this.skipValue();
				};
				while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
				let tmp1;
				if (this.max > this.pos && this.source.charCodeAt(this.pos) == 44) {
					this.pos += 1;
					while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
					tmp1 = true;
				} else {
					tmp1 = false;
				};
				if (!tmp1) {
					break;
				};
			};
			while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
			let tmp;
			if (this.max > this.pos && this.source.charCodeAt(this.pos) == 125) {
				this.pos += 1;
				while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
				tmp = true;
			} else {
				tmp = false;
			};
			if (!tmp) {
				this.die("Expected " + "}");
			};
		};
		let __missing__ = function (field) {
			return _gthis.die("missing field \"" + field + "\"", __start__);
		};
		return {"duration": v_duration, "filename": v_filename, "mime": v_mime, "size": v_size, "thumbnailUrl": v_thumbnailUrl, "type": v_type, "url": v_url};
	}
	process3() {
		let __ret = this.process4();
		let o = __ret.Product;
		if (o != null) {
			return ProductType.Product(o.id);
		} else {
			let o = __ret.Custom;
			if (o != null) {
				return ProductType.Custom(o.id);
			} else {
				throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "tink/json/macros/GenReader.hx", "lineNumber": 366, "className": "tink.json.Parser8", "methodName": "process3"}));
			};
		};
	}
	process4() {
		let _gthis = this;
		let v_Custom = null;
		let v_Product = null;
		let __start__ = this.pos;
		while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
		let tmp;
		if (this.max > this.pos && this.source.charCodeAt(this.pos) == 123) {
			this.pos += 1;
			while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
			tmp = true;
		} else {
			tmp = false;
		};
		if (!tmp) {
			this.die("Expected " + "{");
		};
		while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
		let tmp1;
		if (this.max > this.pos && this.source.charCodeAt(this.pos) == 125) {
			this.pos += 1;
			while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
			tmp1 = true;
		} else {
			tmp1 = false;
		};
		if (!tmp1) {
			while (true) {
				let __name__ = this.parseString();
				while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
				let tmp;
				if (this.max > this.pos && this.source.charCodeAt(this.pos) == 58) {
					this.pos += 1;
					while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
					tmp = true;
				} else {
					tmp = false;
				};
				if (!tmp) {
					this.die("Expected " + ":");
				};
				if ("Product".length == __name__.max - __name__.min && __name__.source.substring(__name__.min, __name__.max) == "Product") {
					while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
					let v_Product1;
					if (this.max > this.pos + 3 && this.source.charCodeAt(this.pos) == 110 && this.source.charCodeAt(this.pos + 1) == 117 && this.source.charCodeAt(this.pos + 2) == 108 && this.source.charCodeAt(this.pos + 3) == 108) {
						this.pos += 4;
						while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
						v_Product1 = true;
					} else {
						v_Product1 = false;
					};
					v_Product = (v_Product1) ? null : this.process5();
				} else if ("Custom".length == __name__.max - __name__.min && __name__.source.substring(__name__.min, __name__.max) == "Custom") {
					while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
					let v_Custom1;
					if (this.max > this.pos + 3 && this.source.charCodeAt(this.pos) == 110 && this.source.charCodeAt(this.pos + 1) == 117 && this.source.charCodeAt(this.pos + 2) == 108 && this.source.charCodeAt(this.pos + 3) == 108) {
						this.pos += 4;
						while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
						v_Custom1 = true;
					} else {
						v_Custom1 = false;
					};
					v_Custom = (v_Custom1) ? null : this.process5();
				} else {
					this.skipValue();
				};
				while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
				let tmp1;
				if (this.max > this.pos && this.source.charCodeAt(this.pos) == 44) {
					this.pos += 1;
					while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
					tmp1 = true;
				} else {
					tmp1 = false;
				};
				if (!tmp1) {
					break;
				};
			};
			while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
			let tmp;
			if (this.max > this.pos && this.source.charCodeAt(this.pos) == 125) {
				this.pos += 1;
				while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
				tmp = true;
			} else {
				tmp = false;
			};
			if (!tmp) {
				this.die("Expected " + "}");
			};
		};
		let __missing__ = function (field) {
			return _gthis.die("missing field \"" + field + "\"", __start__);
		};
		return {"Custom": v_Custom, "Product": v_Product};
	}
	process5() {
		let _gthis = this;
		let v_id = null;
		let hasv_id = false;
		let __start__ = this.pos;
		while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
		let tmp;
		if (this.max > this.pos && this.source.charCodeAt(this.pos) == 123) {
			this.pos += 1;
			while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
			tmp = true;
		} else {
			tmp = false;
		};
		if (!tmp) {
			this.die("Expected " + "{");
		};
		while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
		let tmp1;
		if (this.max > this.pos && this.source.charCodeAt(this.pos) == 125) {
			this.pos += 1;
			while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
			tmp1 = true;
		} else {
			tmp1 = false;
		};
		if (!tmp1) {
			while (true) {
				let __name__ = this.parseString();
				while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
				let tmp;
				if (this.max > this.pos && this.source.charCodeAt(this.pos) == 58) {
					this.pos += 1;
					while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
					tmp = true;
				} else {
					tmp = false;
				};
				if (!tmp) {
					this.die("Expected " + ":");
				};
				if ("id".length == __name__.max - __name__.min && __name__.source.substring(__name__.min, __name__.max) == "id") {
					v_id = JsonString_Impl_.toString(this.parseString());
					hasv_id = true;
				} else {
					this.skipValue();
				};
				while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
				let tmp1;
				if (this.max > this.pos && this.source.charCodeAt(this.pos) == 44) {
					this.pos += 1;
					while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
					tmp1 = true;
				} else {
					tmp1 = false;
				};
				if (!tmp1) {
					break;
				};
			};
			while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
			let tmp;
			if (this.max > this.pos && this.source.charCodeAt(this.pos) == 125) {
				this.pos += 1;
				while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
				tmp = true;
			} else {
				tmp = false;
			};
			if (!tmp) {
				this.die("Expected " + "}");
			};
		};
		let __missing__ = function (field) {
			return _gthis.die("missing field \"" + field + "\"", __start__);
		};
		return {"id": (hasv_id) ? v_id : __missing__("id")};
	}
	parse(source) {
		let _gthis = this;
		if (_gthis.afterParsing.length > 0) {
			_gthis.afterParsing = [];
		};
		this.init(source);
		let ret = this.process0();
		let _g = 0;
		let _g1 = this.afterParsing;
		while (_g < _g1.length) {
			let f = _g1[_g];
			++_g;
			f();
		};
		if (_gthis.afterParsing.length > 0) {
			_gthis.afterParsing = [];
		};
		return ret;
	}
	tryParse(source) {
		let _gthis = this;
		return TypedError.catchExceptions(function () {
			let ret = _gthis.parse(source);
			while (_gthis.pos < _gthis.max && _gthis.source.charCodeAt(_gthis.pos) < 33) _gthis.pos++;
			if (_gthis.pos < _gthis.max) {
				_gthis.die("Invalid data after JSON document");
			};
			return ret;
		}, null, {"fileName": "tink/json/macros/Macro.hx", "lineNumber": 107, "className": "tink.json.Parser8", "methodName": "tryParse"});
	}
	static get __name__() {
		return "tink.json.Parser8"
	}
	static get __super__() {
		return BasicParser
	}
	get __class__() {
		return Parser8
	}
}

