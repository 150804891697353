import {Register} from "../../genes/Register"

export const Disposable = {}

export const OwnedDisposable = {}

/**
* A simple implementation of the OwnedDisposable,
* where actual disposal is passed in via the constructor.
*/
export const SimpleDisposable = Register.global("$hxClasses")["tink.core.SimpleDisposable"] = 
class SimpleDisposable extends Register.inherits() {
	new(dispose) {
		this.disposeHandlers = [];
		this.f = dispose;
	}
	dispose() {
		let _g = this.disposeHandlers;
		if (_g != null) {
			let v = _g;
			this.disposeHandlers = null;
			let f = this.f;
			this.f = SimpleDisposable.noop;
			f();
			let _g1 = 0;
			while (_g1 < v.length) {
				let h = v[_g1];
				++_g1;
				h();
			};
		};
	}
	static noop() {
	}
	static get __name__() {
		return "tink.core.SimpleDisposable"
	}
	static get __interfaces__() {
		return [OwnedDisposable]
	}
	get __class__() {
		return SimpleDisposable
	}
}


export const AlreadyDisposed = Register.global("$hxClasses")["tink.core.AlreadyDisposed"] = 
class AlreadyDisposed extends Register.inherits() {
	new() {
	}
	static get __name__() {
		return "tink.core.AlreadyDisposed"
	}
	static get __interfaces__() {
		return [OwnedDisposable]
	}
	get __class__() {
		return AlreadyDisposed
	}
}


AlreadyDisposed.INST = new AlreadyDisposed()