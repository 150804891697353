import {ChunkObject} from "./ChunkObject"
import {ChunkBase} from "./ChunkBase"
import {Chunk_Impl_} from "../Chunk"
import {Bytes} from "../../haxe/io/Bytes"
import {Register} from "../../genes/Register"

export const CompoundChunk = Register.global("$hxClasses")["tink.chunk.CompoundChunk"] = 
class CompoundChunk extends Register.inherits(ChunkBase) {
	new() {
	}
	getLength() {
		return this.length;
	}
	flatten(into) {
		let _g = 0;
		let _g1 = this.chunks;
		while (_g < _g1.length) {
			let c = _g1[_g];
			++_g;
			c.flatten(into);
		};
	}
	blitTo(target, offset) {
		let _g = 0;
		let _g1 = this.chunks.length;
		while (_g < _g1) {
			let i = _g++;
			this.chunks[i].blitTo(target, offset + this.offsets[i]);
		};
	}
	toString() {
		return this.toBytes().toString();
	}
	toBytes() {
		let ret = new Bytes(new ArrayBuffer(this.length));
		this.blitTo(ret, 0);
		return ret;
	}
	static asCompound(c) {
		if (((c) instanceof CompoundChunk)) {
			return c;
		} else {
			return null;
		};
	}
	static cons(a, b) {
		let _g = b.getLength();
		let _g1 = a.getLength();
		if (_g1 == 0) {
			if (_g == 0) {
				return Chunk_Impl_.EMPTY;
			} else {
				return b;
			};
		} else if (_g == 0) {
			return a;
		} else {
			let la = _g1;
			let lb = _g;
			let _g2 = CompoundChunk.asCompound(b);
			let _g3 = CompoundChunk.asCompound(a);
			if (_g3 == null) {
				if (_g2 == null) {
					return CompoundChunk.create([a, b], 2);
				} else {
					let v = _g2;
					if (v.depth < 100) {
						return CompoundChunk.create([a, b], v.depth + 1);
					} else {
						let flat = [];
						v.flatten(flat);
						b.flatten(flat);
						return CompoundChunk.create(flat, 2);
					};
				};
			} else if (_g2 == null) {
				let v = _g3;
				if (v.depth < 100) {
					return CompoundChunk.create([a, b], v.depth + 1);
				} else {
					let flat = [];
					v.flatten(flat);
					b.flatten(flat);
					return CompoundChunk.create(flat, 2);
				};
			} else {
				let a = _g3;
				let b = _g2;
				let depth = (a.depth > b.depth) ? a.depth : b.depth;
				return CompoundChunk.create(a.chunks.concat(b.chunks), depth);
			};
		};
	}
	static create(chunks, depth) {
		let ret = new CompoundChunk();
		let offsets = [0];
		let length = 0;
		let _g = 0;
		while (_g < chunks.length) {
			let c = chunks[_g];
			++_g;
			offsets.push(length += c.getLength());
		};
		ret.chunks = chunks;
		ret.offsets = offsets;
		ret.length = length;
		ret.depth = depth;
		return ret;
	}
	static get __name__() {
		return "tink.chunk.CompoundChunk"
	}
	static get __interfaces__() {
		return [ChunkObject]
	}
	static get __super__() {
		return ChunkBase
	}
	get __class__() {
		return CompoundChunk
	}
}

