import {BasicWriter} from "./Writer"
import {Register} from "../../genes/Register"

export const Writer8 = Register.global("$hxClasses")["tink.json.Writer8"] = 
class Writer8 extends Register.inherits(BasicWriter) {
	new() {
		super.new();
	}
	process0(value) {
		let __first = true;
		this.buf += String.fromCodePoint(123);
		let value1 = value._21;
		if (__first) {
			__first = false;
		} else {
			this.buf += String.fromCodePoint(44);
		};
		this.buf += "\"cognito\":";
		let s = JSON.stringify(value1);
		this.buf += s;
		this.buf += String.fromCodePoint(125);
	}
	write(value) {
		this.init();
		this.process0(value);
		return this.buf.toString();
	}
	static get __name__() {
		return "tink.json.Writer8"
	}
	static get __super__() {
		return BasicWriter
	}
	get __class__() {
		return Writer8
	}
}

