import {ReactType_Impl_} from "../../react/ReactType"
import * as React from "react"
import {Register} from "../../genes/Register"

export const Html = Register.global("$hxClasses")["coconut.react.Html"] = 
class Html {
	static h(tag, attr, children = null) {
		if (children == null) {
			return React.createElement(ReactType_Impl_.fromString(tag), attr);
		} else {
			let tmp = [tag, attr].concat(children);
			return React.createElement.apply(null, tmp);
		};
	}
	static get __name__() {
		return "coconut.react.Html"
	}
	get __class__() {
		return Html
	}
}

