import {Outcome} from "../core/Outcome"
import {NamedWith} from "../core/Named"
import {TypedError} from "../core/Error"
import {Bytes} from "../../haxe/io/Bytes"
import {Base64} from "../../haxe/crypto/Base64"
import {Register} from "../../genes/Register"
import {StringTools} from "../../StringTools"
import {HxOverrides} from "../../HxOverrides"

export const Header = Register.global("$hxClasses")["tink.http.Header"] = 
class Header extends Register.inherits() {
	new(fields = null) {
		let tmp;
		if (fields == null) {
			tmp = [];
		} else {
			let v = fields;
			tmp = v;
		};
		this.fields = tmp;
	}
	
	/**
	*  Get all headers of the given name
	*  @param name - Header name to retrieve
	*  @return Array of headers of the given name
	*/
	get(name) {
		let _g = [];
		let _g1 = 0;
		let _g2 = this.fields;
		while (_g1 < _g2.length) {
			let f = _g2[_g1];
			++_g1;
			if (f.name == name) {
				_g.push(f.value);
			};
		};
		return _g;
	}
	
	/**
	*  Get a header
	*  @param name - Header name to retrieve
	*  @return `Success(header)` if there is exactly one entry of the given header name, `Failure(err)` otherwise
	*/
	byName(name) {
		let _g = this.get(name);
		switch (_g.length) {
			case 0:
				return Outcome.Failure(new TypedError(422, "No " + name + " header found", {"fileName": "tink/http/Header.hx", "lineNumber": 91, "className": "tink.http.Header", "methodName": "byName"}));
				break
			case 1:
				let v = _g[0];
				return Outcome.Success(v);
				break
			default:
			let v1 = _g;
			return Outcome.Failure(new TypedError(422, "Multiple entries for " + name + " header", {"fileName": "tink/http/Header.hx", "lineNumber": 95, "className": "tink.http.Header", "methodName": "byName"}));
			
		};
	}
	static get __name__() {
		return "tink.http.Header"
	}
	get __class__() {
		return Header
	}
}


export const HeaderValue_Impl_ = Register.global("$hxClasses")["tink.http._Header.HeaderValue_Impl_"] = 
class HeaderValue_Impl_ {
	static basicAuth(username, password) {
		return "Basic " + Base64.encode(Bytes.ofString("" + username + ":" + password)).toString();
	}
	static ofInt(i) {
		if (i == null) {
			return "null";
		} else {
			return "" + i;
		};
	}
	static get __name__() {
		return "tink.http._Header.HeaderValue_Impl_"
	}
	get __class__() {
		return HeaderValue_Impl_
	}
}


export const HeaderField = Register.global("$hxClasses")["tink.http.HeaderField"] = 
class HeaderField extends Register.inherits(NamedWith) {
	new(name, value) {
		super.new(name, value);
	}
	static ofString(s) {
		let _g = s.indexOf(":");
		if (_g == -1) {
			let this1 = s.toLowerCase();
			return new HeaderField(this1, null);
		} else {
			let v = _g;
			let this1 = HxOverrides.substr(s, 0, v).toLowerCase();
			return new HeaderField(this1, StringTools.trim(HxOverrides.substr(s, v + 1, null)));
		};
	}
	static get __name__() {
		return "tink.http.HeaderField"
	}
	static get __super__() {
		return NamedWith
	}
	get __class__() {
		return HeaderField
	}
}

