import {Annex} from "../core/Annex"
import {Register} from "../../genes/Register"

export const BasicWriter = Register.global("$hxClasses")["tink.json.BasicWriter"] = 
class BasicWriter extends Register.inherits() {
	new() {
		this.plugins = new Annex(this);
	}
	init() {
		let this1 = "";
		this.buf = this1;
	}
	static get __name__() {
		return "tink.json.BasicWriter"
	}
	get __class__() {
		return BasicWriter
	}
}

