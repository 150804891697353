import {Boot} from "./js/Boot"
import {Register} from "./genes/Register"

/**
The Std class provides standard methods for manipulating basic types.
*/
export const Std = Register.global("$hxClasses")["Std"] = 
class Std {
	
	/**
	Converts any value to a String.
	
	If `s` is of `String`, `Int`, `Float` or `Bool`, its value is returned.
	
	If `s` is an instance of a class and that class or one of its parent classes has
	a `toString` method, that method is called. If no such method is present, the result
	is unspecified.
	
	If `s` is an enum constructor without argument, the constructor's name is returned. If
	arguments exists, the constructor's name followed by the String representations of
	the arguments is returned.
	
	If `s` is a structure, the field names along with their values are returned. The field order
	and the operator separating field names and values are unspecified.
	
	If s is null, "null" is returned.
	*/
	static string(s) {
		return Boot.__string_rec(s, "");
	}
	
	/**
	Converts a `String` to an `Int`.
	
	Leading whitespaces are ignored.
	
	If `x` starts with 0x or 0X, hexadecimal notation is recognized where the following digits may
	contain 0-9 and A-F.
	
	Otherwise `x` is read as decimal number with 0-9 being allowed characters. `x` may also start with
	a - to denote a negative value.
	
	In decimal mode, parsing continues until an invalid character is detected, in which case the
	result up to that point is returned. For hexadecimal notation, the effect of invalid characters
	is unspecified.
	
	Leading 0s that are not part of the 0x/0X hexadecimal notation are ignored, which means octal
	notation is not supported.
	
	If `x` is null, the result is unspecified.
	If `x` cannot be parsed as integer, the result is `null`.
	*/
	static parseInt(x) {
		if (x != null) {
			let _g = 0;
			let _g1 = x.length;
			while (_g < _g1) {
				let i = _g++;
				let c = x.charCodeAt(i);
				if (c <= 8 || c >= 14 && c != 32 && c != 45) {
					let nc = x.charCodeAt(i + 1);
					let v = parseInt(x, (nc == 120 || nc == 88) ? 16 : 10);
					if ((isNaN)(v)) {
						return null;
					} else {
						return v;
					};
				};
			};
		};
		return null;
	}
	
	/**
	Return a random integer between 0 included and `x` excluded.
	
	If `x <= 1`, the result is always 0.
	*/
	static random(x) {
		if (x <= 0) {
			return 0;
		} else {
			return Math.floor(Math.random() * x);
		};
	}
	static get __name__() {
		return "Std"
	}
	get __class__() {
		return Std
	}
}


;{
	String.__name__ = true;
	Array.__name__ = true;
	Date.__name__ = "Date";
}
