import {BasicParser, JsonString_Impl_} from "./Parser"
import {TypedError} from "../core/Error"
import {Register} from "../../genes/Register"

export const Parser15 = Register.global("$hxClasses")["tink.json.Parser15"] = 
class Parser15 extends Register.inherits(BasicParser) {
	new() {
		super.new();
	}
	process0() {
		let _gthis = this;
		let v_token = null;
		let hasv_token = false;
		let __start__ = this.pos;
		while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
		let tmp;
		if (this.max > this.pos && this.source.charCodeAt(this.pos) == 123) {
			this.pos += 1;
			while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
			tmp = true;
		} else {
			tmp = false;
		};
		if (!tmp) {
			this.die("Expected " + "{");
		};
		while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
		let tmp1;
		if (this.max > this.pos && this.source.charCodeAt(this.pos) == 125) {
			this.pos += 1;
			while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
			tmp1 = true;
		} else {
			tmp1 = false;
		};
		if (!tmp1) {
			while (true) {
				let __name__ = this.parseString();
				while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
				let tmp;
				if (this.max > this.pos && this.source.charCodeAt(this.pos) == 58) {
					this.pos += 1;
					while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
					tmp = true;
				} else {
					tmp = false;
				};
				if (!tmp) {
					this.die("Expected " + ":");
				};
				if ("token".length == __name__.max - __name__.min && __name__.source.substring(__name__.min, __name__.max) == "token") {
					v_token = JsonString_Impl_.toString(this.parseString());
					hasv_token = true;
				} else {
					this.skipValue();
				};
				while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
				let tmp1;
				if (this.max > this.pos && this.source.charCodeAt(this.pos) == 44) {
					this.pos += 1;
					while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
					tmp1 = true;
				} else {
					tmp1 = false;
				};
				if (!tmp1) {
					break;
				};
			};
			while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
			let tmp;
			if (this.max > this.pos && this.source.charCodeAt(this.pos) == 125) {
				this.pos += 1;
				while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
				tmp = true;
			} else {
				tmp = false;
			};
			if (!tmp) {
				this.die("Expected " + "}");
			};
		};
		let __missing__ = function (field) {
			return _gthis.die("missing field \"" + field + "\"", __start__);
		};
		return {"token": (hasv_token) ? v_token : __missing__("token")};
	}
	parse(source) {
		let _gthis = this;
		if (_gthis.afterParsing.length > 0) {
			_gthis.afterParsing = [];
		};
		this.init(source);
		let ret = this.process0();
		let _g = 0;
		let _g1 = this.afterParsing;
		while (_g < _g1.length) {
			let f = _g1[_g];
			++_g;
			f();
		};
		if (_gthis.afterParsing.length > 0) {
			_gthis.afterParsing = [];
		};
		return ret;
	}
	tryParse(source) {
		let _gthis = this;
		return TypedError.catchExceptions(function () {
			let ret = _gthis.parse(source);
			while (_gthis.pos < _gthis.max && _gthis.source.charCodeAt(_gthis.pos) < 33) _gthis.pos++;
			if (_gthis.pos < _gthis.max) {
				_gthis.die("Invalid data after JSON document");
			};
			return ret;
		}, null, {"fileName": "tink/json/macros/Macro.hx", "lineNumber": 107, "className": "tink.json.Parser15", "methodName": "tryParse"});
	}
	static get __name__() {
		return "tink.json.Parser15"
	}
	static get __super__() {
		return BasicParser
	}
	get __class__() {
		return Parser15
	}
}

