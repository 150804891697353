import {ObservableObject} from "../../../tink/state/internal/ObservableObject"
import {Invalidatable, Invalidator} from "../../../tink/state/internal/Invalidatable"
import {Register} from "../../../genes/Register"

export const Slot = Register.global("$hxClasses")["coconut.ui.internal.Slot"] = 
class Slot extends Register.inherits(Invalidator) {
	new(owner, comparator = null, defaultData = null, toString = null) {
		let _gthis = this;
		super.new(toString);
		this.comparator = comparator;
		this.data = this.defaultData = defaultData;
		this.list.ondrain = function () {
			let this1 = _gthis.link;
			if (this1 != null) {
				this1.cancel();
			};
		};
		this.list.onfill = function () {
			_gthis.heatup();
		};
	}
	heatup() {
		if (this.data != null) {
			this.link = this.data.onInvalidate(this);
		};
	}
	invalidate() {
		this.fire();
	}
	getComparator() {
		return this.comparator;
	}
	getRevision() {
		let ret = this.revision;
		if (this.data != null) {
			let b = this.data.getRevision();
			if (!(ret > b)) {
				ret = b;
			};
		};
		if (this.defaultData != null) {
			let b = this.defaultData.getRevision();
			if (!(ret > b)) {
				ret = b;
			};
		};
		return ret;
	}
	getValue() {
		let _g = this.defaultData;
		let _g1 = this.data;
		let _hx_tmp;
		if (_g1 == null) {
			if (_g == null) {
				return null;
			} else {
				let v = _g;
				return v.getValue();
			};
		} else if (_g == null) {
			let v = _g1;
			return v.getValue();
		} else {
			_hx_tmp = _g1.getValue();
			let ret = _hx_tmp;
			let v = _g;
			if (ret == null) {
				return v.getValue();
			} else {
				return ret;
			};
		};
	}
	setData(data) {
		if (data == null) {
			data = this.defaultData;
		};
		if (data == this.data) {
			return;
		};
		this.data = data;
		if (this.list.used > 0) {
			let this1 = this.link;
			if (this1 != null) {
				this1.cancel();
			};
			this.heatup();
		};
		this.fire();
	}
	static get __name__() {
		return "coconut.ui.internal.Slot"
	}
	static get __interfaces__() {
		return [ObservableObject, Invalidatable]
	}
	static get __super__() {
		return Invalidator
	}
	get __class__() {
		return Slot
	}
}

