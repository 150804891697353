import {StreamBase} from "./Stream"
import {Register} from "../../genes/Register"

export const IdealStreamBase = Register.global("$hxClasses")["tink.streams.IdealStreamBase"] = 
class IdealStreamBase extends Register.inherits(() => StreamBase, true) {
	new() {
		super.new();
	}
	idealize(rescue) {
		return this;
	}
	static get __name__() {
		return "tink.streams.IdealStreamBase"
	}
	static get __super__() {
		return StreamBase
	}
	get __class__() {
		return IdealStreamBase
	}
}

