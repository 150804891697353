import {Register} from "../../genes/Register"

export const Message = Register.global("$hxClasses")["tink.http.Message"] = 
class Message extends Register.inherits() {
	new(header, body) {
		this.header = header;
		this.body = body;
	}
	static get __name__() {
		return "tink.http.Message"
	}
	get __class__() {
		return Message
	}
}

