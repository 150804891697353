import {Message} from "./Message"
import {Header} from "./Header"
import {HttpStatusMessage_Impl_} from "../../httpstatus/HttpStatusMessage"
import {Register} from "../../genes/Register"

export const ResponseHeaderBase = Register.global("$hxClasses")["tink.http.ResponseHeaderBase"] = 
class ResponseHeaderBase extends Register.inherits(Header) {
	new(statusCode, reason = null, fields = null, protocol = "HTTP/1.1") {
		this.statusCode = statusCode;
		let tmp;
		if (reason == null) {
			let this1 = HttpStatusMessage_Impl_.fromCode(statusCode);
			tmp = this1;
		} else {
			tmp = reason;
		};
		this.reason = tmp;
		this.protocol = protocol;
		super.new(fields);
	}
	static get __name__() {
		return "tink.http.ResponseHeaderBase"
	}
	static get __super__() {
		return Header
	}
	get __class__() {
		return ResponseHeaderBase
	}
}


export const OutgoingResponseData = Register.global("$hxClasses")["tink.http._Response.OutgoingResponseData"] = 
class OutgoingResponseData extends Register.inherits(Message) {
	new(header, body) {
		super.new(header, body);
	}
	static get __name__() {
		return "tink.http._Response.OutgoingResponseData"
	}
	static get __super__() {
		return Message
	}
	get __class__() {
		return OutgoingResponseData
	}
}


export const IncomingResponse = Register.global("$hxClasses")["tink.http.IncomingResponse"] = 
class IncomingResponse extends Register.inherits(Message) {
	new(header, body) {
		super.new(header, body);
	}
	static get __name__() {
		return "tink.http.IncomingResponse"
	}
	static get __super__() {
		return Message
	}
	get __class__() {
		return IncomingResponse
	}
}

