import {Disposable, AlreadyDisposed, OwnedDisposable} from "./Disposable"
import {CallbackList, ListCell, Callback_Impl_} from "./Callback"
import {Register} from "../../genes/Register"

export const Signal_Impl_ = Register.global("$hxClasses")["tink.core._Signal.Signal_Impl_"] = 
class Signal_Impl_ {
	
	/**
	*  Creates a new `SignalTrigger`
	*/
	static trigger() {
		return new SignalTrigger();
	}
	static dead() {
		return Disposed.INST;
	}
	static get __name__() {
		return "tink.core._Signal.Signal_Impl_"
	}
	get __class__() {
		return Signal_Impl_
	}
}


export const SignalObject = {}

export const Disposed = Register.global("$hxClasses")["tink.core._Signal.Disposed"] = 
class Disposed extends Register.inherits(AlreadyDisposed) {
	new() {
		super.new();
	}
	listen(cb) {
		return null;
	}
	static get __name__() {
		return "tink.core._Signal.Disposed"
	}
	static get __interfaces__() {
		return [SignalObject]
	}
	static get __super__() {
		return AlreadyDisposed
	}
	get __class__() {
		return Disposed
	}
}


Disposed.INST = new Disposed()
export const Suspendable = Register.global("$hxClasses")["tink.core._Signal.Suspendable"] = 
class Suspendable extends Register.inherits() {
	new(activate, init = null) {
		this.handlers = new CallbackList();
		let _gthis = this;
		this.activate = activate;
		this.init = init;
		this.handlers.ondrain = function () {
			let this1 = _gthis.subscription;
			if (this1 != null) {
				this1.cancel();
			};
		};
		this.handlers.onfill = function () {
			if (init != null) {
				let f = init;
				init = null;
				f(_gthis);
			};
			_gthis.subscription = activate((o=>Register.bind(o, o.invoke))(_gthis.handlers));
		};
	}
	listen(cb) {
		let _this = this.handlers;
		if (_this.disposeHandlers == null) {
			return null;
		} else {
			let node = new ListCell(cb, _this);
			_this.cells.push(node);
			if (_this.used++ == 0) {
				let fn = _this.onfill;
				if (Callback_Impl_.depth < 500) {
					Callback_Impl_.depth++;
					fn();
					Callback_Impl_.depth--;
				} else {
					Callback_Impl_.defer(fn);
				};
			};
			return node;
		};
	}
	static get __name__() {
		return "tink.core._Signal.Suspendable"
	}
	static get __interfaces__() {
		return [OwnedDisposable, SignalObject]
	}
	get __class__() {
		return Suspendable
	}
}


export const SignalTrigger = Register.global("$hxClasses")["tink.core.SignalTrigger"] = 
class SignalTrigger extends Register.inherits() {
	new() {
		this.handlers = new CallbackList();
	}
	listen(cb) {
		let _this = this.handlers;
		if (_this.disposeHandlers == null) {
			return null;
		} else {
			let node = new ListCell(cb, _this);
			_this.cells.push(node);
			if (_this.used++ == 0) {
				let fn = _this.onfill;
				if (Callback_Impl_.depth < 500) {
					Callback_Impl_.depth++;
					fn();
					Callback_Impl_.depth--;
				} else {
					Callback_Impl_.defer(fn);
				};
			};
			return node;
		};
	}
	static get __name__() {
		return "tink.core.SignalTrigger"
	}
	static get __interfaces__() {
		return [OwnedDisposable, SignalObject]
	}
	get __class__() {
		return SignalTrigger
	}
}

