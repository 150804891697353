import {TypedError} from "../core/Error"
import {Annex} from "../core/Annex"
import {Register} from "../../genes/Register"
import {StringTools} from "../../StringTools"
import {HxOverrides} from "../../HxOverrides"

export const SliceData = Register.global("$hxClasses")["tink.json._Parser.SliceData"] = 
class SliceData extends Register.inherits() {
	new(source, min, max) {
		this.source = source;
		this.min = min;
		this.max = max;
	}
	static get __name__() {
		return "tink.json._Parser.SliceData"
	}
	get __class__() {
		return SliceData
	}
}


export const JsonString_Impl_ = Register.global("$hxClasses")["tink.json._Parser.JsonString_Impl_"] = 
class JsonString_Impl_ {
	static toString(this1) {
		return JSON.parse(this1.source.substring(this1.min - 1, this1.max + 1));
	}
	static get __name__() {
		return "tink.json._Parser.JsonString_Impl_"
	}
	get __class__() {
		return JsonString_Impl_
	}
}


export const BasicParser = Register.global("$hxClasses")["tink.json.BasicParser"] = 
class BasicParser extends Register.inherits() {
	new() {
		this.afterParsing = new Array();
		this.plugins = new Annex(this);
	}
	init(source) {
		this.pos = 0;
		let this1 = source;
		this.max = source.length;
		this.source = this1;
		while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
	}
	parseString() {
		while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
		let e;
		if (this.max > this.pos && this.source.charCodeAt(this.pos) == 34) {
			this.pos += 1;
			e = true;
		} else {
			e = false;
		};
		let e1 = (!e) ? this.die("Expected " + "string") : null;
		return this.parseRestOfString();
	}
	parseRestOfString() {
		return this.slice(this.skipString(), this.pos - 1);
	}
	skipString() {
		let start = this.pos;
		while (true) {
			let end = this.max;
			let _g = this.source.indexOf(BasicParser.DBQT, this.pos);
			if (_g == -1) {
				this.die("unterminated string", start);
			} else {
				let v = _g;
				this.pos = v + 1;
				let p = this.pos - 2;
				while (this.source.charCodeAt(p) == 92) --p;
				if ((p - this.pos & 1) == 0) {
					break;
				};
			};
		};
		return start;
	}
	parseNumber() {
		let $char = this.source.charCodeAt(this.pos);
		if ($char == 46 || $char == 45 || $char < 58 && $char > 47) {
			return this.doParseNumber();
		} else {
			return this.die("number expected");
		};
	}
	doParseNumber() {
		return this.slice(this.skipNumber(this.source.charCodeAt(this.pos++)), this.pos);
	}
	invalidNumber(start) {
		return this.die("Invalid number " + this.source.substring(start, this.pos), start);
	}
	skipNumber(c) {
		let start = this.pos - 1;
		let minus = c == 45;
		let digit = !minus;
		let zero = c == 48;
		let point = false;
		let e = false;
		let pm = false;
		let end = false;
		while (this.pos < this.max) {
			c = this.source.charCodeAt(this.pos++);
			switch (c) {
				case 43:case 45:
					if (!e || pm) {
						this.invalidNumber(start);
					};
					digit = false;
					pm = true;
					break
				case 46:
					if (minus || point) {
						this.invalidNumber(start);
					};
					digit = false;
					point = true;
					break
				case 48:
					if (zero && !point) {
						this.invalidNumber(start);
					};
					if (minus) {
						minus = false;
						zero = true;
					};
					digit = true;
					break
				case 49:case 50:case 51:case 52:case 53:case 54:case 55:case 56:case 57:
					if (zero && !point) {
						this.invalidNumber(start);
					};
					if (minus) {
						minus = false;
					};
					digit = true;
					zero = false;
					break
				case 69:case 101:
					if (minus || zero || e) {
						this.invalidNumber(start);
					};
					digit = false;
					e = true;
					break
				default:
				if (!digit) {
					this.invalidNumber(start);
				};
				this.pos--;
				end = true;
				
			};
			if (end) {
				break;
			};
		};
		return start;
	}
	slice(from, to) {
		return new SliceData(this.source, from, to);
	}
	skipArray() {
		while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
		let tmp;
		if (this.max > this.pos && this.source.charCodeAt(this.pos) == 93) {
			this.pos += 1;
			while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
			tmp = true;
		} else {
			tmp = false;
		};
		if (tmp) {
			return;
		};
		while (true) {
			this.skipValue();
			while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
			let tmp;
			if (this.max > this.pos && this.source.charCodeAt(this.pos) == 44) {
				this.pos += 1;
				while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
				tmp = true;
			} else {
				tmp = false;
			};
			if (!tmp) {
				break;
			};
		};
		while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
		let tmp1;
		if (this.max > this.pos && this.source.charCodeAt(this.pos) == 93) {
			this.pos += 1;
			tmp1 = true;
		} else {
			tmp1 = false;
		};
		if (!tmp1) {
			this.die("Expected " + "]");
		};
	}
	skipValue() {
		let _gthis = this;
		let _g = this.source.charCodeAt(this.pos++);
		switch (_g) {
			case 34:
				this.skipString();
				break
			case 91:
				this.skipArray();
				break
			case 102:
				let tmp;
				if (this.max > this.pos + 3 && this.source.charCodeAt(this.pos) == 97 && this.source.charCodeAt(this.pos + 1) == 108 && this.source.charCodeAt(this.pos + 2) == 115 && this.source.charCodeAt(this.pos + 3) == 101) {
					this.pos += 4;
					tmp = true;
				} else {
					tmp = false;
				};
				if (!tmp) {
					this.die("Expected " + "alse");
				};
				break
			case 110:
				let tmp1;
				if (this.max > this.pos + 2 && this.source.charCodeAt(this.pos) == 117 && this.source.charCodeAt(this.pos + 1) == 108 && this.source.charCodeAt(this.pos + 2) == 108) {
					this.pos += 3;
					tmp1 = true;
				} else {
					tmp1 = false;
				};
				if (!tmp1) {
					this.die("Expected " + "ull");
				};
				break
			case 116:
				let tmp2;
				if (this.max > this.pos + 2 && this.source.charCodeAt(this.pos) == 114 && this.source.charCodeAt(this.pos + 1) == 117 && this.source.charCodeAt(this.pos + 2) == 101) {
					this.pos += 3;
					tmp2 = true;
				} else {
					tmp2 = false;
				};
				if (!tmp2) {
					this.die("Expected " + "rue");
				};
				break
			case 123:
				while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
				let tmp3;
				if (this.max > this.pos && this.source.charCodeAt(this.pos) == 125) {
					this.pos += 1;
					while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
					tmp3 = true;
				} else {
					tmp3 = false;
				};
				if (tmp3) {
					return;
				};
				while (true) {
					if (_gthis.source.charCodeAt(_gthis.pos++) != 34) {
						_gthis.die("expected string", _gthis.pos - 1);
					};
					_gthis.skipString();
					while (_gthis.pos < _gthis.max && _gthis.source.charCodeAt(_gthis.pos) < 33) _gthis.pos++;
					let tmp;
					if (_gthis.max > _gthis.pos && _gthis.source.charCodeAt(_gthis.pos) == 58) {
						_gthis.pos += 1;
						while (_gthis.pos < _gthis.max && _gthis.source.charCodeAt(_gthis.pos) < 33) _gthis.pos++;
						tmp = true;
					} else {
						tmp = false;
					};
					if (!tmp) {
						_gthis.die("Expected " + ":");
					};
					_gthis.skipValue();
					while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
					let tmp1;
					if (this.max > this.pos && this.source.charCodeAt(this.pos) == 44) {
						this.pos += 1;
						while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
						tmp1 = true;
					} else {
						tmp1 = false;
					};
					if (!tmp1) {
						break;
					};
				};
				while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
				let tmp4;
				if (this.max > this.pos && this.source.charCodeAt(this.pos) == 125) {
					this.pos += 1;
					tmp4 = true;
				} else {
					tmp4 = false;
				};
				if (!tmp4) {
					this.die("Expected " + "}");
				};
				break
			default:
			let $char = _g;
			if ($char == 46 || $char == 45 || $char < 58 && $char > 47) {
				this.skipNumber($char);
			} else {
				this.invalidChar($char);
			};
			
		};
	}
	invalidChar(c) {
		return this.die("invalid char " + StringTools.hex(c, 2), this.pos - 1);
	}
	die(s, pos = -1, end = -1) {
		if (pos == -1) {
			pos = this.pos;
			end = pos;
		} else if (end == -1) {
			end = this.pos;
		};
		if (end <= pos) {
			end = pos + 1;
		};
		let range = (end > pos + 1) ? "characters " + pos + " - " + end : "character " + pos;
		let clip = function (s, maxLength, left) {
			if (s.length > maxLength) {
				if (left) {
					return "... " + HxOverrides.substr(s, s.length - maxLength, null);
				} else {
					return HxOverrides.substr(s, 0, maxLength) + " ...";
				};
			} else {
				return s;
			};
		};
		let center = pos + end >> 1;
		let context = clip(this.source.substring(0, pos), 20, true) + "  ---->  " + clip(this.source.substring(pos, center), 20, false) + clip(this.source.substring(center, end), 20, true) + "  <----  " + clip(this.source.substring(end, this.max), 20, false);
		return TypedError.withData(422, s + (" at " + range + " in " + context), {"source": this.source, "start": pos, "end": end}, {"fileName": "tink/json/Parser.hx", "lineNumber": 430, "className": "tink.json.BasicParser", "methodName": "die"}).throwSelf();
	}
	parseBool() {
		while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
		let tmp;
		if (this.max > this.pos + 3 && this.source.charCodeAt(this.pos) == 116 && this.source.charCodeAt(this.pos + 1) == 114 && this.source.charCodeAt(this.pos + 2) == 117 && this.source.charCodeAt(this.pos + 3) == 101) {
			this.pos += 4;
			while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
			tmp = true;
		} else {
			tmp = false;
		};
		if (tmp) {
			return true;
		} else {
			while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
			let tmp;
			if (this.max > this.pos + 4 && this.source.charCodeAt(this.pos) == 102 && this.source.charCodeAt(this.pos + 1) == 97 && this.source.charCodeAt(this.pos + 2) == 108 && this.source.charCodeAt(this.pos + 3) == 115 && this.source.charCodeAt(this.pos + 4) == 101) {
				this.pos += 5;
				while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
				tmp = true;
			} else {
				tmp = false;
			};
			if (tmp) {
				return false;
			} else {
				return this.die("expected boolean value");
			};
		};
	}
	static get __name__() {
		return "tink.json.BasicParser"
	}
	get __class__() {
		return BasicParser
	}
}


BasicParser.DBQT = (function($this) {var $r0
	let this1 = String.fromCodePoint(34);
	
	$r0 = this1
	return $r0})(this)