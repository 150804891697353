import {Representation_Impl_} from "../json/Representation"
import {Register} from "../../genes/Register"

export const List_Impl_ = Register.global("$hxClasses")["tink.pure._List.List_Impl_"] = 
class List_Impl_ {
	static prepend(this1, value) {
		if (this1 == null) {
			return new Node(1, value);
		} else {
			return new Node(this1.length + 1, value, [this1]);
		};
	}
	static filter(this1, f) {
		if (this1 == null) {
			return null;
		} else {
			return this1.filter(f);
		};
	}
	static fromArray(i) {
		let ret = null;
		let len = 0;
		let pos = i.length;
		while (pos-- > 0) ret = new Node(++len, i[pos], (ret == null) ? Node.EMPTY : [ret]);
		return ret;
	}
	static ofRepresentation(rep) {
		return List_Impl_.fromArray(Representation_Impl_.get(rep));
	}
	static get __name__() {
		return "tink.pure._List.List_Impl_"
	}
	get __class__() {
		return List_Impl_
	}
}


export const Node = Register.global("$hxClasses")["tink.pure._List.Node"] = 
class Node extends Register.inherits() {
	new(length, value, tails = null) {
		this.value = value;
		this.length = length;
		this.tails = (tails == null) ? Node.EMPTY : tails;
	}
	filter(f) {
		let iter = new NodeIterator(this);
		let ret = [];
		while (iter.list.length > 0) {
			let value = iter.next();
			let res = f(value);
			if (res > 0) {
				ret.push(value);
			};
			if ((res & 3) == 3) {
				break;
			};
		};
		return List_Impl_.fromArray(ret);
	}
	static get __name__() {
		return "tink.pure._List.Node"
	}
	get __class__() {
		return Node
	}
}


Node.EMPTY = []
export const NodeIterator = Register.global("$hxClasses")["tink.pure.NodeIterator"] = 
class NodeIterator extends Register.inherits() {
	new(node) {
		this.list = [];
		if (node != null) {
			this.list.push(node);
		};
	}
	hasNext() {
		return this.list.length > 0;
	}
	next() {
		let _g = this.list.pop();
		if (_g == null) {
			return null;
		} else {
			let next = _g;
			let _g1 = -next.tails.length;
			let _g2 = 0;
			while (_g1 < _g2) {
				let i = _g1++;
				this.list.push(next.tails[-i - 1]);
			};
			return next.value;
		};
	}
	static get __name__() {
		return "tink.pure.NodeIterator"
	}
	get __class__() {
		return NodeIterator
	}
}

