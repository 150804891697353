import VirtualKeyboardDetector from "virtual-keyboard-detector"
import {AutoObservable, Computation_Impl_} from "../../../../tink/state/internal/AutoObservable"
import {State_Impl_} from "../../../../tink/state/State"
import {ConstObservable, Observable_Impl_, Transform_Impl_} from "../../../../tink/state/Observable"
import {List_Impl_, NodeIterator} from "../../../../tink/pure/List"
import {ClassName_Impl_} from "../../../../tink/domspec/ClassName"
import {Signal_Impl_} from "../../../../tink/core/Signal"
import {Outcome} from "../../../../tink/core/Outcome"
import {Future_Impl_, SuspendableFuture} from "../../../../tink/core/Future"
import {SimpleLink} from "../../../../tink/core/Callback"
import {ReactType_Impl_} from "../../../../react/ReactType"
import * as React from "react"
import {Page} from "./Page"
import {Recorder} from "../../component/app/Recorder"
import {PageLoader} from "../../component/app/PageLoader"
import {OutlineButton} from "../../component/app/OutlineButton"
import {Thumbnail} from "../../component/Thumbnail"
import {SafeAreaView} from "../../component/SafeAreaView"
import {PngIcon} from "../../component/PngIcon"
import {ErrorBox} from "../../component/ErrorBox"
import {AttachmentType} from "../../../types/AttachmentType"
import {StringMap} from "../../../../haxe/ds/StringMap"
import {Register} from "../../../../genes/Register"
import {Slot} from "../../../../coconut/ui/internal/Slot"
import {View} from "../../../../coconut/react/View"
import {Html} from "../../../../coconut/react/Html"
import {Annex} from "../../../../coconut/data/helpers/Annex"
import {Model} from "../../../../coconut/data/Model"
import {StringTools} from "../../../../StringTools"
import {EReg} from "../../../../EReg"
import {ExitToApp, Send, MicNone, ImageOutlined, PermMedia, CameraAlt, Close, PlayArrow, Pause} from "@material-ui/icons"
import * as Styles from "@material-ui/core/styles"
import {IconButton, Typography, Toolbar, TextareaAutosize, TextField, DialogTitle, Avatar, ListItemAvatar, ListItemText, ListItem, List, Dialog, Modal} from "@material-ui/core"

export const Attachment = Register.global("$hxClasses")["letzbig.ui.page.app.Attachment"] = 
class Attachment extends Register.inherits() {
	new(__coco_init) {
		let _gthis = this;
		this.blob = __coco_init.blob;
		let before = AutoObservable.cur;
		AutoObservable.cur = null;
		let ret = new AutoObservable(Computation_Impl_.sync(function () {
			return URL.createObjectURL(_gthis.blob);
		}), null, null);
		AutoObservable.cur = before;
		this.__coco_objectUrl = ret;
		let before1 = AutoObservable.cur;
		AutoObservable.cur = null;
		let ret1 = new AutoObservable(Computation_Impl_.sync(function () {
			if (new EReg("video/", "").match(_gthis.blob.type)) {
				return AttachmentType.Video;
			} else if (new EReg("audio/", "").match(_gthis.blob.type)) {
				return AttachmentType.Audio;
			} else {
				return AttachmentType.Photo;
			};
		}), null, null);
		AutoObservable.cur = before1;
		this.__coco_type = ret1;
		this.__coco_transitionCount = State_Impl_._new(0);
		this.errorTrigger = Signal_Impl_.trigger();
		this.transitionErrors = this.errorTrigger;
		this.annex = new Annex(this);
		this.observables = {"blob": new ConstObservable(this.blob, null), "objectUrl": this.__coco_objectUrl, "type": this.__coco_type, "isInTransition": Observable_Impl_.map(this.__coco_transitionCount, Transform_Impl_.plain(function (count) {
			return count > 0;
		}))};
	}
	static get __name__() {
		return "letzbig.ui.page.app.Attachment"
	}
	static get __interfaces__() {
		return [Model]
	}
	get __class__() {
		return Attachment
	}
}


export const FeedbackEditorPage = Register.global("$hxClasses")["letzbig.ui.page.app.FeedbackEditorPage"] = 
class FeedbackEditorPage extends Register.inherits(View) {
	new(__coco_data_) {
		this.__coco_classes = new Slot(this);
		let _gthis = this;
		this.__coco_loading = new Slot(this, null, new ConstObservable(false, null));
		this.__coco_onSubmit = new Slot(this, null, null);
		this.__coco_onLogin = new Slot(this, null, null);
		this.__coco_product = new Slot(this, null, null);
		this.__coco_text = new Slot(this, null, null);
		this.__coco_attachments = new Slot(this, null, null);
		this.__coco_keyboardVisible = State_Impl_._new(false, null);
		this.__coco_recorderOpen = State_Impl_._new(false, null);
		this.__initAttributes(__coco_data_);
		let snapshot = null;
		super.new(function () {
			return _gthis.__coco_render();
		}, null, null, null, function (firstTime) {
			if (firstTime) {
				_gthis.viewDidMount();
			};
		});
	}
	__coco_render() {
		let _gthis = this;
		let tmp = new AutoObservable(Computation_Impl_.sync(function () {
			let __r = [];
			let _g = Observable_Impl_.get_value(_gthis.__coco_product);
			switch (_g._hx_index) {
				case 0:
					__r.push(PageLoader.fromHxx({}, {}));
					break
				case 1:
					let product = _g.result;
					let attributes = {"className": Observable_Impl_.get_value(_gthis.__coco_classes).header, "style": {"backgroundImage": "url(" + product.thumbnailUrl + ")"}};
					let __r1 = [];
					__r1.push(Html.h("div", {"className": ClassName_Impl_.ofString("underlay")}, null));
					let compute = new ConstObservable(true, null);
					let compute1 = new AutoObservable(Computation_Impl_.sync(function () {
						let props = {"className": Observable_Impl_.get_value(_gthis.__coco_classes).toolbar};
						let __r = [];
						let _g = Observable_Impl_.get_value(_gthis.__coco_onLogin);
						if (_g != null) {
							let f = _g;
							let props = {"edge": "start", "className": Observable_Impl_.get_value(_gthis.__coco_classes).iconButton, "onClick": f};
							let children = [React.createElement(ReactType_Impl_.fromComp(ExitToApp), {})];
							let compute = [ReactType_Impl_.fromComp(IconButton), props].concat(children);
							__r.push(React.createElement.apply(React, compute));
						};
						let compute = [ReactType_Impl_.fromComp(Typography), {"variant": "h6", "className": "title"}].concat(["Share Feedback"]);
						__r.push(React.createElement.apply(React, compute));
						let props1 = {"edge": "end", "className": Observable_Impl_.get_value(_gthis.__coco_classes).iconButton, "onClick": Observable_Impl_.get_value(_gthis.__coco_onSubmit)};
						let children = [React.createElement(ReactType_Impl_.fromComp(Send), {})];
						let compute1 = [ReactType_Impl_.fromComp(IconButton), props1].concat(children);
						__r.push(React.createElement.apply(React, compute1));
						let compute2 = [ReactType_Impl_.fromComp(Toolbar), props].concat(__r);
						return [React.createElement.apply(React, compute2)];
					}), null, null);
					__r1.push(SafeAreaView.fromHxx({}, {"top": compute, "children": compute1}));
					let attributes1 = {"className": ClassName_Impl_.add(ClassName_Impl_.ofString("content"), ClassName_Impl_.when(ClassName_Impl_.ofString("hidden"), State_Impl_.get_value(_gthis.__coco_keyboardVisible)))};
					let __r2 = [];
					let compute2 = new ConstObservable(ClassName_Impl_.ofString("product thumbnail"), null);
					let compute3 = new AutoObservable(Computation_Impl_.sync(function () {
						return product.thumbnailUrl;
					}), null, null);
					__r2.push(Thumbnail.fromHxx({}, {"className": compute2, "src": compute3}));
					let attributes2 = {"className": ClassName_Impl_.ofString("product title")};
					let __r3 = [];
					let children = [product.brand];
					let compute4 = [ReactType_Impl_.fromComp(Typography), {"variant": "h6"}].concat(children);
					__r3.push(React.createElement.apply(React, compute4));
					let children1 = [product.name];
					let compute5 = [ReactType_Impl_.fromComp(Typography), {"variant": "subtitle1"}].concat(children1);
					__r3.push(React.createElement.apply(React, compute5));
					__r2.push(Html.h("div", attributes2, __r3));
					__r1.push(Html.h("div", attributes1, __r2));
					__r.push(Html.h("div", attributes, __r1));
					let attributes3 = {"className": Observable_Impl_.get_value(_gthis.__coco_classes).content};
					let __r4 = [];
					let attributes4 = {"className": ClassName_Impl_.ofString("title")};
					let __r5 = [];
					let compute6 = new ConstObservable(ClassName_Impl_.ofString("icon"), null);
					let compute7 = new ConstObservable("feedback/feeling_ic", null);
					__r5.push(PngIcon.fromHxx({}, {"className": compute6, "name": compute7}));
					let compute8 = [ReactType_Impl_.fromComp(Typography), {"className": "text", "variant": "h6"}].concat(["Let us know what you think!"]);
					__r5.push(React.createElement.apply(React, compute8));
					__r4.push(Html.h("div", attributes4, __r5));
					let props = {"fullWidth": true, "multiline": true, "InputProps": {"inputComponent": ReactType_Impl_.fromComp(TextareaAutosize), "rows": 2, "disableUnderline": true}, "value": Observable_Impl_.get_value(_gthis.__coco_text), "onChange": function (e) {
						_gthis.set_text(e.target.value);
						Observable_Impl_.updateAll();
					}, "placeholder": "You may type or record voice."};
					let compute9 = [ReactType_Impl_.fromComp(TextField), props].concat(null);
					__r4.push(React.createElement.apply(React, compute9));
					__r.push(Html.h("div", attributes3, __r4));
					let target = new AutoObservable(Computation_Impl_.sync(function () {
						return _gthis;
					}), null, null);
					let compute10 = State_Impl_.compound(new AutoObservable(Computation_Impl_.sync(function () {
						return Observable_Impl_.get_value(Observable_Impl_.get_value(target).__coco_attachments);
					}), null, null), function (value) {
						Observable_Impl_.get_value(target).set_attachments(value);
					});
					__r.push(AttachmentList.fromHxx({}, {"attachments": compute10}));
					if (!State_Impl_.get_value(_gthis.__coco_keyboardVisible)) {
						let compute = new ConstObservable(true, null);
						let compute1 = new AutoObservable(Computation_Impl_.sync(function () {
							let attributes = {"className": Observable_Impl_.get_value(_gthis.__coco_classes).footer};
							let __r = [];
							let compute = new ConstObservable("Voice", null);
							let compute1 = new AutoObservable(Computation_Impl_.sync(function () {
								return React.createElement(ReactType_Impl_.fromComp(MicNone), {});
							}), null, null);
							let compute2 = new AutoObservable(Computation_Impl_.sync(function () {
								return function () {
									_gthis.__coco_recorderOpen.set(true);
								};
							}), null, null);
							__r.push(OutlineButton.fromHxx({}, {"label": compute, "icon": compute1, "onClick": compute2}));
							let compute3 = new AutoObservable(Computation_Impl_.sync(function () {
								return Register.bind(_gthis, _gthis.addAttachment);
							}), null, null);
							__r.push(PhotoButton.fromHxx({}, {"onSelect": compute3}));
							return [Html.h("div", attributes, __r)];
						}), null, null);
						__r.push(SafeAreaView.fromHxx({}, {"bottom": compute, "children": compute1}));
					};
					if (State_Impl_.get_value(_gthis.__coco_recorderOpen)) {
						let compute = new ConstObservable(true, null);
						let compute1 = new AutoObservable(Computation_Impl_.sync(function () {
							return function (blob) {
								_gthis.addAttachment(blob);
								_gthis.__coco_recorderOpen.set(false);
							};
						}), null, null);
						let compute2 = new AutoObservable(Computation_Impl_.sync(function () {
							return function () {
								_gthis.__coco_recorderOpen.set(false);
							};
						}), null, null);
						__r.push(Recorder.fromHxx({}, {"open": compute, "onComplete": compute1, "onClose": compute2}));
					};
					break
				case 2:
					let error = _g.error;
					let compute11 = new ConstObservable("Cannot load product", null);
					let compute12 = new AutoObservable(Computation_Impl_.sync(function () {
						return error.message;
					}), null, null);
					__r.push(ErrorBox.fromHxx({}, {"title": compute11, "message": compute12}));
					break
				
			};
			return __r;
		}), null, null);
		return Page.fromHxx({}, {"children": tmp});
	}
	viewDidMount() {
		let detector = VirtualKeyboardDetector.INSTANCE;
		let _gthis = this;
		let onKeyboard = function (e) {
			let param = e.visible;
			_gthis.__coco_keyboardVisible.set(param);
		};
		detector.addEventListener(VirtualKeyboardDetector.TYPE, onKeyboard);
		let _g = Register.bind(detector, detector.removeEventListener);
		let type = VirtualKeyboardDetector.TYPE;
		let listener = onKeyboard;
		let this1 = new SimpleLink(function () {
			_g(type, listener);
		});
		this.__bu.push(this1);
	}
	addAttachment(blob) {
		this.set_attachments(List_Impl_.prepend(Observable_Impl_.get_value(this.__coco_attachments), new Attachment({"blob": blob})));
	}
	set_text(param) {
		let _g = this.__coco_text.data;
		if (_g != null) {
			let v = _g;
			v.set(param);
		};
		return param;
	}
	set_attachments(param) {
		let _g = this.__coco_attachments.data;
		if (_g != null) {
			let v = _g;
			v.set(param);
		};
		return param;
	}
	__initAttributes(attributes) {
		this.__coco_loading.setData(attributes.loading);
		this.__coco_onSubmit.setData(attributes.onSubmit);
		this.__coco_onLogin.setData(attributes.onLogin);
		this.__coco_product.setData(attributes.product);
		this.__coco_text.setData(attributes.text);
		this.__coco_attachments.setData(attributes.attachments);
		let value = attributes.classes;
		this.__coco_classes.setData(new ConstObservable(value, null));
	}
	static styles(theme) {
		let tmp = {"backgroundSize": "cover", "backgroundPosition": "center", "position": "relative", "& > .underlay": {"backgroundColor": "rgba(89,89,89,.95)", "position": "absolute", "left": 0, "right": 0, "top": 0, "bottom": -theme.spacing(2), "backdropFilter": "blur(2px)"}, "& > .content": {"transition": "padding-top .25s, padding-bottom .25s, padding-left .25s .1s, max-height .25s, opacity .25s .1s", "position": "relative", "maxHeight": "100vh", "padding": theme.spacing(2, 3, 3, 3), "display": "flex", "& .product.thumbnail": {"width": theme.spacing(8), "height": theme.spacing(8)}, "& .product.title": {"color": "rgba(255,255,255,0.8)", "paddingLeft": theme.spacing(2)}}, "& > .content.hidden": {"transition": "padding-top .25s, padding-bottom .25s, padding-left .25s, max-height .25s, opacity .25s", "opacity": 0, "maxHeight": 0, "padding": theme.spacing(0, 3, 0, 10)}};
		let tmp1 = {"color": "white", "height": theme.spacing(7), "& > .title": {"flexGrow": 1, "textAlign": "center"}};
		let tmp2 = {"flexGrow": "1", "overflowY": "scroll", "position": "relative", "padding": theme.spacing(3), "backgroundColor": "white", "borderTopRightRadius": theme.spacing(1.5), "borderTopLeftRadius": theme.spacing(1.5), "& > .title": {"height": theme.spacing(4), "marginBottom": theme.spacing(2), "display": "flex", "alignItems": "center", "& > .icon": {"marginBottom": theme.spacing(0.5)}, "& > .text": {"marginLeft": theme.spacing(1)}}};
		let tmp3 = theme.spacing(2, 3, 2, 3);
		let tmp4 = theme.spacing(1.5);
		return {"header": tmp, "toolbar": tmp1, "iconButton": {"color": "white"}, "content": tmp2, "footer": {"flexShrink": "0", "display": "flex", "padding": tmp3, "& > *": {"flex": 1, "marginRight": tmp4}, "& > *:last-child": {"marginRight": 0}}};
	}
	static fromHxx(hxxMeta, attributes) {
		return {"$$typeof": View.TRE, "type": FeedbackEditorPage.__hoc, "props": attributes, "key": hxxMeta.key, "ref": (!hxxMeta.ref) ? null : hxxMeta.ref};
	}
	static get __name__() {
		return "letzbig.ui.page.app.FeedbackEditorPage"
	}
	static get __super__() {
		return View
	}
	get __class__() {
		return FeedbackEditorPage
	}
}


FeedbackEditorPage.__hoc = (Styles.withStyles(FeedbackEditorPage.styles))(FeedbackEditorPage)
export const AudioButton = Register.global("$hxClasses")["letzbig.ui.page.app.AudioButton"] = 
class AudioButton extends Register.inherits(View) {
	new(__coco_data_) {
		this.__coco_onSelect = new Slot(this, null, null);
		let _gthis = this;
		this.__initAttributes(__coco_data_);
		let snapshot = null;
		super.new(function () {
			return _gthis.__coco_render();
		}, null, null, null, null);
	}
	onSelect(a0) {
		(Observable_Impl_.get_value(this.__coco_onSelect))(a0);
	}
	__coco_render() {
		let _gthis = this;
		let tmp = new ConstObservable("Voice", null);
		let tmp1 = new AutoObservable(Computation_Impl_.sync(function () {
			return React.createElement(ReactType_Impl_.fromComp(MicNone), {});
		}), null, null);
		let tmp2 = new AutoObservable(Computation_Impl_.sync(function () {
			return Register.bind(_gthis, _gthis.file);
		}), null, null);
		return OutlineButton.fromHxx({}, {"label": tmp, "icon": tmp1, "onClick": tmp2});
	}
	file() {
		let _gthis = this;
		let input = window.document.createElement("input");
		input.type = "file";
		input.accept = "audio/*";
		input.onchange = function (e) {
			let _g = 0;
			let _g1 = input.files;
			while (_g < _g1.length) {
				let file = _g1[_g];
				++_g;
				_gthis.onSelect(file);
			};
		};
		input.click();
	}
	__initAttributes(attributes) {
		this.__coco_onSelect.setData(attributes.onSelect);
	}
	static get __name__() {
		return "letzbig.ui.page.app.AudioButton"
	}
	static get __super__() {
		return View
	}
	get __class__() {
		return AudioButton
	}
}


export const PhotoButton = Register.global("$hxClasses")["letzbig.ui.page.app.PhotoButton"] = 
class PhotoButton extends Register.inherits(View) {
	new(__coco_data_) {
		this.__coco_onSelect = new Slot(this, null, null);
		this.__coco_open = State_Impl_._new(false, null);
		let _gthis = this;
		this.__initAttributes(__coco_data_);
		let snapshot = null;
		super.new(function () {
			return _gthis.__coco_render();
		}, null, null, null, null);
	}
	onSelect(a0) {
		(Observable_Impl_.get_value(this.__coco_onSelect))(a0);
	}
	__coco_render() {
		let _gthis = this;
		let __r = [];
		let tmp = new ConstObservable("Photo/Video", null);
		let tmp1 = new AutoObservable(Computation_Impl_.sync(function () {
			return React.createElement(ReactType_Impl_.fromComp(ImageOutlined), {});
		}), null, null);
		let tmp2 = new AutoObservable(Computation_Impl_.sync(function () {
			return function () {
				_gthis.__coco_open.set(true);
			};
		}), null, null);
		__r.push(OutlineButton.fromHxx({}, {"label": tmp, "icon": tmp1, "onClick": tmp2}));
		let props = {"onClose": function () {
			_gthis.__coco_open.set(false);
			return false;
		}, "open": State_Impl_.get_value(this.__coco_open)};
		let __r1 = [];
		let tmp3 = [ReactType_Impl_.fromComp(DialogTitle), {"id": "simple-dialog-title"}].concat(["Attach Photos"]);
		__r1.push(React.createElement.apply(React, tmp3));
		let __r2 = [];
		let __r3 = [];
		let children = [React.createElement(ReactType_Impl_.fromComp(PermMedia), {})];
		let children1 = [ReactType_Impl_.fromComp(Avatar), {}].concat(children);
		let children2 = React.createElement.apply(React, children1);
		let tmp4 = [ReactType_Impl_.fromComp(ListItemAvatar), {}].concat(children2);
		__r3.push(React.createElement.apply(React, tmp4));
		let tmp5 = [ReactType_Impl_.fromComp(ListItemText), {"primary": "File"}].concat(null);
		__r3.push(React.createElement.apply(React, tmp5));
		let tmp6 = [ReactType_Impl_.fromComp(ListItem), {"button": true, "onClick": Register.bind(this, this.file)}].concat(__r3);
		__r2.push(React.createElement.apply(React, tmp6));
		let __r4 = [];
		let children3 = [React.createElement(ReactType_Impl_.fromComp(CameraAlt), {})];
		let children4 = [ReactType_Impl_.fromComp(Avatar), {}].concat(children3);
		let children5 = React.createElement.apply(React, children4);
		let tmp7 = [ReactType_Impl_.fromComp(ListItemAvatar), {}].concat(children5);
		__r4.push(React.createElement.apply(React, tmp7));
		let tmp8 = [ReactType_Impl_.fromComp(ListItemText), {"primary": "Camera"}].concat(null);
		__r4.push(React.createElement.apply(React, tmp8));
		let tmp9 = [ReactType_Impl_.fromComp(ListItem), {"button": true}].concat(__r4);
		__r2.push(React.createElement.apply(React, tmp9));
		let tmp10 = [ReactType_Impl_.fromComp(List), {}].concat(__r2);
		__r1.push(React.createElement.apply(React, tmp10));
		let tmp11 = [ReactType_Impl_.fromComp(Dialog), props].concat(__r1);
		__r.push(React.createElement.apply(React, tmp11));
		return View.createFragment({}, __r);
	}
	file() {
		let _gthis = this;
		let input = window.document.createElement("input");
		input.type = "file";
		input.accept = "image/*,video/*";
		input.onchange = function (e) {
			let _g = 0;
			let _g1 = input.files;
			while (_g < _g1.length) {
				let file = _g1[_g];
				++_g;
				_gthis.onSelect(file);
			};
		};
		input.click();
		this.__coco_open.set(false);
	}
	__initAttributes(attributes) {
		this.__coco_onSelect.setData(attributes.onSelect);
	}
	static fromHxx(hxxMeta, attributes) {
		return {"$$typeof": View.TRE, "type": PhotoButton, "props": attributes, "key": hxxMeta.key, "ref": (!hxxMeta.ref) ? null : hxxMeta.ref};
	}
	static get __name__() {
		return "letzbig.ui.page.app.PhotoButton"
	}
	static get __super__() {
		return View
	}
	get __class__() {
		return PhotoButton
	}
}


export const AttachmentList = Register.global("$hxClasses")["letzbig.ui.page.app.AttachmentList"] = 
class AttachmentList extends Register.inherits(View) {
	new(__coco_data_) {
		this.__coco_classes = new Slot(this);
		this.__coco_attachments = new Slot(this, null, null);
		let _gthis = this;
		this.__initAttributes(__coco_data_);
		let snapshot = null;
		super.new(function () {
			return _gthis.__coco_render();
		}, null, null, null, null);
	}
	__coco_render() {
		let _gthis = this;
		let attributes = {"className": Observable_Impl_.get_value(this.__coco_classes).root};
		let __r = [];
		let _g = new NodeIterator(Observable_Impl_.get_value(this.__coco_attachments));
		while (_g.list.length > 0) {
			let attachment = _g.next();
			__r.push(AttachmentView.fromHxx({"key": Observable_Impl_.get_value(attachment.__coco_objectUrl)}, {"attachment": new AutoObservable(Computation_Impl_.sync(function () {
				return attachment;
			}), null, null), "onDelete": new AutoObservable(Computation_Impl_.sync(function () {
				return function () {
					_gthis.set_attachments(List_Impl_.filter(Observable_Impl_.get_value(_gthis.__coco_attachments), function (v) {
						if (v != attachment) {
							return 1;
						} else {
							return 0;
						};
					}));
				};
			}), null, null)}));
		};
		return Html.h("div", attributes, __r);
	}
	set_attachments(param) {
		let _g = this.__coco_attachments.data;
		if (_g != null) {
			let v = _g;
			v.set(param);
		};
		return param;
	}
	__initAttributes(attributes) {
		this.__coco_attachments.setData(attributes.attachments);
		let value = attributes.classes;
		this.__coco_classes.setData(new ConstObservable(value, null));
	}
	static styles(theme) {
		return {"root": {"display": "flex", "flexWrap": "wrap", "margin": theme.spacing(3, 2, 3, 2)}};
	}
	static fromHxx(hxxMeta, attributes) {
		return {"$$typeof": View.TRE, "type": AttachmentList.__hoc, "props": attributes, "key": hxxMeta.key, "ref": (!hxxMeta.ref) ? null : hxxMeta.ref};
	}
	static get __name__() {
		return "letzbig.ui.page.app.AttachmentList"
	}
	static get __super__() {
		return View
	}
	get __class__() {
		return AttachmentList
	}
}


AttachmentList.__hoc = (Styles.withStyles(AttachmentList.styles))(AttachmentList)
export const AttachmentView = Register.global("$hxClasses")["letzbig.ui.page.app.AttachmentView"] = 
class AttachmentView extends Register.inherits(View) {
	new(__coco_data_) {
		this.__coco_classes = new Slot(this);
		this.__coco_attachment = new Slot(this, null, null);
		this.__coco_onDelete = new Slot(this, null, null);
		let _gthis = this;
		this.__initAttributes(__coco_data_);
		let snapshot = null;
		super.new(function () {
			return _gthis.__coco_render();
		}, null, null, null, null);
	}
	__coco_render() {
		let _gthis = this;
		let attributes = {"className": Observable_Impl_.get_value(this.__coco_classes).root};
		let __r = [];
		switch (Observable_Impl_.get_value(Observable_Impl_.get_value(this.__coco_attachment).__coco_type)._hx_index) {
			case 0:
				let tmp = new AutoObservable(Computation_Impl_.sync(function () {
					return Observable_Impl_.get_value(_gthis.__coco_attachment);
				}), null, null);
				__r.push(PhotoViewer.fromHxx({}, {"attachment": tmp}));
				break
			case 1:
				let tmp1 = new AutoObservable(Computation_Impl_.sync(function () {
					return Observable_Impl_.get_value(_gthis.__coco_attachment);
				}), null, null);
				__r.push(VideoPlayer.fromHxx({}, {"attachment": tmp1}));
				break
			case 2:
				let tmp2 = new AutoObservable(Computation_Impl_.sync(function () {
					return Observable_Impl_.get_value(_gthis.__coco_attachment);
				}), null, null);
				__r.push(AudioPlayer.fromHxx({}, {"attachment": tmp2}));
				break
			
		};
		__r.push(Html.h("div", {"className": Observable_Impl_.get_value(this.__coco_classes)["delete"], "onClick": Observable_Impl_.get_value(this.__coco_onDelete)}, [React.createElement(ReactType_Impl_.fromComp(Close), {})]));
		return Html.h("div", attributes, __r);
	}
	__initAttributes(attributes) {
		this.__coco_attachment.setData(attributes.attachment);
		this.__coco_onDelete.setData(attributes.onDelete);
		let value = attributes.classes;
		this.__coco_classes.setData(new ConstObservable(value, null));
	}
	static styles(theme) {
		return {"root": {"position": "relative", "borderRadius": theme.spacing(1), "width": theme.spacing(9), "height": theme.spacing(9), "margin": theme.spacing(1), "boxShadow": "0px 2px 4px rgba(0,0,0,.1)", "& .button": {"width": theme.spacing(4), "height": theme.spacing(4), "borderRadius": theme.spacing(2), "position": "absolute", "cursor": "pointer", "left": theme.spacing(2.5), "top": theme.spacing(2.5), "display": "flex", "alignItems": "center", "justifyContent": "center"}, "& .shadowed.button": {"boxShadow": "0px 2px 4px rgba(0,0,0,.2)"}}, "delete": {"width": theme.spacing(2.5), "height": theme.spacing(2.5), "borderRadius": theme.spacing(1.25), "backgroundColor": theme.palette.primary.dark, "position": "absolute", "cursor": "pointer", "right": theme.spacing(-1), "top": theme.spacing(-1), "display": "flex", "alignItems": "center", "justifyContent": "center", "& > svg": {"color": "white", "fontSize": theme.spacing(2)}}};
	}
	static fromHxx(hxxMeta, attributes) {
		return {"$$typeof": View.TRE, "type": AttachmentView.__hoc, "props": attributes, "key": hxxMeta.key, "ref": (!hxxMeta.ref) ? null : hxxMeta.ref};
	}
	static get __name__() {
		return "letzbig.ui.page.app.AttachmentView"
	}
	static get __super__() {
		return View
	}
	get __class__() {
		return AttachmentView
	}
}


AttachmentView.__hoc = (Styles.withStyles(AttachmentView.styles))(AttachmentView)
export const PhotoViewer = Register.global("$hxClasses")["letzbig.ui.page.app.PhotoViewer"] = 
class PhotoViewer extends Register.inherits(View) {
	new(__coco_data_) {
		this.__coco_classes = new Slot(this);
		this.__coco_attachment = new Slot(this, null, null);
		this.__coco_open = State_Impl_._new(false, null);
		let _gthis = this;
		this.__initAttributes(__coco_data_);
		let snapshot = null;
		super.new(function () {
			return _gthis.__coco_render();
		}, null, null, null, null);
	}
	__coco_render() {
		let _gthis = this;
		let __r = [];
		__r.push(Html.h("img", {"className": Observable_Impl_.get_value(this.__coco_classes).thumbnail, "src": Observable_Impl_.get_value(Observable_Impl_.get_value(this.__coco_attachment).__coco_objectUrl), "onClick": function () {
			_gthis.__coco_open.set(true);
			return true;
		}}));
		let props = {"className": Observable_Impl_.get_value(this.__coco_classes).container, "open": State_Impl_.get_value(this.__coco_open), "onClose": function () {
			_gthis.__coco_open.set(false);
			return false;
		}};
		let children = Html.h("img", {"className": Observable_Impl_.get_value(this.__coco_classes).enlarged, "src": Observable_Impl_.get_value(Observable_Impl_.get_value(this.__coco_attachment).__coco_objectUrl)});
		let tmp = [ReactType_Impl_.fromComp(Modal), props].concat(children);
		__r.push(React.createElement.apply(React, tmp));
		return View.createFragment({}, __r);
	}
	__initAttributes(attributes) {
		this.__coco_attachment.setData(attributes.attachment);
		let value = attributes.classes;
		this.__coco_classes.setData(new ConstObservable(value, null));
	}
	static styles(theme) {
		return {"container": {"display": "flex", "alignItems": "center", "justifyContent": "center", "backgroundColor": "rgba(0,0,0,.75)"}, "thumbnail": {"width": "100%", "height": "100%", "borderRadius": "inherit", "objectFit": "cover"}, "enlarged": {"width": "100%", "height": "100%", "objectFit": "contain", "pointerEvents": "none"}};
	}
	static fromHxx(hxxMeta, attributes) {
		return {"$$typeof": View.TRE, "type": PhotoViewer.__hoc, "props": attributes, "key": hxxMeta.key, "ref": (!hxxMeta.ref) ? null : hxxMeta.ref};
	}
	static get __name__() {
		return "letzbig.ui.page.app.PhotoViewer"
	}
	static get __super__() {
		return View
	}
	get __class__() {
		return PhotoViewer
	}
}


PhotoViewer.__hoc = (Styles.withStyles(PhotoViewer.styles))(PhotoViewer)
export const VideoPlayer = Register.global("$hxClasses")["letzbig.ui.page.app.VideoPlayer"] = 
class VideoPlayer extends Register.inherits(View) {
	new(__coco_data_) {
		this.__coco_classes = new Slot(this);
		this.__coco_attachment = new Slot(this, null, null);
		this.__coco_open = State_Impl_._new(false, null);
		let _gthis = this;
		this.__initAttributes(__coco_data_);
		let snapshot = null;
		super.new(function () {
			return _gthis.__coco_render();
		}, null, null, null, null);
	}
	__coco_render() {
		let _gthis = this;
		let __r = [];
		__r.push(Html.h("video", {"className": Observable_Impl_.get_value(this.__coco_classes).image}, [Html.h("source", {"src": Observable_Impl_.get_value(Observable_Impl_.get_value(this.__coco_attachment).__coco_objectUrl), "type": Observable_Impl_.get_value(this.__coco_attachment).blob.type})]));
		__r.push(Html.h("div", {"className": ClassName_Impl_.add(Observable_Impl_.get_value(this.__coco_classes).button, ClassName_Impl_.ofString("shadowed button")), "onClick": function () {
			_gthis.__coco_open.set(true);
			return true;
		}}, [React.createElement(ReactType_Impl_.fromComp(PlayArrow), {})]));
		let props = {"className": Observable_Impl_.get_value(this.__coco_classes).container, "open": State_Impl_.get_value(this.__coco_open), "onClose": function () {
			_gthis.__coco_open.set(false);
			return false;
		}};
		let children = Html.h("video", {"className": Observable_Impl_.get_value(this.__coco_classes).video, "controls": true}, [Html.h("source", {"src": Observable_Impl_.get_value(Observable_Impl_.get_value(this.__coco_attachment).__coco_objectUrl)})]);
		let tmp = [ReactType_Impl_.fromComp(Modal), props].concat(children);
		__r.push(React.createElement.apply(React, tmp));
		return View.createFragment({}, __r);
	}
	__initAttributes(attributes) {
		this.__coco_attachment.setData(attributes.attachment);
		let value = attributes.classes;
		this.__coco_classes.setData(new ConstObservable(value, null));
	}
	static styles(theme) {
		let theme1 = theme.palette.secondary.main;
		let tmp = theme.spacing(3);
		return {"container": {"display": "flex", "alignItems": "center", "justifyContent": "center", "backgroundColor": "rgba(0,0,0,.75)"}, "image": {"width": "100%", "height": "100%", "borderRadius": "inherit", "objectFit": "cover"}, "video": {"width": "100%", "height": "auto"}, "button": {"backgroundColor": theme1, "& > svg": {"color": "white", "fontSize": tmp}}};
	}
	static fromHxx(hxxMeta, attributes) {
		return {"$$typeof": View.TRE, "type": VideoPlayer.__hoc, "props": attributes, "key": hxxMeta.key, "ref": (!hxxMeta.ref) ? null : hxxMeta.ref};
	}
	static get __name__() {
		return "letzbig.ui.page.app.VideoPlayer"
	}
	static get __super__() {
		return View
	}
	get __class__() {
		return VideoPlayer
	}
}


VideoPlayer.__hoc = (Styles.withStyles(VideoPlayer.styles))(VideoPlayer)
export const AudioPlayer = Register.global("$hxClasses")["letzbig.ui.page.app.AudioPlayer"] = 
class AudioPlayer extends Register.inherits(View) {
	new(__coco_data_) {
		this.promise = null;
		this.audio = null;
		this.__coco_classes = new Slot(this);
		this.__coco_attachment = new Slot(this, null, null);
		this.__coco_duration = State_Impl_._new(null, null);
		this.__coco_currentTime = State_Impl_._new(null, null);
		this.__coco_callback = State_Impl_._new(null, null);
		let _gthis = this;
		this.__coco_status = new AutoObservable(Computation_Impl_.sync(function () {
			if (State_Impl_.get_value(_gthis.__coco_callback) != null) {
				return "playing";
			} else if (State_Impl_.get_value(_gthis.__coco_currentTime) != null) {
				return "paused";
			} else {
				return "stopped";
			};
		}), null, null);
		this.__initAttributes(__coco_data_);
		let snapshot = null;
		super.new(function () {
			return _gthis.__coco_render();
		}, null, null, null, function (firstTime) {
			if (firstTime) {
				_gthis.viewDidMount();
			};
		});
	}
	__coco_render() {
		let attributes = {"className": ClassName_Impl_.add(Observable_Impl_.get_value(this.__coco_classes).root, ClassName_Impl_.ofString(Observable_Impl_.get_value(this.__coco_status)))};
		let __r = [];
		let attributes1 = ClassName_Impl_.add(Observable_Impl_.get_value(this.__coco_classes).button, ClassName_Impl_.ofString("button " + Observable_Impl_.get_value(this.__coco_status)));
		let _g = new StringMap();
		let value = Observable_Impl_.get_value(this.__coco_status) != "playing";
		_g.inst.set("shadowed", value);
		let attributes2 = ClassName_Impl_.add(attributes1, ClassName_Impl_.ofMap(_g));
		let f = (Observable_Impl_.get_value(this.__coco_status) == "playing") ? Register.bind(this, this.pause) : Register.bind(this, this.play);
		let children = (Observable_Impl_.get_value(this.__coco_status) == "playing") ? React.createElement(ReactType_Impl_.fromComp(Pause), {}) : React.createElement(ReactType_Impl_.fromComp(PlayArrow), {});
		__r.push(Html.h("div", {"className": attributes2, "onClick": f}, [children]));
		let props = {"variant": "caption", "className": ClassName_Impl_.add(Observable_Impl_.get_value(this.__coco_classes).timer, ClassName_Impl_.ofString(Observable_Impl_.get_value(this.__coco_status)))};
		let children1 = [AudioPlayer.formatTime((Observable_Impl_.get_value(this.__coco_status) == "stopped") ? State_Impl_.get_value(this.__coco_duration) : State_Impl_.get_value(this.__coco_currentTime))];
		let tmp = [ReactType_Impl_.fromComp(Typography), props].concat(children1);
		__r.push(React.createElement.apply(React, tmp));
		return Html.h("div", attributes, __r);
	}
	viewDidMount() {
		let _gthis = this;
		this.audio = window.document.createElement("audio");
		let tmp = Observable_Impl_.get_value(Observable_Impl_.get_value(this.__coco_attachment).__coco_objectUrl);
		this.audio.src = tmp;
		this.audio.ondurationchange = function () {
			if (_gthis.audio.duration != Infinity) {
				let param = _gthis.audio.duration;
				_gthis.__coco_duration.set(param);
				_gthis.audio.pause();
				_gthis.audio.currentTime = 0;
				_gthis.audio.volume = 1;
			};
		};
		this.audio.currentTime = 86400;
		this.audio.load();
		this.audio.volume = 0;
		let _g = this.audio.play();
		if (_g != null) {
			let promise = _g;
			Future_Impl_.ofJsPromise(promise);
		};
	}
	play() {
		let _gthis = this;
		if (this.promise == null) {
			let f = function (resolve, reject) {
				_gthis.audio.play();
				let param = _gthis.audio.currentTime;
				_gthis.__coco_currentTime.set(param);
				_gthis.audio.ontimeupdate = function () {
					let param = _gthis.audio.currentTime;
					_gthis.__coco_currentTime.set(param);
					return param;
				};
				_gthis.audio.onended = function () {
					let param = null;
					_gthis.__coco_callback.set(param);
					let param1 = null;
					_gthis.__coco_currentTime.set(param1);
					resolve(null);
				};
				let this1 = new SimpleLink((o=>Register.bind(o, o.pause))(_gthis.audio));
				return this1;
			};
			let this1 = new SuspendableFuture(function (cb) {
				return f(function (v) {
					cb(Outcome.Success(v));
				}, function (e) {
					cb(Outcome.Failure(e));
				});
			});
			let this2 = this1;
			this.promise = this2;
			let param = this.promise.handle(Register.bind(this, this.end));
			this.__coco_callback.set(param);
		} else if (State_Impl_.get_value(this.__coco_callback) == null) {
			let param = this.promise.handle(Register.bind(this, this.end));
			this.__coco_callback.set(param);
		};
	}
	end(o) {
		console.log("letzbig/ui/page/app/FeedbackEditorPage.hx:629:",o);
		this.promise = null;
		let param = null;
		this.__coco_callback.set(param);
	}
	pause() {
		let this1 = State_Impl_.get_value(this.__coco_callback);
		if (this1 != null) {
			this1.cancel();
		};
		let param = null;
		this.__coco_callback.set(param);
	}
	__initAttributes(attributes) {
		this.__coco_attachment.setData(attributes.attachment);
		let value = attributes.classes;
		this.__coco_classes.setData(new ConstObservable(value, null));
	}
	static styles(theme) {
		let tmp = {"borderRadius": "inherit", "width": "100%", "height": "100%", "backgroundColor": theme.palette.secondary.light, "&.playing": {"backgroundColor": theme.palette.secondary.main}};
		let tmp1 = {"position": "absolute", "bottom": -1, "right": 3, "color": "#111111", "fontSize": theme.spacing(1.25), "&.playing": {"color": "white"}};
		let theme1 = theme.palette.secondary.main;
		let tmp2 = theme.spacing(3);
		return {"root": tmp, "timer": tmp1, "button": {"backgroundColor": theme1, "&.playing": {"backgroundColor": "transparent"}, "& > svg": {"color": "white", "fontSize": tmp2}}};
	}
	static formatTime(v) {
		let m = v / 60 | 0;
		let s = v - m * 60 | 0;
		return "" + m + ":" + StringTools.lpad("" + s, "0", 2);
	}
	static fromHxx(hxxMeta, attributes) {
		return {"$$typeof": View.TRE, "type": AudioPlayer.__hoc, "props": attributes, "key": hxxMeta.key, "ref": (!hxxMeta.ref) ? null : hxxMeta.ref};
	}
	static get __name__() {
		return "letzbig.ui.page.app.AudioPlayer"
	}
	static get __super__() {
		return View
	}
	get __class__() {
		return AudioPlayer
	}
}


AudioPlayer.__hoc = (Styles.withStyles(AudioPlayer.styles))(AudioPlayer)