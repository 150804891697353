import {Register} from "../../genes/Register"
import * as MuiThemeExtern from "@material-ui/core/styles"

export const Theme = Register.global("$hxClasses")["letzbig.ui.Theme"] = 
class Theme {
	static get __name__() {
		return "letzbig.ui.Theme"
	}
	get __class__() {
		return Theme
	}
}


Theme.theme = MuiThemeExtern.createMuiTheme({"palette": {"primary": {"main": "#FFF200", "dark": "#FFDE03", "light": "#FFF988"}, "secondary": {"main": "#3ADF97", "dark": "#1FC47D", "light": "#CAFFE8", "contrastText": "white"}}, "typography": {"fontFamily": "AvenirNext, Lato, \"Microsoft JhengHei\", \"Microsoft YaHei\", \"Microsoft JhengHei\", Meiryo", "h1": {"fontWeight": 900, "fontSize": 30}, "h6": {"fontWeight": 600, "fontSize": 18}}})