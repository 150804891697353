import {AutoObservable, Computation_Impl_} from "../../tink/state/internal/AutoObservable"
import {Observable_Impl_} from "../../tink/state/Observable"
import {ReactType_Impl_} from "../../react/ReactType"
import * as React from "react"
import {Theme} from "./Theme"
import {Register} from "../../genes/Register"
import {Slot} from "../../coconut/ui/internal/Slot"
import {View} from "../../coconut/react/View"
import {MuiThemeProvider} from "@material-ui/core/styles"

export const ThemeProvider = Register.global("$hxClasses")["letzbig.ui.ThemeProvider"] = 
class ThemeProvider extends Register.inherits(View) {
	new(__coco_data_) {
		let _gthis = this;
		this.__coco_theme = new Slot(this, null, new AutoObservable(Computation_Impl_.sync(function () {
			return Theme.theme;
		}), null, null));
		this.__coco_children = new Slot(this, null, null);
		this.__initAttributes(__coco_data_);
		let snapshot = null;
		super.new(function () {
			return _gthis.__coco_render();
		}, null, null, null, null);
	}
	__coco_render() {
		let props = {"theme": Observable_Impl_.get_value(this.__coco_theme)};
		let __r = [];
		let _g = 0;
		let _g1 = Observable_Impl_.get_value(this.__coco_children);
		while (_g < ((_g1 == null) ? 0 : _g1.length)) {
			let _0 = (_g1 == null) ? null : _g1[_g];
			++_g;
			__r.push(_0);
		};
		let children = [View.createFragment({}, __r)];
		let tmp = [ReactType_Impl_.fromComp(MuiThemeProvider), props].concat(children);
		return React.createElement.apply(React, tmp);
	}
	__initAttributes(attributes) {
		this.__coco_theme.setData(attributes.theme);
		this.__coco_children.setData(attributes.children);
	}
	static fromHxx(hxxMeta, attributes) {
		return {"$$typeof": View.TRE, "type": ThemeProvider, "props": attributes, "key": hxxMeta.key, "ref": (!hxxMeta.ref) ? null : hxxMeta.ref};
	}
	static get __name__() {
		return "letzbig.ui.ThemeProvider"
	}
	static get __super__() {
		return View
	}
	get __class__() {
		return ThemeProvider
	}
}

