import {Register} from "../../genes/Register"

export const Representation_Impl_ = Register.global("$hxClasses")["tink.json._Representation.Representation_Impl_"] = 
class Representation_Impl_ {
	static get(this1) {
		return this1;
	}
	static get __name__() {
		return "tink.json._Representation.Representation_Impl_"
	}
	get __class__() {
		return Representation_Impl_
	}
}

