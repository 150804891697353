import {BasicParser, JsonString_Impl_} from "./Parser"
import {TypedError} from "../core/Error"
import {AuthConfig} from "../../letzbig/api/external/Configs"
import {Exception} from "../../haxe/Exception"
import {Register} from "../../genes/Register"
import {Std} from "../../Std"

export const Parser14 = Register.global("$hxClasses")["tink.json.Parser14"] = 
class Parser14 extends Register.inherits(BasicParser) {
	new() {
		super.new();
	}
	process0() {
		let __ret = this.process1();
		let o = __ret.Cognito;
		if (o != null) {
			return AuthConfig.Cognito(o);
		} else {
			throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "tink/json/macros/GenReader.hx", "lineNumber": 366, "className": "tink.json.Parser14", "methodName": "process0"}));
		};
	}
	process1() {
		let _gthis = this;
		let v_Cognito = null;
		let __start__ = this.pos;
		while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
		let tmp;
		if (this.max > this.pos && this.source.charCodeAt(this.pos) == 123) {
			this.pos += 1;
			while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
			tmp = true;
		} else {
			tmp = false;
		};
		if (!tmp) {
			this.die("Expected " + "{");
		};
		while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
		let tmp1;
		if (this.max > this.pos && this.source.charCodeAt(this.pos) == 125) {
			this.pos += 1;
			while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
			tmp1 = true;
		} else {
			tmp1 = false;
		};
		if (!tmp1) {
			while (true) {
				let __name__ = this.parseString();
				while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
				let tmp;
				if (this.max > this.pos && this.source.charCodeAt(this.pos) == 58) {
					this.pos += 1;
					while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
					tmp = true;
				} else {
					tmp = false;
				};
				if (!tmp) {
					this.die("Expected " + ":");
				};
				if ("Cognito".length == __name__.max - __name__.min && __name__.source.substring(__name__.min, __name__.max) == "Cognito") {
					while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
					let v_Cognito1;
					if (this.max > this.pos + 3 && this.source.charCodeAt(this.pos) == 110 && this.source.charCodeAt(this.pos + 1) == 117 && this.source.charCodeAt(this.pos + 2) == 108 && this.source.charCodeAt(this.pos + 3) == 108) {
						this.pos += 4;
						while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
						v_Cognito1 = true;
					} else {
						v_Cognito1 = false;
					};
					v_Cognito = (v_Cognito1) ? null : this.process2();
				} else {
					this.skipValue();
				};
				while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
				let tmp1;
				if (this.max > this.pos && this.source.charCodeAt(this.pos) == 44) {
					this.pos += 1;
					while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
					tmp1 = true;
				} else {
					tmp1 = false;
				};
				if (!tmp1) {
					break;
				};
			};
			while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
			let tmp;
			if (this.max > this.pos && this.source.charCodeAt(this.pos) == 125) {
				this.pos += 1;
				while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
				tmp = true;
			} else {
				tmp = false;
			};
			if (!tmp) {
				this.die("Expected " + "}");
			};
		};
		let __missing__ = function (field) {
			return _gthis.die("missing field \"" + field + "\"", __start__);
		};
		return {"Cognito": v_Cognito};
	}
	process2() {
		let _gthis = this;
		let v_appClientId = null;
		let hasv_appClientId = false;
		let v_region = null;
		let hasv_region = false;
		let v_userPoolId = null;
		let hasv_userPoolId = false;
		let __start__ = this.pos;
		while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
		let tmp;
		if (this.max > this.pos && this.source.charCodeAt(this.pos) == 123) {
			this.pos += 1;
			while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
			tmp = true;
		} else {
			tmp = false;
		};
		if (!tmp) {
			this.die("Expected " + "{");
		};
		while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
		let tmp1;
		if (this.max > this.pos && this.source.charCodeAt(this.pos) == 125) {
			this.pos += 1;
			while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
			tmp1 = true;
		} else {
			tmp1 = false;
		};
		if (!tmp1) {
			while (true) {
				let __name__ = this.parseString();
				while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
				let tmp;
				if (this.max > this.pos && this.source.charCodeAt(this.pos) == 58) {
					this.pos += 1;
					while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
					tmp = true;
				} else {
					tmp = false;
				};
				if (!tmp) {
					this.die("Expected " + ":");
				};
				if ("userPoolId".length == __name__.max - __name__.min && __name__.source.substring(__name__.min, __name__.max) == "userPoolId") {
					v_userPoolId = JsonString_Impl_.toString(this.parseString());
					hasv_userPoolId = true;
				} else if ("region".length == __name__.max - __name__.min && __name__.source.substring(__name__.min, __name__.max) == "region") {
					v_region = JsonString_Impl_.toString(this.parseString());
					hasv_region = true;
				} else if ("appClientId".length == __name__.max - __name__.min && __name__.source.substring(__name__.min, __name__.max) == "appClientId") {
					v_appClientId = JsonString_Impl_.toString(this.parseString());
					hasv_appClientId = true;
				} else {
					this.skipValue();
				};
				while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
				let tmp1;
				if (this.max > this.pos && this.source.charCodeAt(this.pos) == 44) {
					this.pos += 1;
					while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
					tmp1 = true;
				} else {
					tmp1 = false;
				};
				if (!tmp1) {
					break;
				};
			};
			while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
			let tmp;
			if (this.max > this.pos && this.source.charCodeAt(this.pos) == 125) {
				this.pos += 1;
				while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
				tmp = true;
			} else {
				tmp = false;
			};
			if (!tmp) {
				this.die("Expected " + "}");
			};
		};
		let __missing__ = function (field) {
			return _gthis.die("missing field \"" + field + "\"", __start__);
		};
		return {"appClientId": (hasv_appClientId) ? v_appClientId : __missing__("appClientId"), "region": (hasv_region) ? v_region : __missing__("region"), "userPoolId": (hasv_userPoolId) ? v_userPoolId : __missing__("userPoolId")};
	}
	parse(source) {
		let _gthis = this;
		if (_gthis.afterParsing.length > 0) {
			_gthis.afterParsing = [];
		};
		this.init(source);
		let ret = this.process0();
		let _g = 0;
		let _g1 = this.afterParsing;
		while (_g < _g1.length) {
			let f = _g1[_g];
			++_g;
			f();
		};
		if (_gthis.afterParsing.length > 0) {
			_gthis.afterParsing = [];
		};
		return ret;
	}
	tryParse(source) {
		let _gthis = this;
		return TypedError.catchExceptions(function () {
			let ret = _gthis.parse(source);
			while (_gthis.pos < _gthis.max && _gthis.source.charCodeAt(_gthis.pos) < 33) _gthis.pos++;
			if (_gthis.pos < _gthis.max) {
				_gthis.die("Invalid data after JSON document");
			};
			return ret;
		}, null, {"fileName": "tink/json/macros/Macro.hx", "lineNumber": 107, "className": "tink.json.Parser14", "methodName": "tryParse"});
	}
	static get __name__() {
		return "tink.json.Parser14"
	}
	static get __super__() {
		return BasicParser
	}
	get __class__() {
		return Parser14
	}
}

