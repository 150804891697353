import {Observable_Impl_} from "../../../../../tink/state/Observable"
import {OutgoingRequest} from "../../../../../tink/http/Request"
import {HeaderField} from "../../../../../tink/http/Header"
import {Promise_Impl_} from "../../../../../tink/core/Promise"
import {Outcome} from "../../../../../tink/core/Outcome"
import {LazyConst} from "../../../../../tink/core/Lazy"
import {SyncFuture} from "../../../../../tink/core/Future"
import {TypedError} from "../../../../../tink/core/Error"
import {Register} from "../../../../../genes/Register"

export const DelegateProcessors_Impl_ = Register.global("$hxClasses")["why.auth.plugins.tink.http._DelegateProcessors.DelegateProcessors_Impl_"] = 
class DelegateProcessors_Impl_ {
	static _new(delegate, scheme = "Bearer") {
		let this1 = {"before": [function (req) {
			let _g = Observable_Impl_.get_value(delegate.status);
			switch (_g._hx_index) {
				case 0:
					return new SyncFuture(new LazyConst(Outcome.Failure(new TypedError(null, "Delegate still initializing", {"fileName": "why/auth/plugins/tink/http/DelegateProcessors.hx", "lineNumber": 25, "className": "why.auth.plugins.tink.http._DelegateProcessors.DelegateProcessors_Impl_", "methodName": "_new"}))));
					break
				case 1:
					return new SyncFuture(new LazyConst(Outcome.Success(req)));
					break
				case 2:
					let user = _g.profile;
					return Promise_Impl_.next(user.getToken(), function (token) {
						return new SyncFuture(new LazyConst(Outcome.Success(new OutgoingRequest(req.header.concat([new HeaderField("authorization", "" + scheme + " " + token)]), req.body))));
					});
					break
				case 3:
					let e = _g.e;
					return new SyncFuture(new LazyConst(Outcome.Failure(e)));
					break
				
			};
		}]};
		return this1;
	}
	static get __name__() {
		return "why.auth.plugins.tink.http._DelegateProcessors.DelegateProcessors_Impl_"
	}
	get __class__() {
		return DelegateProcessors_Impl_
	}
}

