import {LazyConst, Lazy_Impl_} from "../core/Lazy"
import {SyncFuture} from "../core/Future"
import {Register} from "../../genes/Register"
import {Std} from "../../Std"

export const WorkerObject = {}

export const EagerWorker = Register.global("$hxClasses")["tink.io._Worker.EagerWorker"] = 
class EagerWorker extends Register.inherits() {
	new() {
	}
	work(task) {
		return new SyncFuture(new LazyConst(Lazy_Impl_.get(task)));
	}
	static get __name__() {
		return "tink.io._Worker.EagerWorker"
	}
	static get __interfaces__() {
		return [WorkerObject]
	}
	get __class__() {
		return EagerWorker
	}
}


export const Worker_Impl_ = Register.global("$hxClasses")["tink.io._Worker.Worker_Impl_"] = 
class Worker_Impl_ {
	static ensure(this1) {
		if (this1 == null) {
			return Worker_Impl_.get();
		} else {
			return this1;
		};
	}
	static get() {
		return Worker_Impl_.pool[Std.random(Worker_Impl_.pool.length)];
	}
	static work(this1, task) {
		return this1.work(task);
	}
	static get __name__() {
		return "tink.io._Worker.Worker_Impl_"
	}
	get __class__() {
		return Worker_Impl_
	}
}


Worker_Impl_.EAGER = new EagerWorker()
Worker_Impl_.pool = [Worker_Impl_.EAGER]