import {Revision_Impl_} from "./Revision"
import {ObservableObject} from "./ObservableObject"
import {Invalidatable, Invalidator} from "./Invalidatable"
import {PromisedWith} from "../Promised"
import {Observable_Impl_} from "../Observable"
import {Exception} from "../../../haxe/Exception"
import {Register} from "../../../genes/Register"

export const Derived = {}

export const AutoObservable = Register.global("$hxClasses")["tink.state.internal.AutoObservable"] = 
class AutoObservable extends Register.inherits(Invalidator) {
	new(compute, comparator = null, toString = null) {
		this.sync = true;
		this.dependencies = new Map();
		this.last = null;
		this.status = 0;
		this.hot = false;
		let _gthis = this;
		super.new(toString);
		this.compute = compute;
		this.comparator = comparator;
		this.list.onfill = function () {
			_gthis.getValue();
			_gthis.getRevision();
			if (_gthis.subscriptions != null) {
				let _g = 0;
				let _g1 = _gthis.subscriptions;
				while (_g < _g1.length) {
					let s = _g1[_g];
					++_g;
					s.link = s.source.onInvalidate(s.owner);
				};
			};
			_gthis.hot = true;
		};
		this.list.ondrain = function () {
			_gthis.hot = false;
			if (_gthis.subscriptions != null) {
				let _g = 0;
				let _g1 = _gthis.subscriptions;
				while (_g < _g1.length) {
					let s = _g1[_g];
					++_g;
					let this1 = s.link;
					if (this1 != null) {
						this1.cancel();
					};
				};
			};
		};
	}
	getRevision() {
		if (this.hot) {
			return this.revision;
		};
		if (this.subscriptions == null) {
			this.getValue();
		};
		let _g = 0;
		let _g1 = this.subscriptions;
		while (_g < _g1.length) {
			let s = _g1[_g];
			++_g;
			if (s.source.getRevision() > this.revision) {
				return this.revision = Revision_Impl_._new();
			};
		};
		return this.revision;
	}
	subsValid() {
		if (this.subscriptions == null) {
			return false;
		};
		let _g = 0;
		let _g1 = this.subscriptions;
		while (_g < _g1.length) {
			let s = _g1[_g];
			++_g;
			if (s.source.getRevision() != s.lastRev) {
				return false;
			};
		};
		return true;
	}
	isValid() {
		if (this.status != 0) {
			if (!this.hot) {
				return this.subsValid();
			} else {
				return true;
			};
		} else {
			return false;
		};
	}
	getComparator() {
		return this.comparator;
	}
	getValue() {
		let _gthis = this;
		let doCompute = function () {
			_gthis.status = 1;
			if (_gthis.subscriptions != null) {
				let _g = 0;
				let _g1 = _gthis.subscriptions;
				while (_g < _g1.length) {
					let s = _g1[_g];
					++_g;
					s.used = false;
				};
			};
			_gthis.subscriptions = [];
			_gthis.sync = true;
			let before = AutoObservable.cur;
			AutoObservable.cur = _gthis;
			let ret = _gthis.compute(function (v) {
				_gthis.update(v);
			});
			AutoObservable.cur = before;
			_gthis.last = ret;
			_gthis.sync = false;
		};
		let prevSubs = this.subscriptions;
		let count = 0;
		while (!this.isValid()) if (++count == Observable_Impl_.MAX_ITERATIONS) {
			throw Exception.thrown("no result after " + Observable_Impl_.MAX_ITERATIONS + " attempts");
		} else if (this.subscriptions != null) {
			let valid = true;
			let _g = 0;
			let _g1 = this.subscriptions;
			while (_g < _g1.length) {
				let s = _g1[_g];
				++_g;
				let nextRev = s.source.getRevision();
				let tmp;
				if (nextRev == s.lastRev) {
					tmp = false;
				} else {
					s.lastRev = nextRev;
					let before = s.last;
					let before1 = AutoObservable.cur;
					AutoObservable.cur = null;
					let ret = s.source.getValue();
					AutoObservable.cur = before1;
					s.last = ret;
					let this1 = s.source.getComparator();
					let a = s.last;
					let tmp1;
					if (this1 == null) {
						tmp1 = a == before;
					} else {
						let f = this1;
						tmp1 = f(a, before);
					};
					tmp = !tmp1;
				};
				if (tmp) {
					valid = false;
					break;
				};
			};
			if (valid) {
				this.status = 1;
			} else {
				doCompute();
				if (prevSubs != null) {
					let _g = 0;
					while (_g < prevSubs.length) {
						let s = prevSubs[_g];
						++_g;
						if (!s.used) {
							if (this.hot) {
								let this1 = s.link;
								if (this1 != null) {
									this1.cancel();
								};
							};
							this.dependencies["delete"](s.source);
						};
					};
				};
			};
		} else {
			doCompute();
		};
		return this.last;
	}
	update(value) {
		if (!this.sync) {
			this.last = value;
			this.fire();
		};
	}
	subscribeTo(source, cur) {
		let _g = this.dependencies.get(source);
		if (_g == null) {
			let sub = new SubscriptionTo(source, cur, this);
			this.dependencies.set(source, sub);
			this.subscriptions.push(sub);
		} else {
			let v = _g;
			if (!v.used) {
				v.used = true;
				v.last = cur;
				this.subscriptions.push(v);
			};
		};
	}
	invalidate() {
		if (this.status == 1) {
			this.status = 0;
			this.fire();
		};
	}
	static get __name__() {
		return "tink.state.internal.AutoObservable"
	}
	static get __interfaces__() {
		return [ObservableObject, Derived, Invalidatable]
	}
	static get __super__() {
		return Invalidator
	}
	get __class__() {
		return AutoObservable
	}
}


export const Computation_Impl_ = Register.global("$hxClasses")["tink.state.internal._AutoObservable.Computation_Impl_"] = 
class Computation_Impl_ {
	static async(f) {
		let link = null;
		let ret = PromisedWith.Loading;
		let this1 = function (update, _ = null) {
			ret = PromisedWith.Loading;
			if (link != null) {
				link.cancel();
			};
			link = f().handle(function (o) {
				let update1 = update;
				switch (o._hx_index) {
					case 0:
						let v = o.data;
						ret = PromisedWith.Done(v);
						break
					case 1:
						let e = o.failure;
						ret = PromisedWith.Failed(e);
						break
					
				};
				update1(ret);
			});
			return ret;
		};
		return this1;
	}
	static sync(f) {
		let this1 = function (_, _1 = null) {
			return f();
		};
		return this1;
	}
	static get __name__() {
		return "tink.state.internal._AutoObservable.Computation_Impl_"
	}
	get __class__() {
		return Computation_Impl_
	}
}


export const SubscriptionTo = Register.global("$hxClasses")["tink.state.internal._AutoObservable.SubscriptionTo"] = 
class SubscriptionTo extends Register.inherits() {
	new(source, cur, owner) {
		this.used = true;
		this.source = source;
		this.last = cur;
		this.lastRev = source.getRevision();
		this.owner = owner;
		if (owner.hot) {
			this.link = this.source.onInvalidate(this.owner);
		};
	}
	static get __name__() {
		return "tink.state.internal._AutoObservable.SubscriptionTo"
	}
	get __class__() {
		return SubscriptionTo
	}
}

