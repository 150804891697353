import {MaterialUiSnackbar} from "../../why/toast/MaterialUiSnackbar"
import {Toast_Impl_} from "../../why/Toast"
import {AutoObservable, Computation_Impl_} from "../../tink/state/internal/AutoObservable"
import {Observable_Impl_} from "../../tink/state/Observable"
import {JsClient} from "../../tink/http/clients/JsClient"
import {Url_Impl_} from "../../tink/Url"
import {ReactType_Impl_} from "../../react/ReactType"
import * as React from "react"
import {AppContainer} from "../ui/component/app/AppContainer"
import {ThemeProvider} from "../ui/ThemeProvider"
import {RouteTools} from "./data/Route"
import {ProductModel} from "./data/ProductModel"
import {AuthModel} from "./data/AuthModel"
import {AppModel} from "./data/AppModel"
import {CreateFeedbackController} from "./controller/CreateFeedbackController"
import {PublicApi} from "../PublicApi"
import {Register} from "../../genes/Register"
import {Slot} from "../../coconut/ui/internal/Slot"
import {Router} from "../../coconut/router/Browser"
import {View} from "../../coconut/react/View"
import {Renderer} from "../../coconut/react/Renderer"

export const Web = Register.global("$hxClasses")["letzbig.app.Web"] = 
class Web {
	static main() {
		let container = window.document.getElementById("app");
		while (container.childElementCount > 0) container.removeChild(container.firstChild);
		let api = new PublicApi({"client": new JsClient(), "scheme": "https", "host": "api2.letzbig.com", "port": 443, "path": "public"});
		let delegate = api.auth;
		let model = new AppModel({"auth": new AuthModel({"delegate": delegate, "status": delegate.status}), "remote": api.remote});
		let tmp = new AutoObservable(Computation_Impl_.sync(function () {
			let compute = new AutoObservable(Computation_Impl_.sync(function () {
				let compute = new AutoObservable(Computation_Impl_.sync(function () {
					return model;
				}), null, null);
				return [App.fromHxx({}, {"app": compute})];
			}), null, null);
			return [ThemeProvider.fromHxx({}, {"children": compute})];
		}), null, null);
		Renderer.mountInto(container, AppContainer.fromHxx({}, {"children": tmp}));
	}
	static get __name__() {
		return "letzbig.app.Web"
	}
	get __class__() {
		return Web
	}
}


export const App = Register.global("$hxClasses")["letzbig.app.App"] = 
class App extends Register.inherits(View) {
	new(__coco_data_) {
		this.__coco_app = new Slot(this, null, null);
		let _gthis = this;
		this.__initAttributes(__coco_data_);
		let snapshot = null;
		super.new(function () {
			return _gthis.__coco_render();
		}, null, null, null, null);
	}
	__coco_render() {
		let _gthis = this;
		let __r = [];
		let tmp = new AutoObservable(Computation_Impl_.sync(function () {
			return Register.bind(_gthis, _gthis.renderSignedIn);
		}), null, null);
		let tmp1 = new AutoObservable(Computation_Impl_.sync(function () {
			return RouteTools.fromUrl;
		}), null, null);
		let tmp2 = new AutoObservable(Computation_Impl_.sync(function () {
			return RouteTools.toUrl;
		}), null, null);
		__r.push(Router.fromHxx({}, {"renderScreen": tmp, "urlToRoute": tmp1, "routeToUrl": tmp2}));
		__r.push(React.createElement(ReactType_Impl_.fromComp(MaterialUiSnackbar), {"ref": function (v) {
			Toast_Impl_.instance = v;
		}, "renderChildren": function (opt) {
			return opt.title;
		}}));
		return View.createFragment({}, __r);
	}
	renderSignedIn(route) {
		let _gthis = this;
		let __r = [];
		switch (route._hx_index) {
			case 0:
				__r.push("Home");
				break
			case 1:
				__r.push("Home");
				break
			case 2:
				let _g = route.sub;
				let id = route.id;
				let tmp = new AutoObservable(Computation_Impl_.sync(function () {
					return Observable_Impl_.get_value(_gthis.__coco_app).auth;
				}), null, null);
				let tmp1 = new AutoObservable(Computation_Impl_.sync(function () {
					return new ProductModel({"id": id, "remote": Observable_Impl_.get_value(_gthis.__coco_app).remote});
				}), null, null);
				__r.push(CreateFeedbackController.fromHxx({}, {"auth": tmp, "product": tmp1}));
				break
			case 3:
				let url = route.url;
				__r.push("Not Found: ");
				__r.push(Url_Impl_.toString(url));
				break
			
		};
		return View.createFragment({}, __r);
	}
	__initAttributes(attributes) {
		this.__coco_app.setData(attributes.app);
	}
	static fromHxx(hxxMeta, attributes) {
		return {"$$typeof": View.TRE, "type": App, "props": attributes, "key": hxxMeta.key, "ref": (!hxxMeta.ref) ? null : hxxMeta.ref};
	}
	static get __name__() {
		return "letzbig.app.App"
	}
	static get __super__() {
		return View
	}
	get __class__() {
		return App
	}
}

