import {Bytes} from "../io/Bytes"
import {Exception} from "../Exception"
import {Register} from "../../genes/Register"

/**
Allows one to encode/decode String and bytes using a power of two base dictionary.
*/
export const BaseCode = Register.global("$hxClasses")["haxe.crypto.BaseCode"] = 
class BaseCode extends Register.inherits() {
	new(base) {
		let len = base.length;
		let nbits = 1;
		while (len > 1 << nbits) ++nbits;
		if (nbits > 8 || len != 1 << nbits) {
			throw Exception.thrown("BaseCode : base length must be a power of two.");
		};
		this.base = base;
		this.nbits = nbits;
	}
	encodeBytes(b) {
		let nbits = this.nbits;
		let base = this.base;
		let size = b.length * 8 / nbits | 0;
		let out = new Bytes(new ArrayBuffer(size + ((b.length * 8 % nbits == 0) ? 0 : 1)));
		let buf = 0;
		let curbits = 0;
		let mask = (1 << nbits) - 1;
		let pin = 0;
		let pout = 0;
		while (pout < size) {
			while (curbits < nbits) {
				curbits += 8;
				buf <<= 8;
				buf |= b.b[pin++];
			};
			curbits -= nbits;
			out.b[pout++] = base.b[buf >> curbits & mask];
		};
		if (curbits > 0) {
			out.b[pout++] = base.b[buf << nbits - curbits & mask];
		};
		return out;
	}
	static get __name__() {
		return "haxe.crypto.BaseCode"
	}
	get __class__() {
		return BaseCode
	}
}

