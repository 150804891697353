import {Generator, Step} from "../../streams/Stream"
import {Future_Impl_} from "../../core/Future"
import {TypedError} from "../../core/Error"
import {ByteChunk} from "../../chunk/ByteChunk"
import {Bytes} from "../../../haxe/io/Bytes"
import {Register} from "../../../genes/Register"

export const BlobSource = Register.global("$hxClasses")["tink.io.js.BlobSource"] = 
class BlobSource extends Register.inherits(Generator) {
	new(name, blob, pos, chunkSize) {
		this.name = name;
		super.new(Future_Impl_.async(function (cb) {
			if (pos >= blob.size) {
				cb(Step.End);
			} else {
				let end = pos + chunkSize;
				if (end > blob.size) {
					end = blob.size;
				};
				let reader = new FileReader();
				reader.onload = function () {
					let chunk = ByteChunk.of(Bytes.ofData(reader.result));
					cb(Step.Link(chunk, new BlobSource(name, blob, end, chunkSize)));
				};
				reader.onerror = function (e) {
					cb(Step.Fail(TypedError.withData(500, e.message, e, {"fileName": "tink/io/js/BlobSource.hx", "lineNumber": 29, "className": "tink.io.js.BlobSource", "methodName": "new"})));
				};
				reader.readAsArrayBuffer(blob.slice(pos, end));
			};
		}, true));
	}
	static get __name__() {
		return "tink.io.js.BlobSource"
	}
	static get __super__() {
		return Generator
	}
	get __class__() {
		return BlobSource
	}
}

