import {Outcome} from "./Outcome"
import {Exception} from "../../haxe/Exception"
import {Register} from "../../genes/Register"

export const TypedError = Register.global("$hxClasses")["tink.core.TypedError"] = 
class TypedError extends Register.inherits() {
	new(code = 500, message, pos = null) {
		this.isTinkError = true;
		this.code = code;
		this.message = message;
		this.pos = pos;
		this.exceptionStack = [];
		this.callStack = [];
	}
	printPos() {
		return this.pos.className + "." + this.pos.methodName + ":" + this.pos.lineNumber;
	}
	toString() {
		let ret = "Error#" + this.code + ": " + this.message;
		if (this.pos != null) {
			ret += " @ " + this.printPos();
		};
		return ret;
	}
	throwSelf() {
		let any = this;
		throw Exception.thrown(any);
	}
	static withData(code = null, message, data, pos = null) {
		return TypedError.typed(code, message, data, pos);
	}
	static typed(code = null, message, data, pos = null) {
		let ret = new TypedError(code, message, pos);
		ret.data = data;
		return ret;
	}
	static asError(v) {
		if (v != null && v.isTinkError) {
			return v;
		} else {
			return null;
		};
	}
	static catchExceptions(f, report = null, pos = null) {
		try {
			return Outcome.Success(f());
		}catch (_g) {
			let e = Exception.caught(_g).unwrap();
			let e1 = TypedError.asError(e);
			let tmp;
			if (e1 == null) {
				tmp = (report == null) ? TypedError.withData(null, "Unexpected Error", e1, pos) : report(e1);
			} else {
				let e = e1;
				tmp = e;
			};
			return Outcome.Failure(tmp);
		};
	}
	static tryFinally(f, cleanup) {
		try { return f(); } finally { cleanup(); };
		return null;
	}
	static get __name__() {
		return "tink.core.TypedError"
	}
	get __class__() {
		return TypedError
	}
}

