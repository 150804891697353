import {ConstObservable, Observable_Impl_} from "../../../tink/state/Observable"
import {ReactType_Impl_} from "../../../react/ReactType"
import * as React from "react"
import {Register} from "../../../genes/Register"
import {Slot} from "../../../coconut/ui/internal/Slot"
import {View} from "../../../coconut/react/View"
import {Image} from "@material-ui/icons"
import * as Styles from "@material-ui/core/styles"
import {Avatar, Badge} from "@material-ui/core"

export const Thumbnail = Register.global("$hxClasses")["letzbig.ui.component.Thumbnail"] = 
class Thumbnail extends Register.inherits(View) {
	new(__coco_data_) {
		this.__coco_classes = new Slot(this);
		this.__coco_className = new Slot(this, null, null);
		let _gthis = this;
		this.__coco_color = new Slot(this, null, new ConstObservable("#FFF200", null));
		this.__coco_src = new Slot(this, null, null);
		this.__coco_badge = new Slot(this, null, new ConstObservable(0, null));
		this.__initAttributes(__coco_data_);
		let snapshot = null;
		super.new(function () {
			return _gthis.__coco_render();
		}, null, null, null, null);
	}
	__coco_render() {
		let props = {"color": "secondary", "badgeContent": Observable_Impl_.get_value(this.__coco_badge)};
		let props1 = {"variant": "rounded", "className": Observable_Impl_.get_value(this.__coco_className), "style": {"backgroundColor": Observable_Impl_.get_value(this.__coco_color)}, "src": Observable_Impl_.get_value(this.__coco_src)};
		let children = [React.createElement(ReactType_Impl_.fromComp(Image), {"className": "MuiAvatar-fallback"})];
		let children1 = [ReactType_Impl_.fromComp(Avatar), props1].concat(children);
		let children2 = [React.createElement.apply(React, children1)];
		let tmp = [ReactType_Impl_.fromComp(Badge), props].concat(children2);
		return React.createElement.apply(React, tmp);
	}
	__initAttributes(attributes) {
		this.__coco_className.setData(attributes.className);
		this.__coco_color.setData(attributes.color);
		this.__coco_src.setData(attributes.src);
		this.__coco_badge.setData(attributes.badge);
		let value = attributes.classes;
		this.__coco_classes.setData(new ConstObservable(value, null));
	}
	static styles(theme) {
		return {"root": {}};
	}
	static fromHxx(hxxMeta, attributes) {
		return {"$$typeof": View.TRE, "type": Thumbnail.__hoc, "props": attributes, "key": hxxMeta.key, "ref": (!hxxMeta.ref) ? null : hxxMeta.ref};
	}
	static get __name__() {
		return "letzbig.ui.component.Thumbnail"
	}
	static get __super__() {
		return View
	}
	get __class__() {
		return Thumbnail
	}
}


Thumbnail.__hoc = (Styles.withStyles(Thumbnail.styles))(Thumbnail)