import {Remote20} from "./Remote20"
import {Remote19} from "./Remote19"
import {RemoteBase, RemoteEndpoint_Impl_} from "./Remote"
import {Register} from "../../../genes/Register"

export const Remote18 = Register.global("$hxClasses")["tink.web.proxy.Remote18"] = 
class Remote18 extends Register.inherits(RemoteBase) {
	new(client, endpoint) {
		super.new(client, endpoint);
	}
	users() {
		let this1 = "users";
		return new Remote19(this.client, RemoteEndpoint_Impl_.sub(this.endpoint, {"path": [this1], "query": [], "headers": [].concat([])}));
	}
	me() {
		let this1 = "me";
		return new Remote20(this.client, RemoteEndpoint_Impl_.sub(this.endpoint, {"path": [this1], "query": [], "headers": [].concat([])}));
	}
	static get __name__() {
		return "tink.web.proxy.Remote18"
	}
	static get __super__() {
		return RemoteBase
	}
	get __class__() {
		return Remote18
	}
}

