import {Register} from "../../genes/Register"

export const ProductType = 
Register.global("$hxEnums")["letzbig.types.ProductType"] = 
{
	__ename__: "letzbig.types.ProductType",
	
	Product: Object.assign((id) => ({_hx_index: 0, __enum__: "letzbig.types.ProductType", id}), {_hx_name: "Product", __params__: ["id"]}),
	Custom: Object.assign((id) => ({_hx_index: 1, __enum__: "letzbig.types.ProductType", id}), {_hx_name: "Custom", __params__: ["id"]})
}
ProductType.__constructs__ = ["Product", "Custom"]
ProductType.__empty_constructs__ = []
