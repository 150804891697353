import {Single} from "../../streams/Stream"
import {Worker_Impl_} from "../../io/Worker"
import {IdealSourceTools} from "../../io/Source"
import {ResponseHeaderBase, IncomingResponse} from "../Response"
import {ClientObject} from "../Client"
import {Outcome} from "../../core/Outcome"
import {LazyConst, LazyFunc} from "../../core/Lazy"
import {Future_Impl_} from "../../core/Future"
import {TypedError} from "../../core/Error"
import {ByteChunk} from "../../chunk/ByteChunk"
import {Url_Impl_} from "../../Url"
import {Bytes} from "../../../haxe/io/Bytes"
import {HttpJs} from "../../../haxe/http/HttpJs"
import {Register} from "../../../genes/Register"

export const StdClient = Register.global("$hxClasses")["tink.http.clients.StdClient"] = 
class StdClient extends Register.inherits() {
	new(worker = null) {
		this.worker = Worker_Impl_.ensure(worker);
	}
	request(req, handlers = null) {
		let _gthis = this;
		return Future_Impl_.async(function (cb) {
			let r = new HttpJs(Url_Impl_.toString(req.header.url));
			let send = function (post) {
				let code = 200;
				r.onStatus = function (c) {
					code = c;
				};
				let headers = function () {
					return [];
				};
				r.onError = function (msg) {
					if (code == 200) {
						code = 500;
					};
					Worker_Impl_.work(_gthis.worker, new LazyConst(true)).handle(function () {
						cb(Outcome.Failure(new TypedError(code, msg, {"fileName": "tink/http/clients/StdClient.hx", "lineNumber": 44, "className": "tink.http.clients.StdClient", "methodName": "request"})));
					});
				};
				r.onData = function (data) {
					Worker_Impl_.work(_gthis.worker, new LazyConst(true)).handle(function () {
						let cb1 = cb;
						let fields = headers();
						let this1 = new ResponseHeaderBase(code, "OK", fields, "HTTP/1.1");
						cb1(Outcome.Success(new IncomingResponse(this1, new Single(new LazyConst(ByteChunk.of(Bytes.ofString(data)))))));
					});
				};
				Worker_Impl_.work(_gthis.worker, new LazyFunc(function () {
					r.request(post);
				}));
			};
			let _g_current = 0;
			let _g_array = req.header.fields;
			while (_g_current < _g_array.length) {
				let h = _g_array[_g_current++];
				r.setHeader(h.name, h.value);
			};
			switch (req.header.method) {
				case "GET":case "HEAD":case "OPTIONS":
					send(false);
					break
				default:
				IdealSourceTools.all(req.body).handle(function (bytes) {
					r.setPostData(bytes.toString());
					send(true);
				});
				
			};
		});
	}
	static get __name__() {
		return "tink.http.clients.StdClient"
	}
	static get __interfaces__() {
		return [ClientObject]
	}
	get __class__() {
		return StdClient
	}
}

