import {Register} from "../../genes/Register"

export const Portion_Impl_ = Register.global("$hxClasses")["tink.url._Portion.Portion_Impl_"] = 
class Portion_Impl_ {
	static toString(this1) {
		if (this1 == null) {
			return null;
		} else {
			try {
				return decodeURIComponent(this1.split("+").join(" "));
			}catch (_g) {
				return "";
			};
		};
	}
	static ofString(s) {
		let this1 = (s == null) ? "" : encodeURIComponent(s);
		return this1;
	}
	static get __name__() {
		return "tink.url._Portion.Portion_Impl_"
	}
	get __class__() {
		return Portion_Impl_
	}
}

