import {State_Impl_} from "../../../../tink/state/State"
import {Observable_Impl_} from "../../../../tink/state/Observable"
import {SimpleLink} from "../../../../tink/core/Callback"
import {Register} from "../../../../genes/Register"
import {Slot} from "../../../../coconut/ui/internal/Slot"
import {View} from "../../../../coconut/react/View"
import {Html} from "../../../../coconut/react/Html"

export const AppContainer = Register.global("$hxClasses")["letzbig.ui.component.app.AppContainer"] = 
class AppContainer extends Register.inherits(View) {
	new(__coco_data_) {
		this.__coco_children = new Slot(this, null, null);
		this.__coco_height = State_Impl_._new("100vh", null);
		let _gthis = this;
		this.__initAttributes(__coco_data_);
		let snapshot = null;
		super.new(function () {
			return _gthis.__coco_render();
		}, null, null, null, function (firstTime) {
			if (firstTime) {
				_gthis.viewDidMount();
			};
		});
	}
	__coco_render() {
		let attributes = {"style": {"position": "relative", "width": "100vw", "height": State_Impl_.get_value(this.__coco_height)}};
		let __r = [];
		let _g = 0;
		let _g1 = Observable_Impl_.get_value(this.__coco_children);
		while (_g < ((_g1 == null) ? 0 : _g1.length)) {
			let _0 = (_g1 == null) ? null : _g1[_g];
			++_g;
			__r.push(_0);
		};
		return Html.h("div", attributes, [Html.h("div", {"style": {"position": "absolute", "top": "0", "right": "0", "bottom": "0", "left": "0"}}, __r)]);
	}
	viewDidMount() {
		let $window = window;
		let _gthis = this;
		if (!("visualViewport" in window)) {
			return;
		};
		let visualViewport = $window.visualViewport;
		let isStandalone = $window.matchMedia("(display-mode: standalone)").matches;
		let onResize = function () {
			let height = visualViewport.height;
			if (isStandalone && height == $window.innerHeight) {
				height = $window.outerHeight;
			};
			let param = height + "px";
			_gthis.__coco_height.set(param);
		};
		onResize();
		visualViewport.addEventListener("resize", onResize);
		let this1 = new SimpleLink(function () {
			return visualViewport.removeEventListener("resize", onResize);
		});
		this.__bu.push(this1);
	}
	__initAttributes(attributes) {
		this.__coco_children.setData(attributes.children);
	}
	static fromHxx(hxxMeta, attributes) {
		return {"$$typeof": View.TRE, "type": AppContainer, "props": attributes, "key": hxxMeta.key, "ref": (!hxxMeta.ref) ? null : hxxMeta.ref};
	}
	static get __name__() {
		return "letzbig.ui.component.app.AppContainer"
	}
	static get __super__() {
		return View
	}
	get __class__() {
		return AppContainer
	}
}

