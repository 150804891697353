import {Revision_Impl_} from "./internal/Revision"
import {ObservableObject} from "./internal/ObservableObject"
import {Binding} from "./internal/Binding"
import {AutoObservable, Computation_Impl_} from "./internal/AutoObservable"
import {Scheduler_Impl_} from "./Scheduler"
import {FutureTrigger} from "../core/Future"
import {Option} from "../../haxe/ds/Option"
import {Register} from "../../genes/Register"

export const ConstObservable = Register.global("$hxClasses")["tink.state._Observable.ConstObservable"] = 
class ConstObservable extends Register.inherits() {
	new(value, toString = null) {
		this.revision = Revision_Impl_._new();
		this.value = value;
	}
	getRevision() {
		return this.revision;
	}
	getValue() {
		return this.value;
	}
	getComparator() {
		return null;
	}
	onInvalidate(i) {
		return null;
	}
	static get __name__() {
		return "tink.state._Observable.ConstObservable"
	}
	static get __interfaces__() {
		return [ObservableObject]
	}
	get __class__() {
		return ConstObservable
	}
}


export const Observable_Impl_ = Register.global("$hxClasses")["tink.state._Observable.Observable_Impl_"] = 
class Observable_Impl_ {
	static get value() {
		return this.get_value()
	}
	static get_value(this1) {
		let ret = this1.getValue();
		if (AutoObservable.cur != null) {
			AutoObservable.cur.subscribeTo(this1, ret);
		};
		return ret;
	}
	
	/**
	Bind a given `callback` to listen for changes of this observable. Returned `CallbackLink`
	object can be used to cancel the binding.
	
	The `callback` will be directly invoked with the current value for the first time and then
	will be invoked each time the binding is triggered by a value change.
	
	Note that the subsequent invokations of callbacks are done in batches, meaning that changed
	values are collected during an execution frame and are scheduled for processing at the end of
	the frame (the exact scheduling mechanism depends the platform).
	
	It is also doesn't matter how many times the value was changed before the callback is invoked,
	it will only be called once per batch if the final value is different from the previous one.
	
	You can customize this behaviour by passing a different `scheduler` and `comparator` instances
	to this function.
	*/
	static bind(this1, callback, comparator = null, scheduler = null) {
		if (scheduler == null) {
			scheduler = Observable_Impl_.scheduler;
		};
		return new Binding(this1, callback, scheduler, comparator);
	}
	static nextTime(this1, options = null, check) {
		return Observable_Impl_.getNext(this1, options, function (v) {
			if (check(v)) {
				return Option.Some(v);
			} else {
				return Option.None;
			};
		});
	}
	static getNext(this1, options = null, select) {
		let ret = new FutureTrigger();
		let waiting = options != null && options.butNotNow;
		let link = Observable_Impl_.bind(this1, function (value) {
			let out = select(value);
			if (waiting) {
				waiting = out != Option.None;
			} else {
				switch (out._hx_index) {
					case 0:
						let value1 = out.v;
						ret.trigger(value1);
						break
					case 1:
						break
					
				};
			};
		}, null, (options != null && options.hires) ? Scheduler_Impl_.direct : null);
		let _e = link;
		let tmp = function () {
			if (_e != null) {
				_e.cancel();
			};
		};
		ret.handle(tmp);
		return ret;
	}
	static map(this1, f) {
		return new AutoObservable(Computation_Impl_.sync(function () {
			let value = Observable_Impl_.get_value(this1);
			return f(value);
		}), null, null);
	}
	static updatePending(maxSeconds = .01) {
		if (!Observable_Impl_.isUpdating) {
			return Observable_Impl_.scheduler.progress(maxSeconds);
		} else {
			return false;
		};
	}
	static updateAll() {
		Observable_Impl_.updatePending(Infinity);
	}
	static ofPromise(p) {
		return new AutoObservable(Computation_Impl_.async(function () {
			return p;
		}), null, null);
	}
	static get __name__() {
		return "tink.state._Observable.Observable_Impl_"
	}
	get __class__() {
		return Observable_Impl_
	}
}


Observable_Impl_.MAX_ITERATIONS = 100
Register.createStatic(Observable_Impl_, "scheduler", function () { return Scheduler_Impl_.batched(Scheduler_Impl_.batcher()) })
Observable_Impl_.isUpdating = false
export const Transform_Impl_ = Register.global("$hxClasses")["tink.state._Observable.Transform_Impl_"] = 
class Transform_Impl_ {
	static plain(f) {
		let this1 = f;
		return this1;
	}
	static get __name__() {
		return "tink.state._Observable.Transform_Impl_"
	}
	get __class__() {
		return Transform_Impl_
	}
}


export const ObservableTools = Register.global("$hxClasses")["tink.state.ObservableTools"] = 
class ObservableTools {
	static flatten(o) {
		return new AutoObservable(Computation_Impl_.sync(function () {
			return Observable_Impl_.get_value(Observable_Impl_.get_value(o));
		}), null, null);
	}
	static get __name__() {
		return "tink.state.ObservableTools"
	}
	get __class__() {
		return ObservableTools
	}
}

