import {Register} from "../genes/Register"

export const ReactType_Impl_ = Register.global("$hxClasses")["react._ReactType.ReactType_Impl_"] = 
class ReactType_Impl_ {
	static fromString(s) {
		return s;
	}
	static fromComp(cls) {
		if (cls.__jsxStatic != null) {
			return cls.__jsxStatic;
		};
		return cls;
	}
	static get __name__() {
		return "react._ReactType.ReactType_Impl_"
	}
	get __class__() {
		return ReactType_Impl_
	}
}

