import {LazyFunc} from "../../tink/core/Lazy"
import {ReactType_Impl_} from "../../react/ReactType"
import * as React from "react"
import {Component} from "react"
import {Register} from "../../genes/Register"
import {ImplicitContext} from "../ui/internal/ImplicitContext"
import {View} from "./View"

export const Implicit = Register.global("$hxClasses")["coconut.react.Implicit"] = 
class Implicit extends Register.inherits(Component) {
	new() {
		Component.call(this);
		let _gthis = this;
		this.__coco_context = new ImplicitContext(new LazyFunc(function () {
			return _gthis.context;
		}));
	}
	render() {
		this.__coco_context.update(this.props.defaults);
		return React.createElement(ReactType_Impl_.fromString(Implicit.contextType.Provider), {"value": this.__coco_context}, View.createFragment({}, this.props.children));
	}
	static fromHxx(attr) {
		return React.createElement(Implicit, attr);
	}
	static get __name__() {
		return "coconut.react.Implicit"
	}
	static get __super__() {
		return Component
	}
	get __class__() {
		return Implicit
	}
}


Implicit.contextType = React.createContext(new ImplicitContext())