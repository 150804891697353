import {ConstObservable, Observable_Impl_} from "../../../../tink/state/Observable"
import {ReactType_Impl_} from "../../../../react/ReactType"
import * as React from "react"
import {Register} from "../../../../genes/Register"
import {Slot} from "../../../../coconut/ui/internal/Slot"
import {View} from "../../../../coconut/react/View"
import * as Styles from "@material-ui/core/styles"
import {Button} from "@material-ui/core"

export const OutlineButton = Register.global("$hxClasses")["letzbig.ui.component.app.OutlineButton"] = 
class OutlineButton extends Register.inherits(View) {
	new(__coco_data_) {
		this.__coco_classes = new Slot(this);
		this.__coco_label = new Slot(this, null, null);
		let _gthis = this;
		this.__coco_fullWidth = new Slot(this, null, new ConstObservable(false, null));
		this.__coco_icon = new Slot(this, null, null);
		this.__coco_onClick = new Slot(this, null, null);
		this.__initAttributes(__coco_data_);
		let snapshot = null;
		super.new(function () {
			return _gthis.__coco_render();
		}, null, null, null, null);
	}
	__coco_render() {
		let props = {"className": Observable_Impl_.get_value(this.__coco_classes).button, "variant": "outlined", "startIcon": Observable_Impl_.get_value(this.__coco_icon), "fullWidth": Observable_Impl_.get_value(this.__coco_fullWidth), "onClick": Observable_Impl_.get_value(this.__coco_onClick)};
		let children = [Observable_Impl_.get_value(this.__coco_label)];
		let tmp = [ReactType_Impl_.fromComp(Button), props].concat(children);
		return React.createElement.apply(React, tmp);
	}
	__initAttributes(attributes) {
		this.__coco_label.setData(attributes.label);
		this.__coco_fullWidth.setData(attributes.fullWidth);
		this.__coco_icon.setData(attributes.icon);
		this.__coco_onClick.setData(attributes.onClick);
		let value = attributes.classes;
		this.__coco_classes.setData(new ConstObservable(value, null));
	}
	static styles(theme) {
		return {"button": {"color": theme.palette.secondary.dark, "textTransform": "none"}};
	}
	static fromHxx(hxxMeta, attributes) {
		return {"$$typeof": View.TRE, "type": OutlineButton.__hoc, "props": attributes, "key": hxxMeta.key, "ref": (!hxxMeta.ref) ? null : hxxMeta.ref};
	}
	static get __name__() {
		return "letzbig.ui.component.app.OutlineButton"
	}
	static get __super__() {
		return View
	}
	get __class__() {
		return OutlineButton
	}
}


OutlineButton.__hoc = (Styles.withStyles(OutlineButton.styles))(OutlineButton)