import {Exception} from "../haxe/Exception"
import {Register} from "../genes/Register"

export const Browser = Register.global("$hxClasses")["js.Browser"] = 
class Browser {
	
	/**
	* Safely gets the browser's local storage, or returns null if localStorage is unsupported or
	* disabled.
	*/
	static getLocalStorage() {
		try {
			let s = window.localStorage;
			s.getItem("");
			if (s.length == 0) {
				let key = "_hx_" + Math.random();
				s.setItem(key, key);
				s.removeItem(key);
			};
			return s;
		}catch (_g) {
			return null;
		};
	}
	
	/**
	* Creates an XMLHttpRequest, with a fallback to ActiveXObject for ancient versions of Internet
	* Explorer.
	*/
	static createXMLHttpRequest() {
		if (typeof XMLHttpRequest != "undefined") {
			return new XMLHttpRequest();
		};
		if (typeof ActiveXObject != "undefined") {
			return new "ActiveXObject"("Microsoft.XMLHTTP");
		};
		throw Exception.thrown("Unable to create XMLHttpRequest object.");
	}
	static get __name__() {
		return "js.Browser"
	}
	get __class__() {
		return Browser
	}
}

