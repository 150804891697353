import {Suspendable, Signal_Impl_} from "./Signal"
import {MPair} from "./Pair"
import {SuspendableFuture} from "./Future"
import {AlreadyDisposed} from "./Disposable"
import {Option} from "../../haxe/ds/Option"
import {Register} from "../../genes/Register"

export const ProgressValue_Impl_ = Register.global("$hxClasses")["tink.core._Progress.ProgressValue_Impl_"] = 
class ProgressValue_Impl_ {
	static get __name__() {
		return "tink.core._Progress.ProgressValue_Impl_"
	}
	get __class__() {
		return ProgressValue_Impl_
	}
}


ProgressValue_Impl_.ZERO = (function($this) {var $r0
	let this1 = new MPair(0, Option.None);
	let this2 = this1;
	
	$r0 = this2
	return $r0})(this)
export const Progress_Impl_ = Register.global("$hxClasses")["tink.core._Progress.Progress_Impl_"] = 
class Progress_Impl_ {
	static make(f) {
		return new SuspendableProgress(function (fire) {
			return f(function (value, total) {
				let fire1 = fire;
				let this1 = new MPair(value, total);
				let this2 = this1;
				fire1(ProgressStatus.InProgress(this2));
			}, function (result) {
				fire(ProgressStatus.Finished(result));
			});
		});
	}
	static get __name__() {
		return "tink.core._Progress.Progress_Impl_"
	}
	get __class__() {
		return Progress_Impl_
	}
}


export const ProgressObject = Register.global("$hxClasses")["tink.core._Progress.ProgressObject"] = 
class ProgressObject extends Register.inherits() {
	new(changed, getStatus) {
		this.changed = changed;
		let this1 = new Suspendable(function (fire) {
			return changed.listen(function (s) {
				if (s._hx_index == 0) {
					let v = s.v;
					fire(v);
				};
			});
		}, null);
		this.progressed = this1;
		this.getStatus = getStatus;
		let this2 = new SuspendableFuture(function (fire) {
			let _g = getStatus();
			if (_g._hx_index == 1) {
				let v = _g.v;
				fire(v);
				return null;
			} else {
				return changed.listen(function (s) {
					if (s._hx_index == 1) {
						let v = s.v;
						fire(v);
					};
				});
			};
		});
		this.result = this2;
	}
	static get __name__() {
		return "tink.core._Progress.ProgressObject"
	}
	get __class__() {
		return ProgressObject
	}
}


export const SuspendableProgress = Register.global("$hxClasses")["tink.core._Progress.SuspendableProgress"] = 
class SuspendableProgress extends Register.inherits(ProgressObject) {
	new(wakeup, status = null) {
		if (status == null) {
			status = ProgressStatus.InProgress(ProgressValue_Impl_.ZERO);
		};
		let disposable = AlreadyDisposed.INST;
		let changed;
		switch (status._hx_index) {
			case 0:
				let _g = status.v;
				let this1 = new Suspendable(function (fire) {
					return wakeup(function (s) {
						status = s;
						fire(status);
					});
				}, function (d) {
					disposable = d;
				});
				changed = this1;
				break
			case 1:
				let _g1 = status.v;
				changed = Signal_Impl_.dead();
				break
			
		};
		super.new(changed, function () {
			return status;
		});
	}
	static get __name__() {
		return "tink.core._Progress.SuspendableProgress"
	}
	static get __super__() {
		return ProgressObject
	}
	get __class__() {
		return SuspendableProgress
	}
}


export const ProgressStatus = 
Register.global("$hxEnums")["tink.core.ProgressStatus"] = 
{
	__ename__: "tink.core.ProgressStatus",
	
	InProgress: Object.assign((v) => ({_hx_index: 0, __enum__: "tink.core.ProgressStatus", v}), {_hx_name: "InProgress", __params__: ["v"]}),
	Finished: Object.assign((v) => ({_hx_index: 1, __enum__: "tink.core.ProgressStatus", v}), {_hx_name: "Finished", __params__: ["v"]})
}
ProgressStatus.__constructs__ = ["InProgress", "Finished"]
ProgressStatus.__empty_constructs__ = []
