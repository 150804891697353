import {LazyConst} from "./Lazy"
import {SyncFuture} from "./Future"
import {Register} from "../../genes/Register"

/**
Representation of the outcome of any kind of operation that can fail.

Values of this type automatically use the extension methods defined in `OutcomeTools`.
*/
export const Outcome = 
Register.global("$hxEnums")["tink.core.Outcome"] = 
{
	__ename__: "tink.core.Outcome",
	
	Success: Object.assign((data) => ({_hx_index: 0, __enum__: "tink.core.Outcome", data}), {_hx_name: "Success", __params__: ["data"]}),
	Failure: Object.assign((failure) => ({_hx_index: 1, __enum__: "tink.core.Outcome", failure}), {_hx_name: "Failure", __params__: ["failure"]})
}
Outcome.__constructs__ = ["Success", "Failure"]
Outcome.__empty_constructs__ = []

export const OutcomeTools = Register.global("$hxClasses")["tink.core.OutcomeTools"] = 
class OutcomeTools {
	
	/**
	*  Transforms the outcome with a transform function
	*  Different from `flatMap`, the transform function of `map` returns a plain value
	*/
	static map(outcome, transform) {
		switch (outcome._hx_index) {
			case 0:
				let a = outcome.data;
				return Outcome.Success(transform(a));
				break
			case 1:
				let f = outcome.failure;
				return Outcome.Failure(f);
				break
			
		};
	}
	static next(outcome, f) {
		switch (outcome._hx_index) {
			case 0:
				let v = outcome.data;
				return f(v);
				break
			case 1:
				let e = outcome.failure;
				return new SyncFuture(new LazyConst(Outcome.Failure(e)));
				break
			
		};
	}
	static get __name__() {
		return "tink.core.OutcomeTools"
	}
	get __class__() {
		return OutcomeTools
	}
}

