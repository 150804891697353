import {Register} from "../../../genes/Register"

export const Configs = {}

export const AuthConfig = 
Register.global("$hxEnums")["letzbig.api.external.AuthConfig"] = 
{
	__ename__: "letzbig.api.external.AuthConfig",
	
	Cognito: Object.assign((cognito) => ({_hx_index: 0, __enum__: "letzbig.api.external.AuthConfig", cognito}), {_hx_name: "Cognito", __params__: ["cognito"]})
}
AuthConfig.__constructs__ = ["Cognito"]
AuthConfig.__empty_constructs__ = []
