import {Register} from "../../genes/Register"

export const PromisedWith = 
Register.global("$hxEnums")["tink.state.PromisedWith"] = 
{
	__ename__: "tink.state.PromisedWith",
	
	Loading: {_hx_name: "Loading", _hx_index: 0, __enum__: "tink.state.PromisedWith"},
	Done: Object.assign((result) => ({_hx_index: 1, __enum__: "tink.state.PromisedWith", result}), {_hx_name: "Done", __params__: ["result"]}),
	Failed: Object.assign((error) => ({_hx_index: 2, __enum__: "tink.state.PromisedWith", error}), {_hx_name: "Failed", __params__: ["error"]})
}
PromisedWith.__constructs__ = ["Loading", "Done", "Failed"]
PromisedWith.__empty_constructs__ = [PromisedWith.Loading]

export const PromisedTools = Register.global("$hxClasses")["tink.state.PromisedTools"] = 
class PromisedTools {
	static map(a, f) {
		switch (a._hx_index) {
			case 0:
				return PromisedWith.Loading;
				break
			case 1:
				let a1 = a.result;
				return PromisedWith.Done(f(a1));
				break
			case 2:
				let e = a.error;
				return PromisedWith.Failed(e);
				break
			
		};
	}
	static orNull(p) {
		if (p._hx_index == 1) {
			let v = p.result;
			return v;
		} else {
			return null;
		};
	}
	static get __name__() {
		return "tink.state.PromisedTools"
	}
	get __class__() {
		return PromisedTools
	}
}

