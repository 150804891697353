import {Empty, Reducer_Impl_, ReductionStep} from "../streams/Stream"
import {Outcome} from "../core/Outcome"
import {LazyConst} from "../core/Lazy"
import {SyncFuture, Future_Impl_} from "../core/Future"
import {Chunk_Impl_} from "../Chunk"
import {Register} from "../../genes/Register"

export const Source_Impl_ = Register.global("$hxClasses")["tink.io._Source.Source_Impl_"] = 
class Source_Impl_ {
	static chunked(this1) {
		return this1;
	}
	static concatAll(s) {
		return s.reduce(Chunk_Impl_.EMPTY, Reducer_Impl_.ofSafe(function (res, cur) {
			return new SyncFuture(new LazyConst(ReductionStep.Progress(Chunk_Impl_.concat(res, cur))));
		}));
	}
	static get __name__() {
		return "tink.io._Source.Source_Impl_"
	}
	get __class__() {
		return Source_Impl_
	}
}


Source_Impl_.EMPTY = Empty.inst
export const RealSourceTools = Register.global("$hxClasses")["tink.io.RealSourceTools"] = 
class RealSourceTools {
	static all(s) {
		return Future_Impl_.map(Source_Impl_.concatAll(s), function (o) {
			switch (o._hx_index) {
				case 1:
					let e = o.error;
					return Outcome.Failure(e);
					break
				case 2:
					let c = o.result;
					return Outcome.Success(c);
					break
				
			};
		});
	}
	static idealize(s, rescue) {
		return Source_Impl_.chunked(s).idealize(rescue);
	}
	static get __name__() {
		return "tink.io.RealSourceTools"
	}
	get __class__() {
		return RealSourceTools
	}
}


export const IdealSourceTools = Register.global("$hxClasses")["tink.io.IdealSourceTools"] = 
class IdealSourceTools {
	static all(s) {
		return Future_Impl_.map(Source_Impl_.concatAll(s), function (o) {
			let c = o.result;
			return c;
		});
	}
	static get __name__() {
		return "tink.io.IdealSourceTools"
	}
	get __class__() {
		return IdealSourceTools
	}
}

