import {Register} from "../../genes/Register"

export const Status = 
Register.global("$hxEnums")["why.auth.Status"] = 
{
	__ename__: "why.auth.Status",
	
	Initializing: {_hx_name: "Initializing", _hx_index: 0, __enum__: "why.auth.Status"},
	SignedOut: {_hx_name: "SignedOut", _hx_index: 1, __enum__: "why.auth.Status"},
	SignedIn: Object.assign((profile) => ({_hx_index: 2, __enum__: "why.auth.Status", profile}), {_hx_name: "SignedIn", __params__: ["profile"]}),
	Errored: Object.assign((e) => ({_hx_index: 3, __enum__: "why.auth.Status", e}), {_hx_name: "Errored", __params__: ["e"]})
}
Status.__constructs__ = ["Initializing", "SignedOut", "SignedIn", "Errored"]
Status.__empty_constructs__ = [Status.Initializing, Status.SignedOut]

export const StatusTools = Register.global("$hxClasses")["why.auth.StatusTools"] = 
class StatusTools {
	static map(status, f) {
		switch (status._hx_index) {
			case 0:
				return Status.Initializing;
				break
			case 1:
				return Status.SignedOut;
				break
			case 2:
				let profile = status.profile;
				return Status.SignedIn(f(profile));
				break
			case 3:
				let e = status.e;
				return Status.Errored(e);
				break
			
		};
	}
	static get __name__() {
		return "why.auth.StatusTools"
	}
	get __class__() {
		return StatusTools
	}
}

