import {BasicWriter} from "./Writer"
import {Register} from "../../genes/Register"

export const Writer7 = Register.global("$hxClasses")["tink.json.Writer7"] = 
class Writer7 extends Register.inherits(BasicWriter) {
	new() {
		super.new();
	}
	process0(value) {
		let __first = true;
		this.buf += String.fromCodePoint(123);
		let value1 = value._20;
		if (__first) {
			__first = false;
		} else {
			this.buf += String.fromCodePoint(44);
		};
		this.buf += "\"credentials\":";
		this.process1(value1);
		this.buf += String.fromCodePoint(125);
	}
	process1(value) {
		let _g = value;
		switch (_g._hx_index) {
			case 0:
				let deviceId = _g.deviceId;
				this.buf += "{\"Guest\":{";
				this.buf += "\"deviceId\"";
				this.buf += String.fromCodePoint(58);
				let value1 = deviceId;
				let s = JSON.stringify(value1);
				this.buf += s;
				this.buf += "}}";
				break
			case 1:
				let token = _g.token;
				this.buf += "{\"Cognito\":{";
				this.buf += "\"token\"";
				this.buf += String.fromCodePoint(58);
				let value2 = token;
				let s1 = JSON.stringify(value2);
				this.buf += s1;
				this.buf += "}}";
				break
			
		};
	}
	write(value) {
		this.init();
		this.process0(value);
		return this.buf.toString();
	}
	static get __name__() {
		return "tink.json.Writer7"
	}
	static get __super__() {
		return BasicWriter
	}
	get __class__() {
		return Writer7
	}
}

