import {State_Impl_} from "../../../tink/state/State"
import {ConstObservable, Observable_Impl_, Transform_Impl_} from "../../../tink/state/Observable"
import {Signal_Impl_} from "../../../tink/core/Signal"
import {Register} from "../../../genes/Register"
import {Annex} from "../../../coconut/data/helpers/Annex"
import {Model} from "../../../coconut/data/Model"
import {Std} from "../../../Std"

export const AuthModel = Register.global("$hxClasses")["letzbig.app.data.AuthModel"] = 
class AuthModel extends Register.inherits() {
	new(__coco_init) {
		this.delegate = __coco_init.delegate;
		this.__coco_status = __coco_init.status;
		this.__coco_transitionCount = State_Impl_._new(0);
		this.errorTrigger = Signal_Impl_.trigger();
		this.transitionErrors = this.errorTrigger;
		this.annex = new Annex(this);
		this.observables = {"delegate": new ConstObservable(this.delegate, null), "status": this.__coco_status, "isInTransition": Observable_Impl_.map(this.__coco_transitionCount, Transform_Impl_.plain(function (count) {
			return count > 0;
		}))};
	}
	static getDeviceId() {
		let storage = window.localStorage;
		let _g = storage.getItem("letzbig-device-id");
		if (_g == null) {
			let result = "";
			let characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
			let count = characters.length;
			let _g = 0;
			while (_g < 48) {
				let i = _g++;
				result += characters.charAt(Std.random(count));
			};
			let id = result;
			storage.setItem("letzbig-device-id", id);
			return id;
		} else if (_g == "") {
			let result = "";
			let characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
			let count = characters.length;
			let _g = 0;
			while (_g < 48) {
				let i = _g++;
				result += characters.charAt(Std.random(count));
			};
			let id = result;
			storage.setItem("letzbig-device-id", id);
			return id;
		} else {
			let id = _g;
			return id;
		};
	}
	static get __name__() {
		return "letzbig.app.data.AuthModel"
	}
	static get __interfaces__() {
		return [Model]
	}
	get __class__() {
		return AuthModel
	}
}

