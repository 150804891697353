import {Register} from "./genes/Register"
import {HxOverrides} from "./HxOverrides"

/**
This class provides advanced methods on Strings. It is ideally used with
`using StringTools` and then acts as an [extension](https://haxe.org/manual/lf-static-extension.html)
to the `String` class.

If the first argument to any of the methods is null, the result is
unspecified.
*/
export const StringTools = Register.global("$hxClasses")["StringTools"] = 
class StringTools {
	
	/**
	Tells if the string `s` starts with the string `start`.
	
	If `start` is `null`, the result is unspecified.
	
	If `start` is the empty String `""`, the result is true.
	*/
	static startsWith(s, start) {
		if (s.length >= start.length) {
			return s.lastIndexOf(start, 0) == 0;
		} else {
			return false;
		};
	}
	
	/**
	Tells if the string `s` ends with the string `end`.
	
	If `end` is `null`, the result is unspecified.
	
	If `end` is the empty String `""`, the result is true.
	*/
	static endsWith(s, end) {
		let elen = end.length;
		let slen = s.length;
		if (slen >= elen) {
			return s.indexOf(end, slen - elen) == slen - elen;
		} else {
			return false;
		};
	}
	
	/**
	Tells if the character in the string `s` at position `pos` is a space.
	
	A character is considered to be a space character if its character code
	is 9,10,11,12,13 or 32.
	
	If `s` is the empty String `""`, or if pos is not a valid position within
	`s`, the result is false.
	*/
	static isSpace(s, pos) {
		let c = HxOverrides.cca(s, pos);
		if (!(c > 8 && c < 14)) {
			return c == 32;
		} else {
			return true;
		};
	}
	
	/**
	Removes leading space characters of `s`.
	
	This function internally calls `isSpace()` to decide which characters to
	remove.
	
	If `s` is the empty String `""` or consists only of space characters, the
	result is the empty String `""`.
	*/
	static ltrim(s) {
		let l = s.length;
		let r = 0;
		while (r < l && StringTools.isSpace(s, r)) ++r;
		if (r > 0) {
			return HxOverrides.substr(s, r, l - r);
		} else {
			return s;
		};
	}
	
	/**
	Removes trailing space characters of `s`.
	
	This function internally calls `isSpace()` to decide which characters to
	remove.
	
	If `s` is the empty String `""` or consists only of space characters, the
	result is the empty String `""`.
	*/
	static rtrim(s) {
		let l = s.length;
		let r = 0;
		while (r < l && StringTools.isSpace(s, l - r - 1)) ++r;
		if (r > 0) {
			return HxOverrides.substr(s, 0, l - r);
		} else {
			return s;
		};
	}
	
	/**
	Removes leading and trailing space characters of `s`.
	
	This is a convenience function for `ltrim(rtrim(s))`.
	*/
	static trim(s) {
		return StringTools.ltrim(StringTools.rtrim(s));
	}
	
	/**
	Concatenates `c` to `s` until `s.length` is at least `l`.
	
	If `c` is the empty String `""` or if `l` does not exceed `s.length`,
	`s` is returned unchanged.
	
	If `c.length` is 1, the resulting String length is exactly `l`.
	
	Otherwise the length may exceed `l`.
	
	If `c` is null, the result is unspecified.
	*/
	static lpad(s, c, l) {
		if (c.length <= 0) {
			return s;
		};
		let buf_b = "";
		l -= s.length;
		while (buf_b.length < l) buf_b += (c == null) ? "null" : "" + c;
		buf_b += (s == null) ? "null" : "" + s;
		return buf_b;
	}
	
	/**
	Replace all occurrences of the String `sub` in the String `s` by the
	String `by`.
	
	If `sub` is the empty String `""`, `by` is inserted after each character
	of `s` except the last one. If `by` is also the empty String `""`, `s`
	remains unchanged.
	
	If `sub` or `by` are null, the result is unspecified.
	*/
	static replace(s, sub, by) {
		return s.split(sub).join(by);
	}
	
	/**
	Encodes `n` into a hexadecimal representation.
	
	If `digits` is specified, the resulting String is padded with "0" until
	its `length` equals `digits`.
	*/
	static hex(n, digits = null) {
		let s = "";
		let hexChars = "0123456789ABCDEF";
		while (true) {
			s = hexChars.charAt(n & 15) + s;
			n >>>= 4;
			if (!(n > 0)) {
				break;
			};
		};
		if (digits != null) {
			while (s.length < digits) s = "0" + s;
		};
		return s;
	}
	static get __name__() {
		return "StringTools"
	}
	get __class__() {
		return StringTools
	}
}

