import {Promise_Impl_} from "../core/Promise"
import {Outcome} from "../core/Outcome"
import {LazyConst} from "../core/Lazy"
import {SyncFuture} from "../core/Future"
import {Register} from "../../genes/Register"

export const ClientObject = {}

export const CustomClient = Register.global("$hxClasses")["tink.http._Client.CustomClient"] = 
class CustomClient extends Register.inherits() {
	new(preprocessors, postprocessors, real) {
		this.preprocessors = preprocessors;
		this.postprocessors = postprocessors;
		this.real = real;
	}
	pipe(value, transforms, index = 0) {
		if (transforms != null && index < transforms.length) {
			let _g = Register.bind(this, this.pipe);
			let transforms1 = transforms;
			let index1 = index + 1;
			let tmp = function (value) {
				return _g(value, transforms1, index1);
			};
			return Promise_Impl_.next(transforms[index](value), tmp);
		} else {
			return new SyncFuture(new LazyConst(Outcome.Success(value)));
		};
	}
	request(req, handlers = null) {
		let _gthis = this;
		return Promise_Impl_.next(this.pipe(req, this.preprocessors), function (req) {
			let tmp = _gthis.real.request(req, handlers);
			let _g = Register.bind(_gthis, _gthis.pipe);
			let transforms;
			if (_gthis.postprocessors == null) {
				transforms = null;
			} else {
				let _g = [];
				let _g1 = 0;
				let _g2 = _gthis.postprocessors;
				while (_g1 < _g2.length) {
					let p = _g2[_g1];
					++_g1;
					_g.push(p(req));
				};
				transforms = _g;
			};
			return Promise_Impl_.next(tmp, function (value) {
				return _g(value, transforms);
			});
		});
	}
	static concat(a, b) {
		if (a == null) {
			let v = b;
			return v;
		} else if (b == null) {
			let v = a;
			return v;
		} else {
			return a.concat(b);
		};
	}
	static create(c, preprocessors, postprocessors) {
		let _g = (((c) instanceof CustomClient)) ? c : null;
		if (_g == null) {
			return new CustomClient(preprocessors, postprocessors, c);
		} else {
			let v = _g;
			return new CustomClient(CustomClient.concat(preprocessors, v.preprocessors), CustomClient.concat(v.postprocessors, postprocessors), v.real);
		};
	}
	static get __name__() {
		return "tink.http._Client.CustomClient"
	}
	static get __interfaces__() {
		return [ClientObject]
	}
	get __class__() {
		return CustomClient
	}
}

