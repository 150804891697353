import {Invalidatable} from "./Invalidatable"
import {Schedulable, Scheduler_Impl_} from "../Scheduler"
import {LinkObject, Callback_Impl_} from "../../core/Callback"
import {Register} from "../../../genes/Register"

export const Binding = Register.global("$hxClasses")["tink.state.internal.Binding"] = 
class Binding extends Register.inherits() {
	new(data, cb, scheduler = null, comparator = null) {
		this.last = null;
		this.status = 0;
		this.data = data;
		this.cb = cb;
		let tmp;
		if (scheduler == null) {
			tmp = Scheduler_Impl_.direct;
		} else {
			let v = scheduler;
			tmp = v;
		};
		this.scheduler = tmp;
		let this1 = data.getComparator();
		let tmp1;
		let _g = comparator;
		if (this1 == null) {
			let v = _g;
			tmp1 = v;
		} else if (_g == null) {
			let v = this1;
			tmp1 = v;
		} else {
			let c1 = this1;
			let c2 = _g;
			tmp1 = function (a, b) {
				if (!c1(a, b)) {
					return c2(a, b);
				} else {
					return true;
				};
			};
		};
		this.comparator = tmp1;
		this.link = data.onInvalidate(this);
		Callback_Impl_.invoke(cb, this.last = data.getValue());
	}
	cancel() {
		let this1 = this.link;
		if (this1 != null) {
			this1.cancel();
		};
		this.status = 2;
	}
	invalidate() {
		if (this.status == 0) {
			this.status = 1;
			this.scheduler.schedule(this);
		};
	}
	run() {
		switch (this.status) {
			case 1:
				this.status = 0;
				let prev = this.last;
				let next = this.last = this.data.getValue();
				let this1 = this.comparator;
				let tmp;
				if (this1 == null) {
					tmp = prev == next;
				} else {
					let f = this1;
					tmp = f(prev, next);
				};
				if (!tmp) {
					Callback_Impl_.invoke(this.cb, next);
				};
				break
			case 0:case 2:
				break
			
		};
	}
	static get __name__() {
		return "tink.state.internal.Binding"
	}
	static get __interfaces__() {
		return [LinkObject, Schedulable, Invalidatable]
	}
	get __class__() {
		return Binding
	}
}

