import {Register} from "../../genes/Register"

export const NamedWith = Register.global("$hxClasses")["tink.core.NamedWith"] = 
class NamedWith extends Register.inherits() {
	new(name, value) {
		this.name = name;
		this.value = value;
	}
	static get __name__() {
		return "tink.core.NamedWith"
	}
	get __class__() {
		return NamedWith
	}
}

