import {BasicWriter} from "./Writer"
import {Register} from "../../genes/Register"

export const Writer5 = Register.global("$hxClasses")["tink.json.Writer5"] = 
class Writer5 extends Register.inherits(BasicWriter) {
	new() {
		super.new();
	}
	write(value) {
		this.init();
		this.buf += String.fromCodePoint(91);
		let first = true;
		let _g = 0;
		while (_g < value.length) {
			let value1 = value[_g];
			++_g;
			if (first) {
				first = false;
			} else {
				this.buf += String.fromCodePoint(44);
			};
			let value2 = value1;
			let s = JSON.stringify(value2);
			this.buf += s;
		};
		this.buf += String.fromCodePoint(93);
		return this.buf.toString();
	}
	static get __name__() {
		return "tink.json.Writer5"
	}
	static get __super__() {
		return BasicWriter
	}
	get __class__() {
		return Writer5
	}
}

