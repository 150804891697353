import {Observable_Impl_} from "./Observable"
import {TypedError} from "../core/Error"
import {Timer} from "../../haxe/Timer"
import {Register} from "../../genes/Register"
import {HxOverrides} from "../../HxOverrides"

export const SchedulerObject = {}

export const DirectScheduler = Register.global("$hxClasses")["tink.state._Scheduler.DirectScheduler"] = 
class DirectScheduler extends Register.inherits() {
	new() {
		this.queue = null;
	}
	progress(_) {
		return false;
	}
	schedule(s) {
		if (this.queue != null) {
			this.queue.push(s);
		} else {
			let wasUpdating = Observable_Impl_.isUpdating;
			Observable_Impl_.isUpdating = true;
			TypedError.tryFinally(Register.bind(s, s.run), function () {
				Observable_Impl_.isUpdating = wasUpdating;
			});
		};
	}
	static get __name__() {
		return "tink.state._Scheduler.DirectScheduler"
	}
	static get __interfaces__() {
		return [SchedulerObject]
	}
	get __class__() {
		return DirectScheduler
	}
}


export const Scheduler_Impl_ = Register.global("$hxClasses")["tink.state._Scheduler.Scheduler_Impl_"] = 
class Scheduler_Impl_ {
	static batched(run) {
		return new BatchScheduler(run);
	}
	static batcher() {
		let later = function (fn) {
			Timer.delay(fn, 10);
		};
		let later1;
		try {
			later1 = ((o=>Register.bind(o, o.requestAnimationFrame))(window) != null) ? function (fn) {
				window.requestAnimationFrame(fn);
			} : later;
		}catch (_g) {
			later1 = later;
		};
		let asap = function (fn) {
			later1(fn);
		};
		let asap1;
		try {
			let p = Promise.resolve(42);
			asap1 = function (fn) {
				p.then(fn);
			};
		}catch (_g) {
			asap1 = asap;
		};
		return function (b, isRerun) {
			let _g = Register.bind(b, b.progress);
			let maxSeconds = .01;
			((isRerun) ? later1 : asap1)(function () {
				return _g(maxSeconds);
			});
		};
	}
	static get __name__() {
		return "tink.state._Scheduler.Scheduler_Impl_"
	}
	get __class__() {
		return Scheduler_Impl_
	}
}


Register.createStatic(Scheduler_Impl_, "direct", function () { return new DirectScheduler() })
export const BatchScheduler = Register.global("$hxClasses")["tink.state._Scheduler.BatchScheduler"] = 
class BatchScheduler extends Register.inherits() {
	new(run) {
		this.scheduled = false;
		this.queue = [];
		this.run = run;
	}
	progress(maxSeconds) {
		let _gthis = this;
		let wasUpdating = Observable_Impl_.isUpdating;
		Observable_Impl_.isUpdating = true;
		return TypedError.tryFinally(function () {
			let end = HxOverrides.now() / 1000 + maxSeconds;
			while (true) {
				let old = _gthis.queue;
				_gthis.queue = [];
				let _g = 0;
				while (_g < old.length) {
					let o = old[_g];
					++_g;
					o.run();
				};
				if (!(_gthis.queue.length > 0 && HxOverrides.now() / 1000 < end)) {
					break;
				};
			};
			if (_gthis.queue.length > 0) {
				_gthis.run(_gthis, true);
				return true;
			} else {
				return _gthis.scheduled = false;
			};
		}, function () {
			Observable_Impl_.isUpdating = wasUpdating;
		});
	}
	schedule(s) {
		this.queue.push(s);
		if (!this.scheduled) {
			this.scheduled = true;
			this.run(this, false);
		};
	}
	static get __name__() {
		return "tink.state._Scheduler.BatchScheduler"
	}
	static get __interfaces__() {
		return [SchedulerObject]
	}
	get __class__() {
		return BatchScheduler
	}
}


export const Schedulable = {}
