import {Status} from "./auth/Status"
import {AutoObservable, Computation_Impl_} from "../tink/state/internal/AutoObservable"
import {Observable_Impl_, ObservableTools, Transform_Impl_, ConstObservable} from "../tink/state/Observable"
import {Promise_Impl_} from "../tink/core/Promise"
import {Option} from "../haxe/ds/Option"
import {Exception} from "../haxe/Exception"
import {Register} from "../genes/Register"

export const User = {}

export const DelegateObject = {}

export const DelegateBase = Register.global("$hxClasses")["why.DelegateBase"] = 
class DelegateBase extends Register.inherits() {
	new(status) {
		this.status = status;
		this.profile = new AutoObservable(Computation_Impl_.sync(function () {
			let _g = Observable_Impl_.get_value(status);
			if (_g._hx_index == 2) {
				let user = _g.profile;
				return Option.Some(Observable_Impl_.get_value(user.profile));
			} else {
				return Option.None;
			};
		}), null, null);
	}
	signUp(info) {
		throw Exception.thrown("abstract");
	}
	signIn(credentials) {
		throw Exception.thrown("abstract");
	}
	signOut() {
		throw Exception.thrown("abstract");
	}
	static get __name__() {
		return "why.DelegateBase"
	}
	static get __interfaces__() {
		return [DelegateObject]
	}
	get __class__() {
		return DelegateBase
	}
}


export const PromiseDelegate = Register.global("$hxClasses")["why.PromiseDelegate"] = 
class PromiseDelegate extends Register.inherits(DelegateBase) {
	new(promise) {
		super.new(ObservableTools.flatten(Observable_Impl_.map(Observable_Impl_.ofPromise(this.promise = promise), Transform_Impl_.plain(function (promised) {
			switch (promised._hx_index) {
				case 0:
					return new ConstObservable(Status.Initializing, null);
					break
				case 1:
					let delegate = promised.result;
					return delegate.status;
					break
				case 2:
					let e = promised.error;
					return new ConstObservable(Status.Errored(e), null);
					break
				
			};
		}))));
	}
	signUp(info) {
		return Promise_Impl_.next(this.promise, function (delegate) {
			return delegate.signUp(info);
		});
	}
	signIn(credentials) {
		return Promise_Impl_.next(this.promise, function (delegate) {
			return delegate.signIn(credentials);
		});
	}
	signOut() {
		return Promise_Impl_.next(this.promise, function (delegate) {
			return delegate.signOut();
		});
	}
	static get __name__() {
		return "why.PromiseDelegate"
	}
	static get __super__() {
		return DelegateBase
	}
	get __class__() {
		return PromiseDelegate
	}
}

