import {Outcome} from "./Outcome"
import {LazyConst} from "./Lazy"
import {SyncFuture, Future_Impl_} from "./Future"
import {Register} from "../../genes/Register"

export const Promise_Impl_ = Register.global("$hxClasses")["tink.core._Promise.Promise_Impl_"] = 
class Promise_Impl_ {
	static noise(this1) {
		return Promise_Impl_.next(this1, function (v) {
			return new SyncFuture(new LazyConst(Outcome.Success(null)));
		});
	}
	static next(this1, f, gather = null) {
		return Future_Impl_.flatMap(this1, function (o) {
			switch (o._hx_index) {
				case 0:
					let d = o.data;
					return f(d);
					break
				case 1:
					let f1 = o.failure;
					return new SyncFuture(new LazyConst(Outcome.Failure(f1)));
					break
				
			};
		});
	}
	static ofSpecific(s) {
		return s;
	}
	static inParallel(a, concurrency = null) {
		return Promise_Impl_.many(a, concurrency);
	}
	static many(a, concurrency = null) {
		return Future_Impl_.processMany(a, concurrency, function (o) {
			return o;
		}, function (o) {
			return o;
		});
	}
	static get __name__() {
		return "tink.core._Promise.Promise_Impl_"
	}
	get __class__() {
		return Promise_Impl_
	}
}


Promise_Impl_.NOISE = new SyncFuture(new LazyConst(Outcome.Success(null)))