import {Register} from "../Register"

export const EsMap = Register.global("$hxClasses")["genes.util.EsMap"] = 
class EsMap extends Register.inherits() {
	new() {
		this.inst = new Map();
	}
	get(key) {
		return this.inst.get(key);
	}
	keys() {
		return EsMap.adaptIterator(this.inst.keys());
	}
	static adaptIterator(from) {
		let value;
		let done;
		let queue = function () {
			let data = from.next();
			value = data.value;
			done = data.done;
		};
		return {"hasNext": function () {
			if (done == null) {
				queue();
			};
			return !done;
		}, "next": function () {
			if (done == null) {
				queue();
			};
			let pending = value;
			queue();
			return pending;
		}};
	}
	static get __name__() {
		return "genes.util.EsMap"
	}
	get __class__() {
		return EsMap
	}
}

