import {Path_Impl_} from "../../url/Path"
import {RealSourceTools} from "../../io/Source"
import {OutgoingRequest, OutgoingRequestHeader} from "../../http/Request"
import {Promise_Impl_} from "../../core/Promise"
import {Outcome} from "../../core/Outcome"
import {LazyConst} from "../../core/Lazy"
import {SyncFuture} from "../../core/Future"
import {TypedError} from "../../core/Error"
import {Url_Impl_} from "../../Url"
import {Register} from "../../../genes/Register"
import {HxOverrides} from "../../../HxOverrides"

export const Scheme_Impl_ = Register.global("$hxClasses")["tink.web.proxy._Remote.Scheme_Impl_"] = 
class Scheme_Impl_ {
	static fromString(s) {
		let s1;
		if (s == null) {
			s1 = "";
		} else {
			let v = s;
			let _g = v.indexOf(":");
			if (_g == -1) {
				s1 = v;
			} else {
				let i = _g;
				s1 = HxOverrides.substr(v, 0, i);
			};
		};
		let this1 = s1;
		return this1;
	}
	static get __name__() {
		return "tink.web.proxy._Remote.Scheme_Impl_"
	}
	get __class__() {
		return Scheme_Impl_
	}
}


export const RemoteEndpoint_Impl_ = Register.global("$hxClasses")["tink.web.proxy._Remote.RemoteEndpoint_Impl_"] = 
class RemoteEndpoint_Impl_ {
	static _new(host, pathSuffix = null, scheme = null) {
		let this1 = {"host": host, "pathSuffix": pathSuffix, "scheme": scheme};
		return this1;
	}
	static concat(a, b) {
		if (a == null) {
			let r = b;
			return r;
		} else if (b == null) {
			let r = a;
			return r;
		} else {
			return a.concat(b);
		};
	}
	static sub(this1, options) {
		return {"host": this1.host, "scheme": this1.scheme, "pathSuffix": this1.pathSuffix, "headers": RemoteEndpoint_Impl_.concat(this1.headers, options.headers), "query": RemoteEndpoint_Impl_.concat(this1.query, options.query), "path": RemoteEndpoint_Impl_.concat(this1.path, options.path)};
	}
	static uri(this1) {
		let _g = this1.path;
		let tmp;
		if (_g == null) {
			tmp = "";
		} else {
			let v = _g;
			tmp = Path_Impl_.normalize(v.join("/"));
		};
		return "/" + tmp + ((this1.pathSuffix == null) ? "" : this1.pathSuffix) + ((this1.query == null) ? "null" : QueryParams_Impl_.toString(this1.query));
	}
	static request(this1, client, method, body, reader) {
		return Promise_Impl_.next(client.request(new OutgoingRequest(new OutgoingRequestHeader(method, Url_Impl_.fromString("" + this1.scheme + "://" + ((this1.host == null) ? "null" : this1.host) + RemoteEndpoint_Impl_.uri(this1)), null, this1.headers), body)), function (response) {
			return (ResponseReader_Impl_.withHeader(reader, response.header))(response.body);
		});
	}
	static get __name__() {
		return "tink.web.proxy._Remote.RemoteEndpoint_Impl_"
	}
	get __class__() {
		return RemoteEndpoint_Impl_
	}
}


export const ResponseReader_Impl_ = Register.global("$hxClasses")["tink.web.proxy._Remote.ResponseReader_Impl_"] = 
class ResponseReader_Impl_ {
	static withHeader(this1, header) {
		let _g = this1;
		let a1 = header;
		return function (a2) {
			return _g(a1, a2);
		};
	}
	static ofStringReader(read) {
		return function (header, body) {
			return Promise_Impl_.next(RealSourceTools.all(body), function (chunk) {
				if (header.statusCode >= 400) {
					return new SyncFuture(new LazyConst(Outcome.Failure(TypedError.withData(header.statusCode, header.reason, chunk.toString(), {"fileName": "tink/web/proxy/Remote.hx", "lineNumber": 202, "className": "tink.web.proxy._Remote.ResponseReader_Impl_", "methodName": "ofStringReader"}))));
				} else {
					return new SyncFuture(new LazyConst(read(chunk.toString())));
				};
			});
		};
	}
	static get __name__() {
		return "tink.web.proxy._Remote.ResponseReader_Impl_"
	}
	get __class__() {
		return ResponseReader_Impl_
	}
}


export const QueryParams_Impl_ = Register.global("$hxClasses")["tink.web.proxy._Remote.QueryParams_Impl_"] = 
class QueryParams_Impl_ {
	static toString(this1) {
		if (this1 == null) {
			return "";
		} else if (this1.length == 0) {
			return "";
		} else {
			let this2 = [];
			let ret = this2;
			let _g = 0;
			while (_g < this1.length) {
				let p = this1[_g];
				++_g;
				ret.push(p.name + "=" + p.value);
			};
			return "?" + ((ret == null) ? "null" : ret.join("&"));
		};
	}
	static get __name__() {
		return "tink.web.proxy._Remote.QueryParams_Impl_"
	}
	get __class__() {
		return QueryParams_Impl_
	}
}


export const RemoteBase = Register.global("$hxClasses")["tink.web.proxy.RemoteBase"] = 
class RemoteBase extends Register.inherits() {
	new(client, endpoint) {
		this.client = client;
		this.endpoint = endpoint;
	}
	static get __name__() {
		return "tink.web.proxy.RemoteBase"
	}
	get __class__() {
		return RemoteBase
	}
}

