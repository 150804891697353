import {BasicWriter} from "./Writer"
import {Register} from "../../genes/Register"

export const Writer3 = Register.global("$hxClasses")["tink.json.Writer3"] = 
class Writer3 extends Register.inherits(BasicWriter) {
	new() {
		super.new();
	}
	process0(value) {
		let __first = true;
		this.buf += String.fromCodePoint(123);
		let _g = value._10;
		if (_g != null) {
			let v = _g;
			if (__first) {
				__first = false;
			} else {
				this.buf += String.fromCodePoint(44);
			};
			this.buf += "\"text\":";
			let value1 = value._10;
			if (value1 == null) {
				this.buf += "null";
			} else {
				let s = JSON.stringify(value1);
				this.buf += s;
			};
		};
		let _g1 = value._9;
		if (_g1 != null) {
			let v = _g1;
			if (__first) {
				__first = false;
			} else {
				this.buf += String.fromCodePoint(44);
			};
			this.buf += "\"attachments\":";
			let value1 = value._9;
			if (value1 == null) {
				this.buf += "null";
			} else {
				this.buf += String.fromCodePoint(91);
				let first = true;
				let _g = 0;
				while (_g < value1.length) {
					let value = value1[_g];
					++_g;
					if (first) {
						first = false;
					} else {
						this.buf += String.fromCodePoint(44);
					};
					let s = JSON.stringify(value);
					this.buf += s;
				};
				this.buf += String.fromCodePoint(93);
			};
		};
		this.buf += String.fromCodePoint(125);
	}
	write(value) {
		this.init();
		this.process0(value);
		return this.buf.toString();
	}
	static get __name__() {
		return "tink.json.Writer3"
	}
	static get __super__() {
		return BasicWriter
	}
	get __class__() {
		return Writer3
	}
}

