import {Remote17} from "./Remote17"
import {Remote14} from "./Remote14"
import {Remote1} from "./Remote1"
import {RemoteBase, RemoteEndpoint_Impl_} from "./Remote"
import {Register} from "../../../genes/Register"

export const Remote0 = Register.global("$hxClasses")["tink.web.proxy.Remote0"] = 
class Remote0 extends Register.inherits(RemoteBase) {
	new(client, endpoint) {
		super.new(client, endpoint);
	}
	me() {
		let this1 = "me";
		return new Remote1(this.client, RemoteEndpoint_Impl_.sub(this.endpoint, {"path": [this1], "query": [], "headers": [].concat([])}));
	}
	products() {
		let this1 = "products";
		return new Remote14(this.client, RemoteEndpoint_Impl_.sub(this.endpoint, {"path": [this1], "query": [], "headers": [].concat([])}));
	}
	configs() {
		let this1 = "configs";
		return new Remote17(this.client, RemoteEndpoint_Impl_.sub(this.endpoint, {"path": [this1], "query": [], "headers": [].concat([])}));
	}
	static get __name__() {
		return "tink.web.proxy.Remote0"
	}
	static get __super__() {
		return RemoteBase
	}
	get __class__() {
		return Remote0
	}
}

