import {Uploader} from "../../../why/fs/Uploader"
import {AutoObservable, Computation_Impl_} from "../../../tink/state/internal/AutoObservable"
import {State_Impl_} from "../../../tink/state/State"
import {ConstObservable, Observable_Impl_, Transform_Impl_} from "../../../tink/state/Observable"
import {BlobSource} from "../../../tink/io/js/BlobSource"
import {RealSourceTools, Source_Impl_} from "../../../tink/io/Source"
import {Signal_Impl_} from "../../../tink/core/Signal"
import {Promise_Impl_} from "../../../tink/core/Promise"
import {Progress_Impl_} from "../../../tink/core/Progress"
import {Outcome} from "../../../tink/core/Outcome"
import {LazyConst} from "../../../tink/core/Lazy"
import {SyncFuture} from "../../../tink/core/Future"
import {Option} from "../../../haxe/ds/Option"
import {Register} from "../../../genes/Register"
import {Annex} from "../../../coconut/data/helpers/Annex"
import {Model} from "../../../coconut/data/Model"
import {Lambda} from "../../../Lambda"

export const ProductModel = Register.global("$hxClasses")["letzbig.app.data.ProductModel"] = 
class ProductModel extends Register.inherits() {
	new(__coco_init) {
		let _gthis = this;
		this.id = __coco_init.id;
		this.remote = __coco_init.remote;
		let before = AutoObservable.cur;
		AutoObservable.cur = null;
		let ret = new AutoObservable(Computation_Impl_.async(function () {
			return _gthis.remote.products().ofId(_gthis.id).get({"lang": "en"});
		}), null, null);
		AutoObservable.cur = before;
		this.__coco_data = ret;
		this.__coco_transitionCount = State_Impl_._new(0);
		this.errorTrigger = Signal_Impl_.trigger();
		this.transitionErrors = this.errorTrigger;
		this.annex = new Annex(this);
		this.observables = {"id": new ConstObservable(this.id, null), "remote": new ConstObservable(this.remote, null), "data": this.__coco_data, "isInTransition": Observable_Impl_.map(this.__coco_transitionCount, Transform_Impl_.plain(function (count) {
			return count > 0;
		}))};
	}
	createFeedback(text, attachments) {
		console.log("src/letzbig/app/data/ProductModel.hx:17:","createFeedback");
		let _gthis = this;
		return Progress_Impl_.make(function (progress, finish) {
			console.log("src/letzbig/app/data/ProductModel.hx:19:","run progress");
			let total = Option.Some(Lambda.fold(attachments, function (a, sum) {
				return sum + a.blob.size;
			}, 0.));
			let _g = [];
			let _g1 = 0;
			let _g2 = attachments.length;
			while (_g1 < _g2) {
				let _ = _g1++;
				_g.push(0.);
			};
			let uploaded = _g;
			let _g3 = [];
			let _g4 = 0;
			let _g5 = attachments.length;
			while (_g4 < _g5) {
				let i = _g4++;
				let attachment = attachments[i];
				_g3.push(Promise_Impl_.next(_gthis.remote.me().uploads().create(attachment.blob.type), function (req) {
					let options = null;
					let chunkSize = (options == null || options.chunkSize == null) ? 16777216 : options.chunkSize;
					let upload = Uploader.upload(req.header, RealSourceTools.idealize(new BlobSource(attachment.blob.type + ":" + attachment.blob.size, attachment.blob, 0, chunkSize), function (_) {
						return Source_Impl_.EMPTY;
					}));
					upload.progressed.listen(function (v) {
						uploaded[i] = v.a;
						progress(Lambda.fold(uploaded, function (k, sum) {
							return sum + k;
						}, 0.), total);
					});
					let v = req.path;
					return Promise_Impl_.next(upload.result, function (_) {
						return new SyncFuture(new LazyConst(Outcome.Success(v)));
					});
				}));
			};
			return Promise_Impl_.noise(Promise_Impl_.next(Promise_Impl_.inParallel(_g3), function (paths) {
				console.log("src/letzbig/app/data/ProductModel.hx:37:","uploaded");
				return _gthis.remote.products().ofId(_gthis.id).feedbacks().create({"text": text, "attachments": paths});
			})).handle(finish);
		});
	}
	static get __name__() {
		return "letzbig.app.data.ProductModel"
	}
	static get __interfaces__() {
		return [Model]
	}
	get __class__() {
		return ProductModel
	}
}

