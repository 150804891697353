import {Register} from "./genes/Register"

/**
The Haxe Reflection API allows retrieval of type information at runtime.

This class complements the more lightweight Reflect class, with a focus on
class and enum instances.

@see https://haxe.org/manual/types.html
@see https://haxe.org/manual/std-reflection.html
*/
export const Type = Register.global("$hxClasses")["Type"] = 
class Type {
	
	/**
	Returns a list of the constructor arguments of enum instance `e`.
	
	If `e` has no arguments, the result is [].
	
	Otherwise the result are the values that were used as arguments to `e`,
	in the order of their declaration.
	
	If `e` is null, the result is unspecified.
	*/
	static enumParameters(e) {
		let enm = Register.global("$hxEnums")[e.__enum__];
		let ctorName = enm.__constructs__[e._hx_index];
		let params = enm[ctorName].__params__;
		if (params != null) {
			let _g = [];
			let _g1 = 0;
			while (_g1 < params.length) {
				let p = params[_g1];
				++_g1;
				_g.push(e[p]);
			};
			return _g;
		} else {
			return [];
		};
	}
	static get __name__() {
		return "Type"
	}
	get __class__() {
		return Type
	}
}

