import {Portion_Impl_} from "./Portion"
import {Register} from "../../genes/Register"

export const PortionArray_Impl_ = Register.global("$hxClasses")["tink.url._PortionArray.PortionArray_Impl_"] = 
class PortionArray_Impl_ {
	static toStringArray(this1) {
		let _g = [];
		let _g1 = 0;
		while (_g1 < this1.length) {
			let p = this1[_g1];
			++_g1;
			_g.push(Portion_Impl_.toString(p));
		};
		return _g;
	}
	static get __name__() {
		return "tink.url._PortionArray.PortionArray_Impl_"
	}
	get __class__() {
		return PortionArray_Impl_
	}
}

