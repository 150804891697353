import {Register} from "./genes/Register"

export const HxOverrides = Register.global("$hxClasses")["HxOverrides"] = 
class HxOverrides {
	static dateStr(date) {
		let m = date.getMonth() + 1;
		let d = date.getDate();
		let h = date.getHours();
		let mi = date.getMinutes();
		let s = date.getSeconds();
		return date.getFullYear() + "-" + ((m < 10) ? "0" + m : "" + m) + "-" + ((d < 10) ? "0" + d : "" + d) + " " + ((h < 10) ? "0" + h : "" + h) + ":" + ((mi < 10) ? "0" + mi : "" + mi) + ":" + ((s < 10) ? "0" + s : "" + s);
	}
	static cca(s, index) {
		let x = s.charCodeAt(index);
		if (x != x) {
			return undefined;
		};
		return x;
	}
	static substr(s, pos, len = null) {
		if (len == null) {
			len = s.length;
		} else if (len < 0) {
			if (pos == 0) {
				len = s.length + len;
			} else {
				return "";
			};
		};
		return s.substr(pos, len);
	}
	static now() {
		return Date.now();
	}
	static get __name__() {
		return "HxOverrides"
	}
	get __class__() {
		return HxOverrides
	}
}


;((typeof(performance) != "undefined") ? typeof(performance.now) == "function" : false) ? HxOverrides.now = performance.now.bind(performance) : null
