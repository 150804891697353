import * as ReactDOM from "react-dom"
import {Register} from "../../genes/Register"

export const Renderer = Register.global("$hxClasses")["coconut.react.Renderer"] = 
class Renderer {
	static mountInto(target, vdom) {
		ReactDOM.render(vdom, target);
	}
	static get __name__() {
		return "coconut.react.Renderer"
	}
	get __class__() {
		return Renderer
	}
}

