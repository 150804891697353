import {Status} from "./Status"
import {User, DelegateBase} from "../Delegate"
import {State_Impl_} from "../../tink/state/State"
import {Promise_Impl_} from "../../tink/core/Promise"
import {Outcome} from "../../tink/core/Outcome"
import {LazyConst} from "../../tink/core/Lazy"
import {Future_Impl_, SyncFuture} from "../../tink/core/Future"
import {StringMap} from "../../haxe/ds/StringMap"
import {Register} from "../../genes/Register"
import Amplify from "@aws-amplify/core"
import {Hub} from "@aws-amplify/core"
import Auth from "@aws-amplify/auth"

export const AmplifyUser = Register.global("$hxClasses")["why.auth.AmplifyUser"] = 
class AmplifyUser extends Register.inherits() {
	new(user, attrs) {
		this.user = user;
		this.profile = State_Impl_._new(attrs);
	}
	getToken() {
		return Promise_Impl_.next(Future_Impl_.ofJsPromise(Auth.userSession(this.user)), function (session) {
			return new SyncFuture(new LazyConst(Outcome.Success(session.idToken.jwtToken)));
		});
	}
	static get __name__() {
		return "why.auth.AmplifyUser"
	}
	static get __interfaces__() {
		return [User]
	}
	get __class__() {
		return AmplifyUser
	}
}


/**
* Setup:
* Call `AmplifyDelegate.configure` before accessing `AmplifyDelegate.instance`
*/
export const AmplifyDelegate = Register.global("$hxClasses")["why.auth.AmplifyDelegate"] = 
class AmplifyDelegate extends Register.inherits(DelegateBase) {
	new() {
		let state = State_Impl_._new(Status.Initializing);
		super.new(state);
		let update = function (init = false) {
			Future_Impl_.ofJsPromise(Auth.currentUserPoolUser()).handle(function (o) {
				switch (o._hx_index) {
					case 0:
						let _g = o.data;
						if (_g == null) {
							state.set(Status.SignedOut);
						} else {
							let user = _g;
							Future_Impl_.ofJsPromise(Auth.userAttributes(user)).handle(function (o) {
								let state1 = state;
								let update;
								switch (o._hx_index) {
									case 0:
										let attrs = o.data;
										let user1 = user;
										let _g = new StringMap();
										let _g1 = 0;
										while (_g1 < attrs.length) {
											let entry = attrs[_g1];
											++_g1;
											_g.inst.set(entry.Name, entry.Value);
										};
										update = Status.SignedIn(new AmplifyUser(user1, _g));
										break
									case 1:
										let e = o.failure;
										update = Status.Errored(e);
										break
									
								};
								state1.set(update);
							});
						};
						break
					case 1:
						let _g1 = o.failure;
						let e = _g1;
						if (e.data == "No current user") {
							state.set(Status.SignedOut);
						} else {
							let e = _g1;
							state.set(Status.Errored(e));
						};
						break
					
				};
			});
		};
		Hub.listen("auth", function (capsule) {
			let _g = capsule.payload.event;
			switch (_g) {
				case "configured":case "signIn":
					let v = _g;
					update();
					break
				case "signOut":
					state.set(Status.SignedOut);
					break
				
			};
		});
	}
	signUp(info) {
		return Promise_Impl_.noise(Future_Impl_.ofJsPromise(Auth.signUp(info)));
	}
	signIn(credentials) {
		return Promise_Impl_.next(Future_Impl_.ofJsPromise(Auth.signIn(credentials.username, credentials.password)), function (user) {
			if (user.challengeName == "NEW_PASSWORD_REQUIRED") {
				return Promise_Impl_.noise(Future_Impl_.ofJsPromise(Auth.completeNewPassword(user, credentials.password)));
			} else {
				return Promise_Impl_.NOISE;
			};
		});
	}
	signOut() {
		return Promise_Impl_.noise(Future_Impl_.ofJsPromise(Auth.signOut()));
	}
	static configure(config) {
		Amplify.configure({"Auth": {"mandatorySignIn": true, "region": config.region, "userPoolId": config.userPoolId, "userPoolWebClientId": config.appClientId, "identityPoolId": config.identityPoolId}});
	}
	static get __name__() {
		return "why.auth.AmplifyDelegate"
	}
	static get __super__() {
		return DelegateBase
	}
	get __class__() {
		return AmplifyDelegate
	}
}


AmplifyDelegate.instance = new AmplifyDelegate()