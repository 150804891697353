import {HeaderField} from "../http/Header"
import {Register} from "../../genes/Register"

export const Builder7 = Register.global("$hxClasses")["tink.querystring.Builder7"] = 
class Builder7 extends Register.inherits() {
	new() {
	}
	stringify(data) {
		let prefix = "";
		let this1 = [];
		let buffer = this1;
		this.process0(prefix, buffer, data);
		return buffer;
	}
	process0(prefix, buffer, data) {
		let prefix1;
		if (prefix == "") {
			prefix1 = "accept-language";
		} else {
			let v = prefix;
			prefix1 = v + ".accept-language";
		};
		let data1 = data._8;
		let this1 = prefix1.toLowerCase();
		buffer.push(new HeaderField(this1, data1));
	}
	static get __name__() {
		return "tink.querystring.Builder7"
	}
	get __class__() {
		return Builder7
	}
}

