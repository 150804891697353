import {Revision_Impl_} from "./Revision"
import {CallbackList, ListCell, Callback_Impl_, SimpleLink, LinkPair} from "../../core/Callback"
import {Register} from "../../../genes/Register"

export const Invalidatable = {}

export const Invalidator = Register.global("$hxClasses")["tink.state.internal.Invalidator"] = 
class Invalidator extends Register.inherits() {
	new(toString = null) {
		this.list = new CallbackList();
		this.observers = new Map();
		this.revision = Revision_Impl_._new();
	}
	getRevision() {
		return this.revision;
	}
	onInvalidate(i) {
		let _gthis = this;
		if (this.observers.get(i)) {
			return null;
		} else {
			this.observers.set(i, true);
			let _this = this.list;
			let this1;
			if (_this.disposeHandlers == null) {
				this1 = null;
			} else {
				let node = new ListCell(function (_) {
					i.invalidate();
				}, _this);
				_this.cells.push(node);
				if (_this.used++ == 0) {
					let fn = _this.onfill;
					if (Callback_Impl_.depth < 500) {
						Callback_Impl_.depth++;
						fn();
						Callback_Impl_.depth--;
					} else {
						Callback_Impl_.defer(fn);
					};
				};
				this1 = node;
			};
			let this2 = new SimpleLink(function () {
				return _gthis.observers["delete"](i);
			});
			return new LinkPair(this1, this2);
		};
	}
	fire() {
		this.revision = Revision_Impl_._new();
		this.list.invoke(null);
	}
	static get __name__() {
		return "tink.state.internal.Invalidator"
	}
	get __class__() {
		return Invalidator
	}
}

