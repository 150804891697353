import {Message} from "./Message"
import {Header, HeaderField, HeaderValue_Impl_} from "./Header"
import {Url_Impl_} from "../Url"
import {Option} from "../../haxe/ds/Option"
import {Register} from "../../genes/Register"

export const RequestHeader = Register.global("$hxClasses")["tink.http.RequestHeader"] = 
class RequestHeader extends Register.inherits(Header) {
	new(method, url, protocol = "HTTP/1.1", fields) {
		this.method = method;
		this.url = url;
		this.protocol = protocol;
		super.new(fields);
	}
	static get __name__() {
		return "tink.http.RequestHeader"
	}
	static get __super__() {
		return Header
	}
	get __class__() {
		return RequestHeader
	}
}


export const IncomingRequestHeader = Register.global("$hxClasses")["tink.http.IncomingRequestHeader"] = 
class IncomingRequestHeader extends Register.inherits(RequestHeader) {
	new(method, url, protocol = null, fields) {
		super.new(method, url, protocol, fields);
	}
	static get __name__() {
		return "tink.http.IncomingRequestHeader"
	}
	static get __super__() {
		return RequestHeader
	}
	get __class__() {
		return IncomingRequestHeader
	}
}


export const OutgoingRequestHeader = Register.global("$hxClasses")["tink.http.OutgoingRequestHeader"] = 
class OutgoingRequestHeader extends Register.inherits(RequestHeader) {
	new(method, url, protocol = "HTTP/1.1", fields) {
		let _g = OutgoingRequestHeader.extractAuth(url);
		if (_g._hx_index == 0) {
			let v = _g.v;
			url = v.url;
			fields = fields.concat(v.headers);
		};
		super.new(method, url, protocol, fields);
	}
	concat(fields) {
		return new OutgoingRequestHeader(this.method, this.url, this.protocol, this.fields.concat(fields));
	}
	static extractAuth(url) {
		let _g = url.auth;
		if (_g == null) {
			return Option.None;
		} else {
			let v = _g;
			let tmp = [new HeaderField("authorization", HeaderValue_Impl_.basicAuth((v == null) ? null : v.split(":")[0], (v == null) ? null : v.split(":")[1]))];
			let url1 = url.scheme;
			let _g1 = [];
			let host = Register.iter(url.hosts);
			while (host.hasNext()) {
				let host1 = host.next();
				_g1.push(host1);
			};
			return Option.Some({"headers": tmp, "url": Url_Impl_.make({"scheme": url1, "hosts": _g1, "path": url.path, "query": url.query})});
		};
	}
	static get __name__() {
		return "tink.http.OutgoingRequestHeader"
	}
	static get __super__() {
		return RequestHeader
	}
	get __class__() {
		return OutgoingRequestHeader
	}
}


export const OutgoingRequest = Register.global("$hxClasses")["tink.http.OutgoingRequest"] = 
class OutgoingRequest extends Register.inherits(Message) {
	new(header, body) {
		super.new(header, body);
	}
	static get __name__() {
		return "tink.http.OutgoingRequest"
	}
	static get __super__() {
		return Message
	}
	get __class__() {
		return OutgoingRequest
	}
}


export const IncomingRequest = Register.global("$hxClasses")["tink.http.IncomingRequest"] = 
class IncomingRequest extends Register.inherits(Message) {
	new(clientIp, header, body) {
		this.clientIp = clientIp;
		super.new(header, body);
	}
	static get __name__() {
		return "tink.http.IncomingRequest"
	}
	static get __super__() {
		return Message
	}
	get __class__() {
		return IncomingRequest
	}
}


export const IncomingRequestBody = 
Register.global("$hxEnums")["tink.http.IncomingRequestBody"] = 
{
	__ename__: "tink.http.IncomingRequestBody",
	
	Plain: Object.assign((source) => ({_hx_index: 0, __enum__: "tink.http.IncomingRequestBody", source}), {_hx_name: "Plain", __params__: ["source"]}),
	Parsed: Object.assign((parts) => ({_hx_index: 1, __enum__: "tink.http.IncomingRequestBody", parts}), {_hx_name: "Parsed", __params__: ["parts"]})
}
IncomingRequestBody.__constructs__ = ["Plain", "Parsed"]
IncomingRequestBody.__empty_constructs__ = []
