import {IdealStreamBase} from "./IdealStream"
import {LazyConst, Lazy_Impl_} from "../core/Lazy"
import {Future_Impl_, SyncFuture} from "../core/Future"
import {Exception} from "../../haxe/Exception"
import {Register} from "../../genes/Register"

export const StreamObject = {}

export const StreamBase = Register.global("$hxClasses")["tink.streams.StreamBase"] = 
class StreamBase extends Register.inherits() {
	new() {
	}
	get depleted() {
		return this.get_depleted()
	}
	get_depleted() {
		return false;
	}
	idealize(rescue) {
		if (this.get_depleted()) {
			return Empty.inst;
		} else {
			return new IdealizeStream(this, rescue);
		};
	}
	reduce(initial, reducer) {
		let _gthis = this;
		return Future_Impl_.async(function (cb) {
			_gthis.forEach(Handler_Impl_.ofUnknown(function (item) {
				return Future_Impl_.map(reducer(initial, item), function (o) {
					switch (o._hx_index) {
						case 0:
							let v = o.result;
							initial = v;
							return Handled.Resume;
							break
						case 1:
							let e = o.e;
							return Handled.Clog(e);
							break
						
					};
				});
			})).handle(function (c) {
				switch (c._hx_index) {
					case 0:
						let _g = c.rest;
						throw Exception.thrown("assert");
						break
					case 1:
						let rest = c.at;
						let e = c.error;
						cb(Reduction.Crashed(e, rest));
						break
					case 2:
						let e1 = c.error;
						cb(Reduction.Failed(e1));
						break
					case 3:
						cb(Reduction.Reduced(initial));
						break
					
				};
			});
		}, true);
	}
	forEach(handler) {
		throw Exception.thrown("not implemented");
	}
	static get __name__() {
		return "tink.streams.StreamBase"
	}
	static get __interfaces__() {
		return [StreamObject]
	}
	get __class__() {
		return StreamBase
	}
}


export const Empty = Register.global("$hxClasses")["tink.streams.Empty"] = 
class Empty extends Register.inherits(() => StreamBase, true) {
	new() {
		super.new();
	}
	get_depleted() {
		return true;
	}
	forEach(handler) {
		return new SyncFuture(new LazyConst(Conclusion.Depleted));
	}
	static get __name__() {
		return "tink.streams.Empty"
	}
	static get __super__() {
		return StreamBase
	}
	get __class__() {
		return Empty
	}
}


Register.createStatic(Empty, "inst", function () { return new Empty() })
export const Generator = Register.global("$hxClasses")["tink.streams.Generator"] = 
class Generator extends Register.inherits(() => StreamBase, true) {
	new(upcoming) {
		super.new();
		this.upcoming = upcoming;
	}
	forEach(handler) {
		let _gthis = this;
		return Future_Impl_.async(function (cb) {
			_gthis.upcoming.handle(function (e) {
				switch (e._hx_index) {
					case 0:
						let then = e.next;
						let v = e.value;
						handler(v).handle(function (s) {
							switch (s._hx_index) {
								case 0:
									cb(Conclusion.Halted(_gthis));
									break
								case 1:
									cb(Conclusion.Halted(then));
									break
								case 2:
									then.forEach(handler).handle(cb);
									break
								case 3:
									let e = s.e;
									cb(Conclusion.Clogged(e, _gthis));
									break
								
							};
						});
						break
					case 1:
						let e1 = e.e;
						cb(Conclusion.Failed(e1));
						break
					case 2:
						cb(Conclusion.Depleted);
						break
					
				};
			});
		}, true);
	}
	static get __name__() {
		return "tink.streams.Generator"
	}
	static get __super__() {
		return StreamBase
	}
	get __class__() {
		return Generator
	}
}


export const Handled = 
Register.global("$hxEnums")["tink.streams.Handled"] = 
{
	__ename__: "tink.streams.Handled",
	
	BackOff: {_hx_name: "BackOff", _hx_index: 0, __enum__: "tink.streams.Handled"},
	Finish: {_hx_name: "Finish", _hx_index: 1, __enum__: "tink.streams.Handled"},
	Resume: {_hx_name: "Resume", _hx_index: 2, __enum__: "tink.streams.Handled"},
	Clog: Object.assign((e) => ({_hx_index: 3, __enum__: "tink.streams.Handled", e}), {_hx_name: "Clog", __params__: ["e"]})
}
Handled.__constructs__ = ["BackOff", "Finish", "Resume", "Clog"]
Handled.__empty_constructs__ = [Handled.BackOff, Handled.Finish, Handled.Resume]

export const Conclusion = 
Register.global("$hxEnums")["tink.streams.Conclusion"] = 
{
	__ename__: "tink.streams.Conclusion",
	
	Halted: Object.assign((rest) => ({_hx_index: 0, __enum__: "tink.streams.Conclusion", rest}), {_hx_name: "Halted", __params__: ["rest"]}),
	Clogged: Object.assign((error, at) => ({_hx_index: 1, __enum__: "tink.streams.Conclusion", error, at}), {_hx_name: "Clogged", __params__: ["error", "at"]}),
	Failed: Object.assign((error) => ({_hx_index: 2, __enum__: "tink.streams.Conclusion", error}), {_hx_name: "Failed", __params__: ["error"]}),
	Depleted: {_hx_name: "Depleted", _hx_index: 3, __enum__: "tink.streams.Conclusion"}
}
Conclusion.__constructs__ = ["Halted", "Clogged", "Failed", "Depleted"]
Conclusion.__empty_constructs__ = [Conclusion.Depleted]

export const ReductionStep = 
Register.global("$hxEnums")["tink.streams.ReductionStep"] = 
{
	__ename__: "tink.streams.ReductionStep",
	
	Progress: Object.assign((result) => ({_hx_index: 0, __enum__: "tink.streams.ReductionStep", result}), {_hx_name: "Progress", __params__: ["result"]}),
	Crash: Object.assign((e) => ({_hx_index: 1, __enum__: "tink.streams.ReductionStep", e}), {_hx_name: "Crash", __params__: ["e"]})
}
ReductionStep.__constructs__ = ["Progress", "Crash"]
ReductionStep.__empty_constructs__ = []

export const Reduction = 
Register.global("$hxEnums")["tink.streams.Reduction"] = 
{
	__ename__: "tink.streams.Reduction",
	
	Crashed: Object.assign((error, at) => ({_hx_index: 0, __enum__: "tink.streams.Reduction", error, at}), {_hx_name: "Crashed", __params__: ["error", "at"]}),
	Failed: Object.assign((error) => ({_hx_index: 1, __enum__: "tink.streams.Reduction", error}), {_hx_name: "Failed", __params__: ["error"]}),
	Reduced: Object.assign((result) => ({_hx_index: 2, __enum__: "tink.streams.Reduction", result}), {_hx_name: "Reduced", __params__: ["result"]})
}
Reduction.__constructs__ = ["Crashed", "Failed", "Reduced"]
Reduction.__empty_constructs__ = []

export const IdealizeStream = Register.global("$hxClasses")["tink.streams.IdealizeStream"] = 
class IdealizeStream extends Register.inherits(() => IdealStreamBase, true) {
	new(target, rescue) {
		super.new();
		this.target = target;
		this.rescue = rescue;
	}
	get_depleted() {
		return this.target.get_depleted();
	}
	forEach(handler) {
		let _gthis = this;
		return Future_Impl_.async(function (cb) {
			_gthis.target.forEach(handler).handle(function (end) {
				switch (end._hx_index) {
					case 0:
						let rest = end.rest;
						cb(Conclusion.Halted(rest.idealize(_gthis.rescue)));
						break
					case 1:
						let at = end.at;
						let e = end.error;
						cb(Conclusion.Clogged(e, at.idealize(_gthis.rescue)));
						break
					case 2:
						let e1 = end.error;
						_gthis.rescue(e1).idealize(_gthis.rescue).forEach(handler).handle(cb);
						break
					case 3:
						cb(Conclusion.Depleted);
						break
					
				};
			});
		});
	}
	static get __name__() {
		return "tink.streams.IdealizeStream"
	}
	static get __super__() {
		return IdealStreamBase
	}
	get __class__() {
		return IdealizeStream
	}
}


export const Single = Register.global("$hxClasses")["tink.streams.Single"] = 
class Single extends Register.inherits(() => StreamBase, true) {
	new(value) {
		super.new();
		this.value = value;
	}
	forEach(handle) {
		let _gthis = this;
		return Future_Impl_.map(handle(Lazy_Impl_.get(this.value)), function (step) {
			switch (step._hx_index) {
				case 0:
					return Conclusion.Halted(_gthis);
					break
				case 1:
					return Conclusion.Halted(Empty.inst);
					break
				case 2:
					return Conclusion.Depleted;
					break
				case 3:
					let e = step.e;
					return Conclusion.Clogged(e, _gthis);
					break
				
			};
		});
	}
	static get __name__() {
		return "tink.streams.Single"
	}
	static get __super__() {
		return StreamBase
	}
	get __class__() {
		return Single
	}
}


export const Handler_Impl_ = Register.global("$hxClasses")["tink.streams._Stream.Handler_Impl_"] = 
class Handler_Impl_ {
	static ofUnknown(f) {
		let this1 = f;
		return this1;
	}
	static get __name__() {
		return "tink.streams._Stream.Handler_Impl_"
	}
	get __class__() {
		return Handler_Impl_
	}
}


export const Reducer_Impl_ = Register.global("$hxClasses")["tink.streams._Stream.Reducer_Impl_"] = 
class Reducer_Impl_ {
	static ofSafe(f) {
		let this1 = f;
		return this1;
	}
	static get __name__() {
		return "tink.streams._Stream.Reducer_Impl_"
	}
	get __class__() {
		return Reducer_Impl_
	}
}


export const Step = 
Register.global("$hxEnums")["tink.streams.Step"] = 
{
	__ename__: "tink.streams.Step",
	
	Link: Object.assign((value, next) => ({_hx_index: 0, __enum__: "tink.streams.Step", value, next}), {_hx_name: "Link", __params__: ["value", "next"]}),
	Fail: Object.assign((e) => ({_hx_index: 1, __enum__: "tink.streams.Step", e}), {_hx_name: "Fail", __params__: ["e"]}),
	End: {_hx_name: "End", _hx_index: 2, __enum__: "tink.streams.Step"}
}
Step.__constructs__ = ["Link", "Fail", "End"]
Step.__empty_constructs__ = [Step.End]
