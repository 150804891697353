import {Register} from "../../genes/Register"

export const AttachmentType = 
Register.global("$hxEnums")["letzbig.types.AttachmentType"] = 
{
	__ename__: "letzbig.types.AttachmentType",
	
	Photo: {_hx_name: "Photo", _hx_index: 0, __enum__: "letzbig.types.AttachmentType"},
	Video: {_hx_name: "Video", _hx_index: 1, __enum__: "letzbig.types.AttachmentType"},
	Audio: {_hx_name: "Audio", _hx_index: 2, __enum__: "letzbig.types.AttachmentType"}
}
AttachmentType.__constructs__ = ["Photo", "Video", "Audio"]
AttachmentType.__empty_constructs__ = [AttachmentType.Photo, AttachmentType.Video, AttachmentType.Audio]
