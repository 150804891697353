import {List_Impl_} from "../pure/List"
import {Writer0} from "./Writer0"
import {BasicParser, JsonString_Impl_} from "./Parser"
import {TypedError} from "../core/Error"
import {Exception} from "../../haxe/Exception"
import {Register} from "../../genes/Register"
import {Std} from "../../Std"

export const Parser12 = Register.global("$hxClasses")["tink.json.Parser12"] = 
class Parser12 extends Register.inherits(BasicParser) {
	new() {
		super.new();
	}
	process0() {
		let _gthis = this;
		let v_active = false;
		let hasv_active = false;
		let v_appreciation = null;
		let hasv_appreciation = false;
		let v_backgroundColor = null;
		let hasv_backgroundColor = false;
		let v_brand = null;
		let hasv_brand = false;
		let v_createDate = null;
		let hasv_createDate = false;
		let v_description = null;
		let hasv_description = false;
		let v_endDate = null;
		let hasv_endDate = false;
		let v_id = null;
		let hasv_id = false;
		let v_imageUrl = null;
		let hasv_imageUrl = false;
		let v_name = null;
		let hasv_name = false;
		let v_regions = null;
		let hasv_regions = false;
		let v_startDate = null;
		let hasv_startDate = false;
		let v_supplements = null;
		let hasv_supplements = false;
		let v_thumbnailUrl = null;
		let hasv_thumbnailUrl = false;
		let __start__ = this.pos;
		while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
		let tmp;
		if (this.max > this.pos && this.source.charCodeAt(this.pos) == 123) {
			this.pos += 1;
			while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
			tmp = true;
		} else {
			tmp = false;
		};
		if (!tmp) {
			this.die("Expected " + "{");
		};
		while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
		let tmp1;
		if (this.max > this.pos && this.source.charCodeAt(this.pos) == 125) {
			this.pos += 1;
			while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
			tmp1 = true;
		} else {
			tmp1 = false;
		};
		if (!tmp1) {
			while (true) {
				let __name__ = this.parseString();
				while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
				let tmp;
				if (this.max > this.pos && this.source.charCodeAt(this.pos) == 58) {
					this.pos += 1;
					while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
					tmp = true;
				} else {
					tmp = false;
				};
				if (!tmp) {
					this.die("Expected " + ":");
				};
				if ("thumbnailUrl".length == __name__.max - __name__.min && __name__.source.substring(__name__.min, __name__.max) == "thumbnailUrl") {
					v_thumbnailUrl = JsonString_Impl_.toString(this.parseString());
					hasv_thumbnailUrl = true;
				} else if ("supplements".length == __name__.max - __name__.min && __name__.source.substring(__name__.min, __name__.max) == "supplements") {
					let __start__ = this.pos;
					while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
					let v_supplements1;
					if (this.max > this.pos && this.source.charCodeAt(this.pos) == 91) {
						this.pos += 1;
						while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
						v_supplements1 = true;
					} else {
						v_supplements1 = false;
					};
					if (!v_supplements1) {
						this.die("Expected " + "[");
					};
					let __ret = [];
					while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
					let v_supplements2;
					if (this.max > this.pos && this.source.charCodeAt(this.pos) == 93) {
						this.pos += 1;
						while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
						v_supplements2 = true;
					} else {
						v_supplements2 = false;
					};
					if (!v_supplements2) {
						while (true) {
							__ret.push(this.process1());
							while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
							let v_supplements;
							if (this.max > this.pos && this.source.charCodeAt(this.pos) == 44) {
								this.pos += 1;
								while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
								v_supplements = true;
							} else {
								v_supplements = false;
							};
							if (!v_supplements) {
								break;
							};
						};
						while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
						let v_supplements;
						if (this.max > this.pos && this.source.charCodeAt(this.pos) == 93) {
							this.pos += 1;
							while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
							v_supplements = true;
						} else {
							v_supplements = false;
						};
						if (!v_supplements) {
							this.die("Expected " + "]");
						};
					};
					let rep = __ret;
					try {
						let this1 = rep;
						v_supplements = List_Impl_.ofRepresentation(this1);
					}catch (_g) {
						let e = Exception.caught(_g).unwrap();
						v_supplements = this.die(Std.string(e), __start__);
					};
					hasv_supplements = true;
				} else if ("startDate".length == __name__.max - __name__.min && __name__.source.substring(__name__.min, __name__.max) == "startDate") {
					let this1 = this.parseNumber();
					v_startDate = new Date(parseFloat(this1.source.substring(this1.min,this1.max)));
					hasv_startDate = true;
				} else if ("regions".length == __name__.max - __name__.min && __name__.source.substring(__name__.min, __name__.max) == "regions") {
					let __start__ = this.pos;
					while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
					let v_regions1;
					if (this.max > this.pos && this.source.charCodeAt(this.pos) == 91) {
						this.pos += 1;
						while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
						v_regions1 = true;
					} else {
						v_regions1 = false;
					};
					if (!v_regions1) {
						this.die("Expected " + "[");
					};
					let __ret = [];
					while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
					let v_regions2;
					if (this.max > this.pos && this.source.charCodeAt(this.pos) == 93) {
						this.pos += 1;
						while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
						v_regions2 = true;
					} else {
						v_regions2 = false;
					};
					if (!v_regions2) {
						while (true) {
							let v = JsonString_Impl_.toString(this.parseString());
							let v_regions;
							switch (v) {
								case "ae":case "at":case "au":case "be":case "bn":case "br":case "ca":case "cn":case "de":case "dk":case "eg":case "es":case "fi":case "fr":case "gr":case "hk":case "id":case "il":case "in":case "ir":case "is":case "it":case "jp":case "ko":case "lu":case "ml":case "mo":case "mx":case "nl":case "no":case "nz":case "ph":case "qa":case "ru":case "sa":case "se":case "sg":case "sw":case "th":case "tr":case "tw":case "uk":case "us":case "vn":case "xx":
									v_regions = v;
									break
								default:
								let list = ["hk", "au", "mo", "xx", "nz", "cn", "tw", "ml", "sg", "bn", "uk", "ir", "us", "ca", "sw", "ko", "jp", "th", "vn", "id", "ph", "sa", "de", "no", "se", "dk", "nl", "fi", "in", "ru", "fr", "it", "be", "es", "gr", "br", "mx", "eg", "tr", "at", "lu", "is", "qa", "ae", "il"];
								throw Exception.thrown(new TypedError(422, "Unrecognized enum value: " + ((v == null) ? "null" : v) + ". Accepted values are: " + new Writer0().write(list), {"fileName": "tink/json/macros/GenReader.hx", "lineNumber": 392, "className": "tink.json.Parser12", "methodName": "process0"}));
								
							};
							__ret.push(v_regions);
							while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
							let v_regions1;
							if (this.max > this.pos && this.source.charCodeAt(this.pos) == 44) {
								this.pos += 1;
								while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
								v_regions1 = true;
							} else {
								v_regions1 = false;
							};
							if (!v_regions1) {
								break;
							};
						};
						while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
						let v_regions;
						if (this.max > this.pos && this.source.charCodeAt(this.pos) == 93) {
							this.pos += 1;
							while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
							v_regions = true;
						} else {
							v_regions = false;
						};
						if (!v_regions) {
							this.die("Expected " + "]");
						};
					};
					let rep = __ret;
					try {
						let this1 = rep;
						v_regions = List_Impl_.ofRepresentation(this1);
					}catch (_g) {
						let e = Exception.caught(_g).unwrap();
						v_regions = this.die(Std.string(e), __start__);
					};
					hasv_regions = true;
				} else if ("name".length == __name__.max - __name__.min && __name__.source.substring(__name__.min, __name__.max) == "name") {
					v_name = JsonString_Impl_.toString(this.parseString());
					hasv_name = true;
				} else if ("imageUrl".length == __name__.max - __name__.min && __name__.source.substring(__name__.min, __name__.max) == "imageUrl") {
					v_imageUrl = JsonString_Impl_.toString(this.parseString());
					hasv_imageUrl = true;
				} else if ("id".length == __name__.max - __name__.min && __name__.source.substring(__name__.min, __name__.max) == "id") {
					v_id = JsonString_Impl_.toString(this.parseString());
					hasv_id = true;
				} else if ("endDate".length == __name__.max - __name__.min && __name__.source.substring(__name__.min, __name__.max) == "endDate") {
					while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
					let v_endDate1;
					if (this.max > this.pos + 3 && this.source.charCodeAt(this.pos) == 110 && this.source.charCodeAt(this.pos + 1) == 117 && this.source.charCodeAt(this.pos + 2) == 108 && this.source.charCodeAt(this.pos + 3) == 108) {
						this.pos += 4;
						while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
						v_endDate1 = true;
					} else {
						v_endDate1 = false;
					};
					if (v_endDate1) {
						v_endDate = null;
					} else {
						let this1 = this.parseNumber();
						v_endDate = new Date(parseFloat(this1.source.substring(this1.min,this1.max)));
					};
					hasv_endDate = true;
				} else if ("description".length == __name__.max - __name__.min && __name__.source.substring(__name__.min, __name__.max) == "description") {
					v_description = JsonString_Impl_.toString(this.parseString());
					hasv_description = true;
				} else if ("createDate".length == __name__.max - __name__.min && __name__.source.substring(__name__.min, __name__.max) == "createDate") {
					let this1 = this.parseNumber();
					v_createDate = new Date(parseFloat(this1.source.substring(this1.min,this1.max)));
					hasv_createDate = true;
				} else if ("brand".length == __name__.max - __name__.min && __name__.source.substring(__name__.min, __name__.max) == "brand") {
					v_brand = JsonString_Impl_.toString(this.parseString());
					hasv_brand = true;
				} else if ("backgroundColor".length == __name__.max - __name__.min && __name__.source.substring(__name__.min, __name__.max) == "backgroundColor") {
					v_backgroundColor = JsonString_Impl_.toString(this.parseString());
					hasv_backgroundColor = true;
				} else if ("appreciation".length == __name__.max - __name__.min && __name__.source.substring(__name__.min, __name__.max) == "appreciation") {
					v_appreciation = JsonString_Impl_.toString(this.parseString());
					hasv_appreciation = true;
				} else if ("active".length == __name__.max - __name__.min && __name__.source.substring(__name__.min, __name__.max) == "active") {
					v_active = this.parseBool();
					hasv_active = true;
				} else {
					this.skipValue();
				};
				while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
				let tmp1;
				if (this.max > this.pos && this.source.charCodeAt(this.pos) == 44) {
					this.pos += 1;
					while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
					tmp1 = true;
				} else {
					tmp1 = false;
				};
				if (!tmp1) {
					break;
				};
			};
			while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
			let tmp;
			if (this.max > this.pos && this.source.charCodeAt(this.pos) == 125) {
				this.pos += 1;
				while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
				tmp = true;
			} else {
				tmp = false;
			};
			if (!tmp) {
				this.die("Expected " + "}");
			};
		};
		let __missing__ = function (field) {
			return _gthis.die("missing field \"" + field + "\"", __start__);
		};
		return {"active": (hasv_active) ? v_active : __missing__("active"), "appreciation": (hasv_appreciation) ? v_appreciation : __missing__("appreciation"), "backgroundColor": (hasv_backgroundColor) ? v_backgroundColor : __missing__("backgroundColor"), "brand": (hasv_brand) ? v_brand : __missing__("brand"), "createDate": (hasv_createDate) ? v_createDate : __missing__("createDate"), "description": (hasv_description) ? v_description : __missing__("description"), "endDate": (hasv_endDate) ? v_endDate : __missing__("endDate"), "id": (hasv_id) ? v_id : __missing__("id"), "imageUrl": (hasv_imageUrl) ? v_imageUrl : __missing__("imageUrl"), "name": (hasv_name) ? v_name : __missing__("name"), "regions": (hasv_regions) ? v_regions : __missing__("regions"), "startDate": (hasv_startDate) ? v_startDate : __missing__("startDate"), "supplements": (hasv_supplements) ? v_supplements : __missing__("supplements"), "thumbnailUrl": (hasv_thumbnailUrl) ? v_thumbnailUrl : __missing__("thumbnailUrl")};
	}
	process1() {
		let _gthis = this;
		let v_active = false;
		let hasv_active = false;
		let v_createDate = null;
		let hasv_createDate = false;
		let v_id = null;
		let hasv_id = false;
		let v_name = null;
		let hasv_name = false;
		let v_url = null;
		let hasv_url = false;
		let __start__ = this.pos;
		while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
		let tmp;
		if (this.max > this.pos && this.source.charCodeAt(this.pos) == 123) {
			this.pos += 1;
			while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
			tmp = true;
		} else {
			tmp = false;
		};
		if (!tmp) {
			this.die("Expected " + "{");
		};
		while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
		let tmp1;
		if (this.max > this.pos && this.source.charCodeAt(this.pos) == 125) {
			this.pos += 1;
			while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
			tmp1 = true;
		} else {
			tmp1 = false;
		};
		if (!tmp1) {
			while (true) {
				let __name__ = this.parseString();
				while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
				let tmp;
				if (this.max > this.pos && this.source.charCodeAt(this.pos) == 58) {
					this.pos += 1;
					while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
					tmp = true;
				} else {
					tmp = false;
				};
				if (!tmp) {
					this.die("Expected " + ":");
				};
				if ("url".length == __name__.max - __name__.min && __name__.source.substring(__name__.min, __name__.max) == "url") {
					v_url = JsonString_Impl_.toString(this.parseString());
					hasv_url = true;
				} else if ("name".length == __name__.max - __name__.min && __name__.source.substring(__name__.min, __name__.max) == "name") {
					v_name = JsonString_Impl_.toString(this.parseString());
					hasv_name = true;
				} else if ("id".length == __name__.max - __name__.min && __name__.source.substring(__name__.min, __name__.max) == "id") {
					v_id = JsonString_Impl_.toString(this.parseString());
					hasv_id = true;
				} else if ("createDate".length == __name__.max - __name__.min && __name__.source.substring(__name__.min, __name__.max) == "createDate") {
					let this1 = this.parseNumber();
					v_createDate = new Date(parseFloat(this1.source.substring(this1.min,this1.max)));
					hasv_createDate = true;
				} else if ("active".length == __name__.max - __name__.min && __name__.source.substring(__name__.min, __name__.max) == "active") {
					v_active = this.parseBool();
					hasv_active = true;
				} else {
					this.skipValue();
				};
				while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
				let tmp1;
				if (this.max > this.pos && this.source.charCodeAt(this.pos) == 44) {
					this.pos += 1;
					while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
					tmp1 = true;
				} else {
					tmp1 = false;
				};
				if (!tmp1) {
					break;
				};
			};
			while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
			let tmp;
			if (this.max > this.pos && this.source.charCodeAt(this.pos) == 125) {
				this.pos += 1;
				while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
				tmp = true;
			} else {
				tmp = false;
			};
			if (!tmp) {
				this.die("Expected " + "}");
			};
		};
		let __missing__ = function (field) {
			return _gthis.die("missing field \"" + field + "\"", __start__);
		};
		return {"active": (hasv_active) ? v_active : __missing__("active"), "createDate": (hasv_createDate) ? v_createDate : __missing__("createDate"), "id": (hasv_id) ? v_id : __missing__("id"), "name": (hasv_name) ? v_name : __missing__("name"), "url": (hasv_url) ? v_url : __missing__("url")};
	}
	parse(source) {
		let _gthis = this;
		if (_gthis.afterParsing.length > 0) {
			_gthis.afterParsing = [];
		};
		this.init(source);
		let ret = this.process0();
		let _g = 0;
		let _g1 = this.afterParsing;
		while (_g < _g1.length) {
			let f = _g1[_g];
			++_g;
			f();
		};
		if (_gthis.afterParsing.length > 0) {
			_gthis.afterParsing = [];
		};
		return ret;
	}
	tryParse(source) {
		let _gthis = this;
		return TypedError.catchExceptions(function () {
			let ret = _gthis.parse(source);
			while (_gthis.pos < _gthis.max && _gthis.source.charCodeAt(_gthis.pos) < 33) _gthis.pos++;
			if (_gthis.pos < _gthis.max) {
				_gthis.die("Invalid data after JSON document");
			};
			return ret;
		}, null, {"fileName": "tink/json/macros/Macro.hx", "lineNumber": 107, "className": "tink.json.Parser12", "methodName": "tryParse"});
	}
	static get __name__() {
		return "tink.json.Parser12"
	}
	static get __super__() {
		return BasicParser
	}
	get __class__() {
		return Parser12
	}
}

