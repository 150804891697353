import {Register} from "../../../genes/Register"

export const Revision_Impl_ = Register.global("$hxClasses")["tink.state.internal._Revision.Revision_Impl_"] = 
class Revision_Impl_ {
	static _new() {
		let this1 = Revision_Impl_;
		let this2 = this1.counter += 1.0;
		return this2;
	}
	static get __name__() {
		return "tink.state.internal._Revision.Revision_Impl_"
	}
	get __class__() {
		return Revision_Impl_
	}
}


;Revision_Impl_.counter = .0
