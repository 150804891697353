import {Invalidatable} from "../../tink/state/internal/Invalidatable"
import {AutoObservable, Computation_Impl_} from "../../tink/state/internal/AutoObservable"
import {State_Impl_} from "../../tink/state/State"
import {Observable_Impl_} from "../../tink/state/Observable"
import {LazyFunc, Lazy_Impl_} from "../../tink/core/Lazy"
import {Callback_Impl_} from "../../tink/core/Callback"
import React from "react"
import {Component} from "react"
import {Register} from "../../genes/Register"

export const ViewBase = Register.global("$hxClasses")["coconut.react.ViewBase"] = 
class ViewBase extends Register.inherits(Component) {
	new(rendered, mounted, updated, unmounting) {
		Component.call(this);
		this.state = this.__snap();
		this.__rendered = rendered;
		this.__viewMounted = mounted;
		this.__viewUpdated = updated;
		this.__viewUnmounting = unmounting;
	}
	__snap() {
		let _gthis = this;
		return {"vtree": new LazyFunc(function () {
			return Observable_Impl_.get_value(_gthis.__rendered);
		})};
	}
	componentDidMount() {
		let _gthis = this;
		this.__link = Observable_Impl_.bind(this.__rendered, function (_) {
			_gthis.setState(_gthis.__snap());
		});
		if (this.__viewMounted != null) {
			this.__viewMounted();
		};
	}
	componentDidUpdate(_, _1) {
		if (this.__viewUpdated != null) {
			this.__viewUpdated();
		};
	}
	componentWillUnmount() {
		let this1 = this.__link;
		if (this1 != null) {
			this1.cancel();
		};
		if (this.__viewUnmounting != null) {
			this.__viewUnmounting();
		};
	}
	shouldComponentUpdate(_, next) {
		return Lazy_Impl_.get(this.state.vtree) != Lazy_Impl_.get(next.vtree);
	}
	render() {
		let ret = Lazy_Impl_.get(this.state.vtree);
		if (typeof(ret) == "undefined") {
			return null;
		};
		return ret;
	}
	static get __name__() {
		return "coconut.react.ViewBase"
	}
	static get __super__() {
		return Component
	}
	get __class__() {
		return ViewBase
	}
}


;Object.defineProperty(ViewBase.prototype, "props", {"get": function () {
	return this.__props;
}, "set": function (attr) {
	if (attr != null) {
		this.__props = attr;
		if (this.__initAttributes) {
			this.__initAttributes(attr);
		};
	};
}})

export const View = Register.global("$hxClasses")["coconut.react.View"] = 
class View extends Register.inherits(ViewBase) {
	new(render, shouldUpdate, track, beforeRerender, rendered) {
		this.__au = [];
		this.__bc = [];
		this.__bu = [];
		let _gthis = this;
		let mounted;
		if (rendered != null) {
			let _g = rendered;
			let a1 = true;
			mounted = function () {
				_g(a1);
			};
		} else {
			mounted = null;
		};
		let updated;
		if (rendered != null) {
			let _g = rendered;
			let a1 = false;
			updated = function () {
				_g(a1);
			};
		} else {
			updated = null;
		};
		let firstTime = true;
		let last = null;
		let hasBeforeRerender = beforeRerender != null;
		let hasUpdated = updated != null;
		let _coco_revision = State_Impl_._new(0);
		let lastRev = State_Impl_.get_value(_coco_revision);
		let renderView = function () {
			let curRev = State_Impl_.get_value(_coco_revision);
			if (track != null) {
				track();
			};
			if (firstTime) {
				firstTime = false;
			} else {
				if (curRev == lastRev && shouldUpdate != null && !shouldUpdate()) {
					return last;
				};
				let hasCallbacks = _gthis.__bc.length > 0;
				if (hasBeforeRerender || hasCallbacks) {
					let before = AutoObservable.cur;
					AutoObservable.cur = null;
					if (hasBeforeRerender) {
						beforeRerender();
					};
					if (hasCallbacks) {
						let _g = 0;
						let _g1 = _gthis.__bc.splice(0, _gthis.__bc.length);
						while (_g < _g1.length) {
							let c = _g1[_g];
							++_g;
							Callback_Impl_.invoke(c, false);
						};
					};
					let ret = null;
					AutoObservable.cur = before;
				};
			};
			lastRev = curRev;
			last = render();
			return last;
		};
		super.new(new AutoObservable(Computation_Impl_.sync(renderView), null, null), mounted, function () {
			let hasCallbacks = _gthis.__au.length > 0;
			if (hasUpdated || hasCallbacks) {
				let before = AutoObservable.cur;
				AutoObservable.cur = null;
				if (hasUpdated) {
					updated();
				};
				if (hasCallbacks) {
					let _g = 0;
					let _g1 = _gthis.__au.splice(0, _gthis.__au.length);
					while (_g < _g1.length) {
						let c = _g1[_g];
						++_g;
						Callback_Impl_.invoke(c, null);
					};
				};
				let ret = null;
				AutoObservable.cur = before;
			};
		}, function () {
			last = null;
			firstTime = true;
			_gthis.__beforeUnmount();
		});
		this._coco_revision = _coco_revision;
	}
	__beforeUnmount() {
		let _g = 0;
		let _g1 = this.__bu.splice(0, this.__bu.length);
		while (_g < _g1.length) {
			let c = _g1[_g];
			++_g;
			if (c != null) {
				c.cancel();
			};
		};
		let _g2 = 0;
		let _g3 = this.__bc.splice(0, this.__bu.length);
		while (_g2 < _g3.length) {
			let c = _g3[_g2];
			++_g2;
			Callback_Impl_.invoke(c, true);
		};
	}
	static createFragment(attr, children) {
		let tmp = [React.Fragment, attr].concat(children);
		return React.createElement.apply(null, tmp);
	}
	static get __name__() {
		return "coconut.react.View"
	}
	static get __super__() {
		return ViewBase
	}
	get __class__() {
		return View
	}
}


View.TRE = (typeof Symbol === "function" && Symbol.for && Symbol.for("react.element")) || 0xeac7
export const Rewrapped = Register.global("$hxClasses")["coconut.react._View.Rewrapped"] = 
class Rewrapped extends Register.inherits(Component) {
	observable() {
		return this.props.target.__rendered;
	}
	componentDidMount() {
		this.props.target.componentDidMount();
		this.link = this.observable().onInvalidate(this);
	}
	componentDidUpdate(_, _1) {
		this.props.target.componentDidUpdate(null, null);
	}
	componentWillUnmount() {
		let this1 = this.link;
		if (this1 != null) {
			this1.cancel();
		};
		this.props.target.componentWillUnmount();
	}
	invalidate() {
		this.forceUpdate();
	}
	render() {
		return this.observable().getValue();
	}
	static get __name__() {
		return "coconut.react._View.Rewrapped"
	}
	static get __interfaces__() {
		return [Invalidatable]
	}
	static get __super__() {
		return Component
	}
	get __class__() {
		return Rewrapped
	}
}

