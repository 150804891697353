import {ToastObject} from "../Toast"
import {ReactType_Impl_} from "../../react/ReactType"
import React from "react"
import {Register} from "../../genes/Register"
import {Snackbar} from "@material-ui/core"

export const MaterialUiSnackbar = Register.global("$hxClasses")["why.toast.MaterialUiSnackbar"] = 
class MaterialUiSnackbar extends Register.inherits(React.Component) {
	new(props) {
		React.Component.call(this, props);
		this.state = {};
	}
	show(options) {
		let _g = options.duration;
		let tmp;
		switch (_g._hx_index) {
			case 0:
				tmp = 2000;
				break
			case 1:
				tmp = 5000;
				break
			case 2:
				let v = _g.ms;
				tmp = v;
				break
			case 3:
				tmp = null;
				break
			
		};
		this.setState({"open": true, "options": options, "duration": tmp});
	}
	render() {
		if (this.state.options == null) {
			return null;
		} else {
			let children = this.props.renderChildren(this.state.options);
			let isString = typeof(children) == "string";
			return React.createElement(ReactType_Impl_.fromComp(Snackbar), {"open": this.state.open, "autoHideDuration": this.state.duration, "onClose": Register.bind(this, this.onClose), "message": (isString) ? children : null, "anchorOrigin": this.props.anchorOrigin}, (isString) ? null : children);
		};
	}
	onClose(event, reason) {
		if (reason == "clickaway") {
			return;
		};
		this.setState({"open": false});
	}
	static fromHxx(props) {
		return React.createElement(ReactType_Impl_.fromComp(MaterialUiSnackbar), props);
	}
	static get __name__() {
		return "why.toast.MaterialUiSnackbar"
	}
	static get __interfaces__() {
		return [ToastObject]
	}
	static get __super__() {
		return React.Component
	}
	get __class__() {
		return MaterialUiSnackbar
	}
}

