import {AutoObservable, Computation_Impl_} from "../../tink/state/internal/AutoObservable"
import {Observable_Impl_} from "../../tink/state/Observable"
import {Url_Impl_} from "../../tink/Url"
import {Location} from "../../spectatory/Location"
import {Register} from "../../genes/Register"
import {Slot} from "../ui/internal/Slot"
import {ImplicitValues_Impl_, SingleImplicit, TypeKey_Impl_} from "../ui/internal/ImplicitContext"
import {View} from "../react/View"
import {Implicit} from "../react/Implicit"

export const History = Register.global("$hxClasses")["coconut.router.History"] = 
class History extends Register.inherits() {
	new(l2r, r2l) {
		this.urlToRoute = l2r;
		this.routeToUrl = r2l;
	}
	static get __name__() {
		return "coconut.router.History"
	}
	get __class__() {
		return History
	}
}


export const Router = Register.global("$hxClasses")["coconut.router.Router"] = 
class Router extends Register.inherits(View) {
	new(__coco_data_) {
		this.__coco_renderScreen = new Slot(this, null, null);
		this.__coco_urlToRoute = new Slot(this, null, null);
		this.__coco_routeToUrl = new Slot(this, null, null);
		let _gthis = this;
		this.__coco_current = new AutoObservable(Computation_Impl_.sync(function () {
			return _gthis.urlToRoute(Url_Impl_.fromString(Observable_Impl_.get_value(Location.href)));
		}), null, null);
		this.__initAttributes(__coco_data_);
		let snapshot = null;
		super.new(function () {
			return _gthis.__coco_render();
		}, null, null, null, null);
	}
	renderScreen(a0) {
		return (Observable_Impl_.get_value(this.__coco_renderScreen))(a0);
	}
	urlToRoute(a0) {
		return (Observable_Impl_.get_value(this.__coco_urlToRoute))(a0);
	}
	routeToUrl(a0) {
		return (Observable_Impl_.get_value(this.__coco_routeToUrl))(a0);
	}
	__coco_render() {
		let _gthis = this;
		return Implicit.fromHxx({"defaults": ImplicitValues_Impl_._new([new SingleImplicit(TypeKey_Impl_.ofClass(History), new AutoObservable(Computation_Impl_.sync(function () {
			return new History(Register.bind(_gthis, _gthis.urlToRoute), Register.bind(_gthis, _gthis.routeToUrl));
		}), null, null))]), "children": [this.renderScreen(Observable_Impl_.get_value(this.__coco_current))]});
	}
	__initAttributes(attributes) {
		this.__coco_renderScreen.setData(attributes.renderScreen);
		this.__coco_urlToRoute.setData(attributes.urlToRoute);
		this.__coco_routeToUrl.setData(attributes.routeToUrl);
	}
	static fromHxx(hxxMeta, attributes) {
		return {"$$typeof": View.TRE, "type": Router, "props": attributes, "key": hxxMeta.key, "ref": (!hxxMeta.ref) ? null : hxxMeta.ref};
	}
	static get __name__() {
		return "coconut.router.Router"
	}
	static get __super__() {
		return View
	}
	get __class__() {
		return Router
	}
}

