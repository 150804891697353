import {Status} from "../../../why/auth/Status"
import {User, DelegateObject} from "../../../why/Delegate"
import {Remote18} from "../../../tink/web/proxy/Remote18"
import {RemoteEndpoint_Impl_, Scheme_Impl_} from "../../../tink/web/proxy/Remote"
import {Host_Impl_} from "../../../tink/url/Host"
import {State_Impl_} from "../../../tink/state/State"
import {ConstObservable} from "../../../tink/state/Observable"
import {Fetch, ClientType} from "../../../tink/http/Fetch"
import {Promise_Impl_} from "../../../tink/core/Promise"
import {Outcome, OutcomeTools} from "../../../tink/core/Outcome"
import {LazyConst} from "../../../tink/core/Lazy"
import {SyncFuture} from "../../../tink/core/Future"
import {TypedError} from "../../../tink/core/Error"
import {Browser} from "../../../js/Browser"
import {Option} from "../../../haxe/ds/Option"
import {Register} from "../../../genes/Register"

export const GuestUser = Register.global("$hxClasses")["letzbig.api.auth._GuestDelegate.GuestUser"] = 
class GuestUser extends Register.inherits() {
	new(token) {
		this.profile = new ConstObservable({"id": token}, null);
		this.token = token;
	}
	getToken() {
		return new SyncFuture(new LazyConst(Outcome.Success(this.token)));
	}
	static get __name__() {
		return "letzbig.api.auth._GuestDelegate.GuestUser"
	}
	static get __interfaces__() {
		return [User]
	}
	get __class__() {
		return GuestUser
	}
}


export const GuestDelegate = Register.global("$hxClasses")["letzbig.api.auth.GuestDelegate"] = 
class GuestDelegate extends Register.inherits() {
	new() {
		this.remote = new Remote18(Fetch.getClient(ClientType.Default), RemoteEndpoint_Impl_.sub(RemoteEndpoint_Impl_._new(Host_Impl_._new("localhost:8880"), null, Scheme_Impl_.fromString("http")), {"path": []}));
		this.status = this.state = State_Impl_._new(Status.Initializing);
		this.profile = new ConstObservable(Option.None, null);
		let _g = Browser.getLocalStorage();
		let tmp;
		if (_g == null) {
			tmp = Outcome.Failure(new TypedError(null, "Browser local storage unsupported", {"fileName": "letzbig/api/auth/GuestDelegate.hx", "lineNumber": 33, "className": "letzbig.api.auth.GuestDelegate", "methodName": "new"}));
		} else {
			let s = _g;
			tmp = Outcome.Success(s);
		};
		this.storage = tmp;
		let _g1 = OutcomeTools.map(this.storage, function (s) {
			return s.getItem("letzbig_guest_token");
		});
		switch (_g1._hx_index) {
			case 0:
				let _g2 = _g1.data;
				if (_g2 == null) {
					this.state.set(Status.SignedOut);
				} else {
					let token = _g2;
					this.state.set(Status.SignedIn(new GuestUser(token)));
				};
				break
			case 1:
				let e = _g1.failure;
				this.state.set(Status.Errored(e));
				break
			
		};
	}
	signUp(deviceId) {
		let _gthis = this;
		return OutcomeTools.next(this.storage, function (s) {
			return Promise_Impl_.next(_gthis.create(deviceId), function (o) {
				s.setItem("letzbig_guest_token", o.token);
				_gthis.state.set(Status.SignedIn(new GuestUser(o.token)));
				return new SyncFuture(new LazyConst(Outcome.Success(null)));
			});
		});
	}
	signIn(_) {
		let _g = OutcomeTools.map(this.storage, function (s) {
			return s.getItem("letzbig_guest_token");
		});
		switch (_g._hx_index) {
			case 0:
				let _g1 = _g.data;
				if (_g1 == null) {
					return new SyncFuture(new LazyConst(Outcome.Failure(new TypedError(null, "Guest account not found", {"fileName": "letzbig/api/auth/GuestDelegate.hx", "lineNumber": 60, "className": "letzbig.api.auth.GuestDelegate", "methodName": "signIn"}))));
				} else {
					let token = _g1;
					this.state.set(Status.SignedIn(new GuestUser(token)));
					return new SyncFuture(new LazyConst(Outcome.Success(null)));
				};
				break
			case 1:
				let e = _g.failure;
				return new SyncFuture(new LazyConst(Outcome.Failure(e)));
				break
			
		};
	}
	signOut() {
		this.state.set(Status.SignedOut);
		return OutcomeTools.next(this.storage, function (s) {
			s.removeItem("letzbig_guest_token");
			return new SyncFuture(new LazyConst(Outcome.Success(null)));
		});
	}
	create(deviceId) {
		return Promise_Impl_.ofSpecific(Promise_Impl_.next(this.remote.users().create(Credentials.Guest(deviceId)), function (o) {
			return new SyncFuture(new LazyConst(Outcome.Success({"id": deviceId, "token": o.token})));
		}));
	}
	link(cognito) {
		return this.remote.me().link(cognito);
	}
	static get __name__() {
		return "letzbig.api.auth.GuestDelegate"
	}
	static get __interfaces__() {
		return [DelegateObject]
	}
	get __class__() {
		return GuestDelegate
	}
}


GuestDelegate.INST = new GuestDelegate()
export const OldApi = {}

export const UsersApi = {}

export const UserApi = {}

export const Credentials = 
Register.global("$hxEnums")["letzbig.api.auth._GuestDelegate.Credentials"] = 
{
	__ename__: "letzbig.api.auth._GuestDelegate.Credentials",
	
	Guest: Object.assign((deviceId) => ({_hx_index: 0, __enum__: "letzbig.api.auth._GuestDelegate.Credentials", deviceId}), {_hx_name: "Guest", __params__: ["deviceId"]}),
	Cognito: Object.assign((token) => ({_hx_index: 1, __enum__: "letzbig.api.auth._GuestDelegate.Credentials", token}), {_hx_name: "Cognito", __params__: ["token"]})
}
Credentials.__constructs__ = ["Guest", "Cognito"]
Credentials.__empty_constructs__ = []
