import {Single} from "../../streams/Stream"
import {Source_Impl_, IdealSourceTools} from "../../io/Source"
import {ResponseHeaderBase, IncomingResponse} from "../Response"
import {HeaderField} from "../Header"
import {ClientObject} from "../Client"
import {MPair} from "../../core/Pair"
import {Outcome} from "../../core/Outcome"
import {LazyConst} from "../../core/Lazy"
import {Future_Impl_} from "../../core/Future"
import {TypedError} from "../../core/Error"
import {ByteChunk} from "../../chunk/ByteChunk"
import {Url_Impl_} from "../../Url"
import {Bytes} from "../../../haxe/io/Bytes"
import {Option} from "../../../haxe/ds/Option"
import {Timer} from "../../../haxe/Timer"
import {Register} from "../../../genes/Register"

export const JsClient = Register.global("$hxClasses")["tink.http.clients.JsClient"] = 
class JsClient extends Register.inherits() {
	new(credentials = null) {
		this.credentials = false;
		if (credentials) {
			this.credentials = true;
		};
	}
	request(req, handlers = null) {
		let _gthis = this;
		return Future_Impl_.async(function (cb) {
			let http = new XMLHttpRequest();
			http.open(req.header.method, Url_Impl_.toString(req.header.url));
			http.withCredentials = _gthis.credentials;
			http.responseType = "arraybuffer";
			let _g_current = 0;
			let _g_array = req.header.fields;
			while (_g_current < _g_array.length) {
				let header = _g_array[_g_current++];
				switch (header.name) {
					case "content-length":case "host":
						break
					default:
					http.setRequestHeader(header.name, header.value);
					
				};
			};
			http.onreadystatechange = function () {
				if (http.readyState == 4) {
					if (http.status != 0) {
						let headers;
						let _g = http.getAllResponseHeaders();
						if (_g == null) {
							headers = [];
						} else {
							let v = _g;
							let _g1 = [];
							let _g2 = 0;
							let _g3 = v.split("\r\n");
							while (_g2 < _g3.length) {
								let line = _g3[_g2];
								++_g2;
								if (line != "") {
									_g1.push(HeaderField.ofString(line));
								};
							};
							headers = _g1;
						};
						let this1 = new ResponseHeaderBase(http.status, http.statusText, headers, "HTTP/1.1");
						let header = this1;
						let cb1 = cb;
						let this2 = new ResponseHeaderBase(http.status, http.statusText, headers, "HTTP/1.1");
						let _g1 = http.response;
						let tmp;
						if (_g1 == null) {
							tmp = Source_Impl_.EMPTY;
						} else {
							let v = _g1;
							tmp = new Single(new LazyConst(ByteChunk.of(Bytes.ofData(v))));
						};
						cb1(Outcome.Success(new IncomingResponse(this2, tmp)));
					} else {
						let _g = cb;
						let a1 = Outcome.Failure(TypedError.withData(502, "XMLHttpRequest Error", {"request": req, "error": "Status code is zero"}, {"fileName": "tink/http/clients/JsClient.hx", "lineNumber": 55, "className": "tink.http.clients.JsClient", "methodName": "request"}));
						Timer.delay(function () {
							_g(a1);
						}, 1);
					};
				};
			};
			if (handlers != null) {
				let _g = handlers.upload;
				if (_g != null) {
					let upload = _g;
					http.upload.onprogress = function (e) {
						let upload1 = upload;
						let this1 = new MPair(e.loaded, Option.Some(e.total));
						let this2 = this1;
						upload1(this2);
					};
				};
			};
			http.onerror = function (e) {
				cb(Outcome.Failure(TypedError.withData(502, "XMLHttpRequest Error", {"request": req, "error": e}, {"fileName": "tink/http/clients/JsClient.hx", "lineNumber": 66, "className": "tink.http.clients.JsClient", "methodName": "request"})));
			};
			if (req.header.method == "GET") {
				http.send();
			} else {
				IdealSourceTools.all(req.body).handle(function (chunk) {
					http.send(new Int8Array(chunk.toBytes().b.bufferValue));
				});
			};
		});
	}
	static get __name__() {
		return "tink.http.clients.JsClient"
	}
	static get __interfaces__() {
		return [ClientObject]
	}
	get __class__() {
		return JsClient
	}
}

