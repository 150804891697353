import {Observable_Impl_, ConstObservable} from "../../../tink/state/Observable"
import {ClassName_Impl_} from "../../../tink/domspec/ClassName"
import {ReactType_Impl_} from "../../../react/ReactType"
import * as React from "react"
import {Register} from "../../../genes/Register"
import {Slot} from "../../../coconut/ui/internal/Slot"
import {View} from "../../../coconut/react/View"
import {Html} from "../../../coconut/react/Html"
import {ErrorOutline} from "@material-ui/icons"
import * as Styles from "@material-ui/core/styles"
import {Typography, Paper} from "@material-ui/core"

export const ErrorBox = Register.global("$hxClasses")["letzbig.ui.component.ErrorBox"] = 
class ErrorBox extends Register.inherits(View) {
	new(__coco_data_) {
		this.__coco_classes = new Slot(this);
		this.__coco_title = new Slot(this, null, null);
		this.__coco_message = new Slot(this, null, null);
		this.__coco_extra = new Slot(this, null, null);
		let _gthis = this;
		this.__initAttributes(__coco_data_);
		let snapshot = null;
		super.new(function () {
			return _gthis.__coco_render();
		}, null, null, null, null);
	}
	__coco_render() {
		let attributes = {"className": ClassName_Impl_.ofString(Observable_Impl_.get_value(this.__coco_classes).root)};
		let props = {"className": Observable_Impl_.get_value(this.__coco_classes).paper, "elevation": 2};
		let __r = [];
		let attributes1 = {"className": ClassName_Impl_.ofString(Observable_Impl_.get_value(this.__coco_classes).top)};
		let __r1 = [];
		__r1.push(React.createElement(ReactType_Impl_.fromComp(ErrorOutline), {"style": {"fontSize": 72, "color": "white"}}));
		let props1 = {"className": Observable_Impl_.get_value(this.__coco_classes).title, "variant": "h5"};
		let children = [Observable_Impl_.get_value(this.__coco_title)];
		let children1 = [ReactType_Impl_.fromComp(Typography), props1].concat(children);
		__r1.push(React.createElement.apply(React, children1));
		__r.push(Html.h("div", attributes1, __r1));
		let attributes2 = {"className": ClassName_Impl_.ofString(Observable_Impl_.get_value(this.__coco_classes).bottom)};
		let __r2 = [];
		let children2 = [Observable_Impl_.get_value(this.__coco_message)];
		let children3 = [ReactType_Impl_.fromComp(Typography), {}].concat(children2);
		__r2.push(React.createElement.apply(React, children3));
		__r2.push(Observable_Impl_.get_value(this.__coco_extra));
		__r.push(Html.h("div", attributes2, __r2));
		let children4 = [ReactType_Impl_.fromComp(Paper), props].concat(__r);
		return Html.h("div", attributes, [React.createElement.apply(React, children4)]);
	}
	__initAttributes(attributes) {
		this.__coco_title.setData(attributes.title);
		this.__coco_message.setData(attributes.message);
		this.__coco_extra.setData(attributes.extra);
		let value = attributes.classes;
		this.__coco_classes.setData(new ConstObservable(value, null));
	}
	static styles(theme) {
		let tmp = {"display": "flex", "justifyContent": "center", "alignItems": "center", "width": "100%", "marginTop": theme.spacing(4), "padding": theme.spacing(4)};
		let tmp1 = {"display": "flex", "flexDirection": "column", "justifyContent": "center", "alignItems": "center", "backgroundColor": "#e46868", "padding": theme.spacing(4)};
		let tmp2 = {"textAlign": "center", "backgroundColor": "white", "paddingLeft": theme.spacing(6), "paddingRight": theme.spacing(6), "paddingTop": theme.spacing(2), "paddingBottom": theme.spacing(2), "& > *": {"marginTop": theme.spacing(1), "marginBottom": theme.spacing(1)}};
		let tmp3 = theme.spacing(1);
		return {"root": tmp, "paper": {}, "top": tmp1, "bottom": tmp2, "title": {"color": "white", "fontWeight": "bold", "marginTop": tmp3}};
	}
	static fromHxx(hxxMeta, attributes) {
		return {"$$typeof": View.TRE, "type": ErrorBox.__hoc, "props": attributes, "key": hxxMeta.key, "ref": (!hxxMeta.ref) ? null : hxxMeta.ref};
	}
	static get __name__() {
		return "letzbig.ui.component.ErrorBox"
	}
	static get __super__() {
		return View
	}
	get __class__() {
		return ErrorBox
	}
}


ErrorBox.__hoc = (Styles.withStyles(ErrorBox.styles))(ErrorBox)