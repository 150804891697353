import {Observable_Impl_, ConstObservable} from "../../../../tink/state/Observable"
import {ClassName_Impl_} from "../../../../tink/domspec/ClassName"
import {ReactType_Impl_} from "../../../../react/ReactType"
import * as React from "react"
import {Register} from "../../../../genes/Register"
import {Slot} from "../../../../coconut/ui/internal/Slot"
import {View} from "../../../../coconut/react/View"
import {Html} from "../../../../coconut/react/Html"
import * as Styles from "@material-ui/core/styles"
import {CircularProgress} from "@material-ui/core"

export const PageLoader = Register.global("$hxClasses")["letzbig.ui.component.app.PageLoader"] = 
class PageLoader extends Register.inherits(View) {
	new(__coco_data_) {
		this.__coco_classes = new Slot(this);
		let _gthis = this;
		this.__initAttributes(__coco_data_);
		let snapshot = null;
		super.new(function () {
			return _gthis.__coco_render();
		}, null, null, null, null);
	}
	__coco_render() {
		let attributes = {"className": ClassName_Impl_.ofString(Observable_Impl_.get_value(this.__coco_classes).container)};
		let children = [ReactType_Impl_.fromComp(CircularProgress), {"size": 24, "color": "secondary"}].concat(null);
		return Html.h("div", attributes, [React.createElement.apply(React, children)]);
	}
	__initAttributes(attributes) {
		let value = attributes.classes;
		this.__coco_classes.setData(new ConstObservable(value, null));
	}
	static styles(theme) {
		return {"container": {"display": "flex", "alignItems": "center", "justifyContent": "center", "flex": 1}};
	}
	static fromHxx(hxxMeta, attributes) {
		return {"$$typeof": View.TRE, "type": PageLoader.__hoc, "props": attributes, "key": hxxMeta.key, "ref": (!hxxMeta.ref) ? null : hxxMeta.ref};
	}
	static get __name__() {
		return "letzbig.ui.component.app.PageLoader"
	}
	static get __super__() {
		return View
	}
	get __class__() {
		return PageLoader
	}
}


PageLoader.__hoc = (Styles.withStyles(PageLoader.styles))(PageLoader)