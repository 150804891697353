import {Status} from "../../../why/auth/Status"
import {AutoObservable, Computation_Impl_} from "../../../tink/state/internal/AutoObservable"
import {State_Impl_} from "../../../tink/state/State"
import {PromisedTools} from "../../../tink/state/Promised"
import {Observable_Impl_, ConstObservable} from "../../../tink/state/Observable"
import {NodeIterator} from "../../../tink/pure/List"
import {Outcome} from "../../../tink/core/Outcome"
import {SuspendableFuture} from "../../../tink/core/Future"
import {TypedError} from "../../../tink/core/Error"
import {SimpleLink} from "../../../tink/core/Callback"
import {ReactType_Impl_} from "../../../react/ReactType"
import * as React from "react"
import {FeedbackEditorPage} from "../../ui/page/app/FeedbackEditorPage"
import {AuthModel} from "../data/AuthModel"
import {CompositeCredentials, CompositeSignUpInfo} from "../../PublicApi"
import {Option} from "../../../haxe/ds/Option"
import {Register} from "../../../genes/Register"
import {Slot} from "../../../coconut/ui/internal/Slot"
import {View} from "../../../coconut/react/View"
import {Html} from "../../../coconut/react/Html"
import {HxOverrides} from "../../../HxOverrides"
import {Close} from "@material-ui/icons"
import * as Styles from "@material-ui/core/styles"
import {Typography, IconButton, DialogTitle, TextField, Button, DialogContent, Dialog} from "@material-ui/core"

export const CreateFeedbackController = Register.global("$hxClasses")["letzbig.app.controller.CreateFeedbackController"] = 
class CreateFeedbackController extends Register.inherits(View) {
	new(__coco_data_) {
		this.__coco_auth = new Slot(this, null, null);
		this.__coco_product = new Slot(this, null, null);
		this.__coco_text = State_Impl_._new("", null);
		this.__coco_attachments = State_Impl_._new(null, null);
		this.__coco_loginWidget = State_Impl_._new(null, null);
		let _gthis = this;
		this.__initAttributes(__coco_data_);
		let snapshot = null;
		super.new(function () {
			return _gthis.__coco_render();
		}, null, null, null, null);
	}
	__coco_render() {
		let _gthis = this;
		let __r = [];
		let tmp = new AutoObservable(Computation_Impl_.sync(function () {
			return PromisedTools.map(Observable_Impl_.get_value(Observable_Impl_.get_value(_gthis.__coco_product).__coco_data), function (info) {
				return {"brand": info.brand + (" (" + HxOverrides.dateStr(new Date(1.616224388e+12)) + ")"), "name": info.name, "thumbnailUrl": info.thumbnailUrl};
			});
		}), null, null);
		let target = new AutoObservable(Computation_Impl_.sync(function () {
			return _gthis;
		}), null, null);
		let tmp1 = State_Impl_.compound(new AutoObservable(Computation_Impl_.sync(function () {
			return State_Impl_.get_value(Observable_Impl_.get_value(target).__coco_text);
		}), null, null), function (value) {
			Observable_Impl_.get_value(target).__coco_text.set(value);
		});
		let target1 = new AutoObservable(Computation_Impl_.sync(function () {
			return _gthis;
		}), null, null);
		let tmp2 = State_Impl_.compound(new AutoObservable(Computation_Impl_.sync(function () {
			return State_Impl_.get_value(Observable_Impl_.get_value(target1).__coco_attachments);
		}), null, null), function (value) {
			Observable_Impl_.get_value(target1).__coco_attachments.set(value);
		});
		let tmp3 = new AutoObservable(Computation_Impl_.sync(function () {
			let _g = Observable_Impl_.get_value(Observable_Impl_.get_value(_gthis.__coco_auth).__coco_status);
			if (_g._hx_index == 2) {
				let _g1 = _g.profile;
				return null;
			} else {
				return function () {
					let this1 = _gthis.login();
					this1.eager();
					return this1;
				};
			};
		}), null, null);
		let tmp4 = new AutoObservable(Computation_Impl_.sync(function () {
			return Register.bind(_gthis, _gthis.submit);
		}), null, null);
		__r.push(FeedbackEditorPage.fromHxx({}, {"product": tmp, "text": tmp1, "attachments": tmp2, "onLogin": tmp3, "onSubmit": tmp4}));
		__r.push(State_Impl_.get_value(this.__coco_loginWidget));
		return View.createFragment({}, __r);
	}
	renderLoginWidget(attr) {
		let _gthis = this;
		let tmp = new AutoObservable(Computation_Impl_.sync(function () {
			return attr.onClose;
		}), null, null);
		let tmp1 = new AutoObservable(Computation_Impl_.sync(function () {
			return function (email, password) {
				Observable_Impl_.get_value(_gthis.__coco_auth).delegate.signIn(CompositeCredentials.Amplify({"email": email, "password": password})).handle(function (o) {
					console.log("src/letzbig/app/controller/CreateFeedbackController.hx:40:",o);
				});
			};
		}), null, null);
		let tmp2 = new AutoObservable(Computation_Impl_.sync(function () {
			return function () {
				Observable_Impl_.get_value(_gthis.__coco_auth).delegate.signUp(CompositeSignUpInfo.Guest(AuthModel.getDeviceId())).handle(function (o) {
					console.log("src/letzbig/app/controller/CreateFeedbackController.hx:41:",o);
				});
			};
		}), null, null);
		return LoginWidget.fromHxx({}, {"onClose": tmp, "onLogin": tmp1, "onLoginAsGuest": tmp2});
	}
	submit() {
		let _gthis = this;
		let tmp;
		if (State_Impl_.get_value(this.__coco_text) == "") {
			let this1 = State_Impl_.get_value(this.__coco_attachments);
			tmp = ((this1 == null) ? 0 : this1.length) == 0;
		} else {
			tmp = false;
		};
		if (tmp) {
			return;
		};
		Observable_Impl_.nextTime(Observable_Impl_.get_value(this.__coco_auth).observables.status, null, function (status) {
			return status != Status.Initializing;
		}).handle(function (status) {
			switch (status._hx_index) {
				case 0:
					break
				case 1:
					_gthis.login().handle(function (o) {
						switch (o._hx_index) {
							case 0:
								let _g = o.data;
								console.log("src/letzbig/app/controller/CreateFeedbackController.hx:59:","sucess login");
								break
							case 1:
								let e = o.failure;
								console.log("src/letzbig/app/controller/CreateFeedbackController.hx:60:",e);
								break
							
						};
					});
					break
				case 2:
					let _g = status.profile;
					_gthis.createFeedback();
					break
				case 3:
					let e = status.e;
					break
				
			};
		});
	}
	login() {
		let _gthis = this;
		let f = function (resolve, reject) {
			let binding = Observable_Impl_.getNext(Observable_Impl_.get_value(_gthis.__coco_auth).observables.status, null, function (status) {
				if (status._hx_index == 2) {
					let user = status.profile;
					return Option.Some(user);
				} else {
					return Option.None;
				};
			}).handle(function (_) {
				resolve(null);
			});
			let cancel = function () {
				console.log("src/letzbig/app/controller/CreateFeedbackController.hx:78:","cancel");
				let param = null;
				_gthis.__coco_loginWidget.set(param);
				if (binding != null) {
					binding.cancel();
				};
				reject(new TypedError(null, "Cancelled", {"fileName": "src/letzbig/app/controller/CreateFeedbackController.hx", "lineNumber": 81, "className": "letzbig.app.controller.CreateFeedbackController", "methodName": "login"}));
			};
			console.log("src/letzbig/app/controller/CreateFeedbackController.hx:84:","renderLoginWidget");
			let param = _gthis.renderLoginWidget({"onClose": cancel});
			_gthis.__coco_loginWidget.set(param);
			let this1 = new SimpleLink(cancel);
			return this1;
		};
		let this1 = new SuspendableFuture(function (cb) {
			return f(function (v) {
				cb(Outcome.Success(v));
			}, function (e) {
				cb(Outcome.Failure(e));
			});
		});
		let this2 = this1;
		return this2;
	}
	createFeedback() {
		let progress = Observable_Impl_.get_value(this.__coco_product);
		let progress1 = State_Impl_.get_value(this.__coco_text);
		let _g = [];
		let _g1 = new NodeIterator(State_Impl_.get_value(this.__coco_attachments));
		while (_g1.list.length > 0) {
			let a = _g1.next();
			_g.push({"blob": a.blob, "type": Observable_Impl_.get_value(a.__coco_type)});
		};
		let progress2 = progress.createFeedback(progress1, _g);
		progress2.result.handle(function (o) {
			console.log("src/letzbig/app/controller/CreateFeedbackController.hx:95:",o);
		});
		progress2.result.handle(function (o) {
			console.log("src/letzbig/app/controller/CreateFeedbackController.hx:96:",o);
		});
	}
	__initAttributes(attributes) {
		this.__coco_auth.setData(attributes.auth);
		this.__coco_product.setData(attributes.product);
	}
	static fromHxx(hxxMeta, attributes) {
		return {"$$typeof": View.TRE, "type": CreateFeedbackController, "props": attributes, "key": hxxMeta.key, "ref": (!hxxMeta.ref) ? null : hxxMeta.ref};
	}
	static get __name__() {
		return "letzbig.app.controller.CreateFeedbackController"
	}
	static get __super__() {
		return View
	}
	get __class__() {
		return CreateFeedbackController
	}
}


export const LoginWidget = Register.global("$hxClasses")["letzbig.app.controller.LoginWidget"] = 
class LoginWidget extends Register.inherits(View) {
	new(__coco_data_) {
		this.__coco_classes = new Slot(this);
		this.__coco_onClose = new Slot(this, null, null);
		this.__coco_onLogin = new Slot(this, null, null);
		this.__coco_onLoginAsGuest = new Slot(this, null, null);
		this.__coco_email = State_Impl_._new("", null);
		this.__coco_password = State_Impl_._new("", null);
		let _gthis = this;
		this.__initAttributes(__coco_data_);
		let snapshot = null;
		super.new(function () {
			return _gthis.__coco_render();
		}, null, null, null, null);
	}
	onClose() {
		(Observable_Impl_.get_value(this.__coco_onClose))();
	}
	onLogin(a0, a1) {
		(Observable_Impl_.get_value(this.__coco_onLogin))(a0, a1);
	}
	onLoginAsGuest() {
		(Observable_Impl_.get_value(this.__coco_onLoginAsGuest))();
	}
	__coco_render() {
		let _gthis = this;
		let __r = [];
		let __r1 = [];
		let tmp = [ReactType_Impl_.fromComp(Typography), {"variant": "h6"}].concat(["Login"]);
		__r1.push(React.createElement.apply(React, tmp));
		let props = {"className": Observable_Impl_.get_value(this.__coco_classes).close, "onClick": Register.bind(this, this.onClose)};
		let children = [React.createElement(ReactType_Impl_.fromComp(Close), {})];
		let tmp1 = [ReactType_Impl_.fromComp(IconButton), props].concat(children);
		__r1.push(React.createElement.apply(React, tmp1));
		let tmp2 = [ReactType_Impl_.fromComp(DialogTitle), {"disableTypography": true}].concat(__r1);
		__r.push(React.createElement.apply(React, tmp2));
		let props1 = {"className": Observable_Impl_.get_value(this.__coco_classes).widget};
		let __r2 = [];
		let props2 = {"className": Observable_Impl_.get_value(this.__coco_classes).input, "fullWidth": true, "placeholder": "Email", "value": State_Impl_.get_value(this.__coco_email), "onChange": function (e) {
			let param = e.target.value;
			_gthis.__coco_email.set(param);
			return param;
		}};
		let tmp3 = [ReactType_Impl_.fromComp(TextField), props2].concat(null);
		__r2.push(React.createElement.apply(React, tmp3));
		let props3 = {"className": Observable_Impl_.get_value(this.__coco_classes).input, "fullWidth": true, "placeholder": "Password", "value": State_Impl_.get_value(this.__coco_password), "onChange": function (e) {
			let param = e.target.value;
			_gthis.__coco_password.set(param);
			return param;
		}};
		let tmp4 = [ReactType_Impl_.fromComp(TextField), props3].concat(null);
		__r2.push(React.createElement.apply(React, tmp4));
		let tmp5 = [ReactType_Impl_.fromComp(Button), {"variant": "contained", "color": "primary", "onClick": function () {
			_gthis.onLogin(State_Impl_.get_value(_gthis.__coco_email), State_Impl_.get_value(_gthis.__coco_password));
		}}].concat(["Login"]);
		__r2.push(React.createElement.apply(React, tmp5));
		__r2.push(Html.h("div", {}, ["or"]));
		let tmp6 = [ReactType_Impl_.fromComp(Button), {"variant": "contained", "color": "primary", "onClick": function () {
			_gthis.onLoginAsGuest();
		}}].concat(["Login as Guest"]);
		__r2.push(React.createElement.apply(React, tmp6));
		let tmp7 = [ReactType_Impl_.fromComp(DialogContent), props1].concat(__r2);
		__r.push(React.createElement.apply(React, tmp7));
		let tmp8 = [ReactType_Impl_.fromComp(Dialog), {"open": true, "onClose": Register.bind(this, this.onClose)}].concat(__r);
		return React.createElement.apply(React, tmp8);
	}
	__initAttributes(attributes) {
		this.__coco_onClose.setData(attributes.onClose);
		this.__coco_onLogin.setData(attributes.onLogin);
		this.__coco_onLoginAsGuest.setData(attributes.onLoginAsGuest);
		let value = attributes.classes;
		this.__coco_classes.setData(new ConstObservable(value, null));
	}
	static styles(theme) {
		return {"container": {"overflow": "hidden", "display": "flex", "flexDirection": "column", "padding": theme.spacing(2), "alignItems": "center", "justifyContent": "center"}, "widget": {"minWidth": 300, "display": "flex", "flexDirection": "column", "alignItems": "center", "justifyContent": "center", "backgroundColor": "white", "borderRadius": theme.spacing(1)}, "close": {"position": "absolute", "right": theme.spacing(1), "top": theme.spacing(1), "color": theme.palette.grey[500]}, "input": {"marginBottom": theme.spacing(1)}};
	}
	static fromHxx(hxxMeta, attributes) {
		return {"$$typeof": View.TRE, "type": LoginWidget.__hoc, "props": attributes, "key": hxxMeta.key, "ref": (!hxxMeta.ref) ? null : hxxMeta.ref};
	}
	static get __name__() {
		return "letzbig.app.controller.LoginWidget"
	}
	static get __super__() {
		return View
	}
	get __class__() {
		return LoginWidget
	}
}


LoginWidget.__hoc = (Styles.withStyles(LoginWidget.styles))(LoginWidget)