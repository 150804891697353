import {Observable_Impl_, Transform_Impl_} from "../../../tink/state/Observable"
import {LazyConst} from "../../../tink/core/Lazy"
import {MapKeyValueIterator} from "../../../haxe/iterators/MapKeyValueIterator"
import {ObjectMap} from "../../../haxe/ds/ObjectMap"
import {Register} from "../../../genes/Register"
import {Slot} from "./Slot"

export const ImplicitValues_Impl_ = Register.global("$hxClasses")["coconut.ui.internal._ImplicitContext.ImplicitValues_Impl_"] = 
class ImplicitValues_Impl_ {
	static _new(a) {
		let _g = new ObjectMap();
		let _g1 = 0;
		while (_g1 < a.length) {
			let o = a[_g1];
			++_g1;
			_g.inst.set(o.key, o.val);
		};
		let this1 = _g;
		return this1;
	}
	static get __name__() {
		return "coconut.ui.internal._ImplicitContext.ImplicitValues_Impl_"
	}
	get __class__() {
		return ImplicitValues_Impl_
	}
}


export const ImplicitContext = Register.global("$hxClasses")["coconut.ui.internal.ImplicitContext"] = 
class ImplicitContext extends Register.inherits() {
	new(parent = null) {
		this.slots = new ObjectMap();
		let tmp;
		if (parent == null) {
			tmp = ImplicitContext.ORPHAN;
		} else {
			let v = parent;
			tmp = v;
		};
		this.parent = tmp;
	}
	getSlot(key) {
		let _g = this.slots.inst.get(key);
		if (_g == null) {
			let this1 = this.slots;
			let v = new Slot(this);
			this1.inst.set(key, v);
			return v;
		} else {
			let v = _g;
			return v;
		};
	}
	update(values) {
		let _g = new MapKeyValueIterator(this.slots);
		while (_g.hasNext()) {
			let _g1 = _g.next();
			let k = _g1.key;
			let slot = _g1.value;
			if (!values.inst.has(k)) {
				slot.setData(null);
			};
		};
		let _g1 = new MapKeyValueIterator(values);
		while (_g1.hasNext()) {
			let _g = _g1.next();
			let k = _g.key;
			let v = _g.value;
			this.getSlot(k).setData(v);
		};
	}
	static get __name__() {
		return "coconut.ui.internal.ImplicitContext"
	}
	get __class__() {
		return ImplicitContext
	}
}


ImplicitContext.ORPHAN = new LazyConst(null)
export const TypeKey_Impl_ = Register.global("$hxClasses")["coconut.ui.internal._ImplicitContext.TypeKey_Impl_"] = 
class TypeKey_Impl_ {
	static ofClass(t) {
		return t;
	}
	static get __name__() {
		return "coconut.ui.internal._ImplicitContext.TypeKey_Impl_"
	}
	get __class__() {
		return TypeKey_Impl_
	}
}


export const SingleImplicit = Register.global("$hxClasses")["coconut.ui.internal.SingleImplicit"] = 
class SingleImplicit extends Register.inherits() {
	new(key, val) {
		this.key = key;
		this.val = Observable_Impl_.map(val, Transform_Impl_.plain(function (x) {
			return x;
		}));
	}
	static get __name__() {
		return "coconut.ui.internal.SingleImplicit"
	}
	get __class__() {
		return SingleImplicit
	}
}

