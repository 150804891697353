import {ObservableObject} from "./internal/ObservableObject"
import {Invalidator} from "./internal/Invalidatable"
import {AutoObservable} from "./internal/AutoObservable"
import {Scheduler_Impl_} from "./Scheduler"
import {Observable_Impl_} from "./Observable"
import {Register} from "../../genes/Register"

export const State_Impl_ = Register.global("$hxClasses")["tink.state._State.State_Impl_"] = 
class State_Impl_ {
	static get value() {
		return this.get_value()
	}
	static get_value(this1) {
		let ret = this1.getValue();
		if (AutoObservable.cur != null) {
			AutoObservable.cur.subscribeTo(this1, ret);
		};
		return ret;
	}
	static _new(value, comparator = null, guard = null, onStatusChange = null, toString = null) {
		let this1;
		if (guard == null) {
			this1 = new SimpleState(value, comparator, onStatusChange, toString);
		} else {
			let f = guard;
			this1 = new GuardedState(value, guard, comparator, onStatusChange, toString);
		};
		return this1;
	}
	static compound(source, update, comparator = null) {
		return new CompoundState(source, update, comparator);
	}
	static get __name__() {
		return "tink.state._State.State_Impl_"
	}
	get __class__() {
		return State_Impl_
	}
}


export const StateObject = {}

export const SimpleState = Register.global("$hxClasses")["tink.state._State.SimpleState"] = 
class SimpleState extends Register.inherits(Invalidator) {
	new(value, comparator = null, onStatusChange = null, toString = null) {
		super.new(toString);
		this.value = value;
		this.comparator = comparator;
		if (onStatusChange != null) {
			let _g = onStatusChange;
			let a1 = false;
			let tmp = function () {
				_g(a1);
			};
			this.list.ondrain = tmp;
			let _g1 = onStatusChange;
			let a11 = true;
			let tmp1 = function () {
				_g1(a11);
			};
			this.list.onfill = tmp1;
		};
	}
	getValue() {
		return this.value;
	}
	getComparator() {
		return this.comparator;
	}
	set(value) {
		if (Observable_Impl_.isUpdating && Scheduler_Impl_.direct.queue == null) {
			Register.$global.console.warn("Updating state in a binding");
		};
		let this1 = this.comparator;
		let b = this.value;
		let tmp;
		if (this1 == null) {
			tmp = value == b;
		} else {
			let f = this1;
			tmp = f(value, b);
		};
		if (!tmp) {
			this.value = value;
			this.fire();
		};
		return value;
	}
	static get __name__() {
		return "tink.state._State.SimpleState"
	}
	static get __interfaces__() {
		return [StateObject]
	}
	static get __super__() {
		return Invalidator
	}
	get __class__() {
		return SimpleState
	}
}


export const GuardedState = Register.global("$hxClasses")["tink.state._State.GuardedState"] = 
class GuardedState extends Register.inherits(SimpleState) {
	new(value, guard, comparator = null, onStatusChange = null, toString = null) {
		this.guardApplied = false;
		super.new(value, comparator, onStatusChange, toString);
		this.guard = guard;
	}
	getValue() {
		if (!this.guardApplied) {
			this.guardApplied = true;
			return this.value = this.guard(this.value);
		} else {
			return this.value;
		};
	}
	set(value) {
		if (!this.guardApplied) {
			this.guardApplied = true;
			this.value = this.guard(this.value);
		};
		return super.set(this.guard(value));
	}
	static get __name__() {
		return "tink.state._State.GuardedState"
	}
	static get __super__() {
		return SimpleState
	}
	get __class__() {
		return GuardedState
	}
}


export const CompoundState = Register.global("$hxClasses")["tink.state._State.CompoundState"] = 
class CompoundState extends Register.inherits() {
	new(data, set, comparator = null) {
		this.data = data;
		this.update = set;
		this.comparator = comparator;
	}
	getRevision() {
		return this.data.getRevision();
	}
	getValue() {
		return this.data.getValue();
	}
	onInvalidate(i) {
		return this.data.onInvalidate(i);
	}
	set(value) {
		this.update(value);
		return value;
	}
	getComparator() {
		return this.comparator;
	}
	static get __name__() {
		return "tink.state._State.CompoundState"
	}
	static get __interfaces__() {
		return [StateObject]
	}
	get __class__() {
		return CompoundState
	}
}

