import {Register} from "../genes/Register"

export const Toast_Impl_ = Register.global("$hxClasses")["why._Toast.Toast_Impl_"] = 
class Toast_Impl_ {
	static get __name__() {
		return "why._Toast.Toast_Impl_"
	}
	get __class__() {
		return Toast_Impl_
	}
}


export const ToastObject = {}

export const Duration = 
Register.global("$hxEnums")["why.Duration"] = 
{
	__ename__: "why.Duration",
	
	Short: {_hx_name: "Short", _hx_index: 0, __enum__: "why.Duration"},
	Long: {_hx_name: "Long", _hx_index: 1, __enum__: "why.Duration"},
	Custom: Object.assign((ms) => ({_hx_index: 2, __enum__: "why.Duration", ms}), {_hx_name: "Custom", __params__: ["ms"]}),
	Indefinite: {_hx_name: "Indefinite", _hx_index: 3, __enum__: "why.Duration"}
}
Duration.__constructs__ = ["Short", "Long", "Custom", "Indefinite"]
Duration.__empty_constructs__ = [Duration.Short, Duration.Long, Duration.Indefinite]
