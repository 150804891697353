import {Writer6} from "./Writer6"
import {Writer5} from "./Writer5"
import {BasicParser, JsonString_Impl_} from "./Parser"
import {OutgoingRequestHeader} from "../http/Request"
import {HeaderField} from "../http/Header"
import {TypedError} from "../core/Error"
import {Url_Impl_} from "../Url"
import {Exception} from "../../haxe/Exception"
import {Register} from "../../genes/Register"
import {Std} from "../../Std"

export const Parser13 = Register.global("$hxClasses")["tink.json.Parser13"] = 
class Parser13 extends Register.inherits(BasicParser) {
	new() {
		super.new();
	}
	process0() {
		let _gthis = this;
		let v_header = null;
		let hasv_header = false;
		let v_path = null;
		let hasv_path = false;
		let __start__ = this.pos;
		while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
		let tmp;
		if (this.max > this.pos && this.source.charCodeAt(this.pos) == 123) {
			this.pos += 1;
			while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
			tmp = true;
		} else {
			tmp = false;
		};
		if (!tmp) {
			this.die("Expected " + "{");
		};
		while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
		let tmp1;
		if (this.max > this.pos && this.source.charCodeAt(this.pos) == 125) {
			this.pos += 1;
			while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
			tmp1 = true;
		} else {
			tmp1 = false;
		};
		if (!tmp1) {
			while (true) {
				let __name__ = this.parseString();
				while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
				let tmp;
				if (this.max > this.pos && this.source.charCodeAt(this.pos) == 58) {
					this.pos += 1;
					while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
					tmp = true;
				} else {
					tmp = false;
				};
				if (!tmp) {
					this.die("Expected " + ":");
				};
				if ("path".length == __name__.max - __name__.min && __name__.source.substring(__name__.min, __name__.max) == "path") {
					v_path = JsonString_Impl_.toString(this.parseString());
					hasv_path = true;
				} else if ("header".length == __name__.max - __name__.min && __name__.source.substring(__name__.min, __name__.max) == "header") {
					let json = this.process1();
					v_header = new OutgoingRequestHeader(json.method, json.url, json.protocol, json.fields);
					hasv_header = true;
				} else {
					this.skipValue();
				};
				while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
				let tmp1;
				if (this.max > this.pos && this.source.charCodeAt(this.pos) == 44) {
					this.pos += 1;
					while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
					tmp1 = true;
				} else {
					tmp1 = false;
				};
				if (!tmp1) {
					break;
				};
			};
			while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
			let tmp;
			if (this.max > this.pos && this.source.charCodeAt(this.pos) == 125) {
				this.pos += 1;
				while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
				tmp = true;
			} else {
				tmp = false;
			};
			if (!tmp) {
				this.die("Expected " + "}");
			};
		};
		let __missing__ = function (field) {
			return _gthis.die("missing field \"" + field + "\"", __start__);
		};
		return {"header": (hasv_header) ? v_header : __missing__("header"), "path": (hasv_path) ? v_path : __missing__("path")};
	}
	process1() {
		let _gthis = this;
		let v_fields = null;
		let hasv_fields = false;
		let v_method = null;
		let hasv_method = false;
		let v_protocol = null;
		let hasv_protocol = false;
		let v_url = null;
		let hasv_url = false;
		let __start__ = this.pos;
		while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
		let tmp;
		if (this.max > this.pos && this.source.charCodeAt(this.pos) == 123) {
			this.pos += 1;
			while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
			tmp = true;
		} else {
			tmp = false;
		};
		if (!tmp) {
			this.die("Expected " + "{");
		};
		while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
		let tmp1;
		if (this.max > this.pos && this.source.charCodeAt(this.pos) == 125) {
			this.pos += 1;
			while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
			tmp1 = true;
		} else {
			tmp1 = false;
		};
		if (!tmp1) {
			while (true) {
				let __name__ = this.parseString();
				while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
				let tmp;
				if (this.max > this.pos && this.source.charCodeAt(this.pos) == 58) {
					this.pos += 1;
					while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
					tmp = true;
				} else {
					tmp = false;
				};
				if (!tmp) {
					this.die("Expected " + ":");
				};
				if ("url".length == __name__.max - __name__.min && __name__.source.substring(__name__.min, __name__.max) == "url") {
					let __start__ = this.pos;
					let rep = JsonString_Impl_.toString(this.parseString());
					try {
						let this1 = rep;
						v_url = Url_Impl_.fromRepresentation(this1);
					}catch (_g) {
						let e = Exception.caught(_g).unwrap();
						v_url = this.die(Std.string(e), __start__);
					};
					hasv_url = true;
				} else if ("protocol".length == __name__.max - __name__.min && __name__.source.substring(__name__.min, __name__.max) == "protocol") {
					let v = JsonString_Impl_.toString(this.parseString());
					switch (v) {
						case "HTTP/1.0":case "HTTP/1.1":case "HTTP/2":
							v_protocol = v;
							break
						default:
						let list = ["HTTP/1.0", "HTTP/1.1", "HTTP/2"];
						throw Exception.thrown(new TypedError(422, "Unrecognized enum value: " + v + ". Accepted values are: " + new Writer5().write(list), {"fileName": "tink/json/macros/GenReader.hx", "lineNumber": 392, "className": "tink.json.Parser13", "methodName": "process1"}));
						
					};
					hasv_protocol = true;
				} else if ("method".length == __name__.max - __name__.min && __name__.source.substring(__name__.min, __name__.max) == "method") {
					let v = JsonString_Impl_.toString(this.parseString());
					switch (v) {
						case "DELETE":case "GET":case "HEAD":case "OPTIONS":case "PATCH":case "POST":case "PUT":
							v_method = v;
							break
						default:
						let list = ["GET", "HEAD", "OPTIONS", "POST", "PUT", "PATCH", "DELETE"];
						throw Exception.thrown(new TypedError(422, "Unrecognized enum value: " + v + ". Accepted values are: " + new Writer6().write(list), {"fileName": "tink/json/macros/GenReader.hx", "lineNumber": 392, "className": "tink.json.Parser13", "methodName": "process1"}));
						
					};
					hasv_method = true;
				} else if ("fields".length == __name__.max - __name__.min && __name__.source.substring(__name__.min, __name__.max) == "fields") {
					while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
					let v_fields1;
					if (this.max > this.pos && this.source.charCodeAt(this.pos) == 91) {
						this.pos += 1;
						while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
						v_fields1 = true;
					} else {
						v_fields1 = false;
					};
					if (!v_fields1) {
						this.die("Expected " + "[");
					};
					let __ret = [];
					while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
					let v_fields2;
					if (this.max > this.pos && this.source.charCodeAt(this.pos) == 93) {
						this.pos += 1;
						while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
						v_fields2 = true;
					} else {
						v_fields2 = false;
					};
					if (!v_fields2) {
						while (true) {
							__ret.push(HeaderField.ofString(JsonString_Impl_.toString(this.parseString())));
							while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
							let v_fields;
							if (this.max > this.pos && this.source.charCodeAt(this.pos) == 44) {
								this.pos += 1;
								while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
								v_fields = true;
							} else {
								v_fields = false;
							};
							if (!v_fields) {
								break;
							};
						};
						while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
						let v_fields;
						if (this.max > this.pos && this.source.charCodeAt(this.pos) == 93) {
							this.pos += 1;
							while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
							v_fields = true;
						} else {
							v_fields = false;
						};
						if (!v_fields) {
							this.die("Expected " + "]");
						};
					};
					v_fields = __ret;
					hasv_fields = true;
				} else {
					this.skipValue();
				};
				while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
				let tmp1;
				if (this.max > this.pos && this.source.charCodeAt(this.pos) == 44) {
					this.pos += 1;
					while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
					tmp1 = true;
				} else {
					tmp1 = false;
				};
				if (!tmp1) {
					break;
				};
			};
			while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
			let tmp;
			if (this.max > this.pos && this.source.charCodeAt(this.pos) == 125) {
				this.pos += 1;
				while (this.pos < this.max && this.source.charCodeAt(this.pos) < 33) this.pos++;
				tmp = true;
			} else {
				tmp = false;
			};
			if (!tmp) {
				this.die("Expected " + "}");
			};
		};
		let __missing__ = function (field) {
			return _gthis.die("missing field \"" + field + "\"", __start__);
		};
		return {"fields": (hasv_fields) ? v_fields : __missing__("fields"), "method": (hasv_method) ? v_method : __missing__("method"), "protocol": (hasv_protocol) ? v_protocol : __missing__("protocol"), "url": (hasv_url) ? v_url : __missing__("url")};
	}
	parse(source) {
		let _gthis = this;
		if (_gthis.afterParsing.length > 0) {
			_gthis.afterParsing = [];
		};
		this.init(source);
		let ret = this.process0();
		let _g = 0;
		let _g1 = this.afterParsing;
		while (_g < _g1.length) {
			let f = _g1[_g];
			++_g;
			f();
		};
		if (_gthis.afterParsing.length > 0) {
			_gthis.afterParsing = [];
		};
		return ret;
	}
	tryParse(source) {
		let _gthis = this;
		return TypedError.catchExceptions(function () {
			let ret = _gthis.parse(source);
			while (_gthis.pos < _gthis.max && _gthis.source.charCodeAt(_gthis.pos) < 33) _gthis.pos++;
			if (_gthis.pos < _gthis.max) {
				_gthis.die("Invalid data after JSON document");
			};
			return ret;
		}, null, {"fileName": "tink/json/macros/Macro.hx", "lineNumber": 107, "className": "tink.json.Parser13", "methodName": "tryParse"});
	}
	static get __name__() {
		return "tink.json.Parser13"
	}
	static get __super__() {
		return BasicParser
	}
	get __class__() {
		return Parser13
	}
}

