import {EsMap} from "../../genes/util/EsMap"
import {Register} from "../../genes/Register"
import {StringTools} from "../../StringTools"

export const ClassName_Impl_ = Register.global("$hxClasses")["tink.domspec._ClassName.ClassName_Impl_"] = 
class ClassName_Impl_ {
	static add(this1, that) {
		let this2;
		let _g = that;
		if (this1 == null) {
			let v = _g;
			this2 = v;
		} else if (_g == null) {
			let v = this1;
			this2 = v;
		} else {
			let a = this1;
			let b = _g;
			this2 = "" + a + " " + b;
		};
		return this2;
	}
	static when(this1, cond) {
		let this2 = (cond) ? this1 : "";
		return this2;
	}
	static ofMap(parts) {
		let _g = [];
		let c = EsMap.adaptIterator(parts.inst.keys());
		while (c.hasNext()) {
			let c1 = c.next();
			if (parts.inst.get(c1)) {
				_g.push(ClassName_Impl_.ofString(c1));
			};
		};
		let this1 = ClassName_Impl_.ofArray(_g);
		return this1;
	}
	static ofArray(parts) {
		let f = ClassName_Impl_.ofString;
		let result = new Array(parts.length);
		let _g = 0;
		let _g1 = parts.length;
		while (_g < _g1) {
			let i = _g++;
			result[i] = f(parts[i]);
		};
		let this1 = result.join(" ");
		return this1;
	}
	static ofString(s) {
		if (s == null) {
			return null;
		} else {
			let this1 = StringTools.trim(s);
			return this1;
		};
	}
	static get __name__() {
		return "tink.domspec._ClassName.ClassName_Impl_"
	}
	get __class__() {
		return ClassName_Impl_
	}
}

