import {State_Impl_} from "../../../tink/state/State"
import {ConstObservable, Observable_Impl_, Transform_Impl_} from "../../../tink/state/Observable"
import {Signal_Impl_} from "../../../tink/core/Signal"
import {Register} from "../../../genes/Register"
import {Annex} from "../../../coconut/data/helpers/Annex"
import {Model} from "../../../coconut/data/Model"

export const AppModel = Register.global("$hxClasses")["letzbig.app.data.AppModel"] = 
class AppModel extends Register.inherits() {
	new(__coco_init) {
		this.auth = __coco_init.auth;
		this.remote = __coco_init.remote;
		this.__coco_transitionCount = State_Impl_._new(0);
		this.errorTrigger = Signal_Impl_.trigger();
		this.transitionErrors = this.errorTrigger;
		this.annex = new Annex(this);
		this.observables = {"auth": new ConstObservable(this.auth, null), "remote": new ConstObservable(this.remote, null), "isInTransition": Observable_Impl_.map(this.__coco_transitionCount, Transform_Impl_.plain(function (count) {
			return count > 0;
		}))};
	}
	static get __name__() {
		return "letzbig.app.data.AppModel"
	}
	static get __interfaces__() {
		return [Model]
	}
	get __class__() {
		return AppModel
	}
}

