import {Path_Impl_} from "./url/Path"
import {Host_Impl_} from "./url/Host"
import {Representation_Impl_} from "./json/Representation"
import {Register} from "../genes/Register"
import {StringTools} from "../StringTools"
import {Std} from "../Std"
import {Reflect} from "../Reflect"
import {HxOverrides} from "../HxOverrides"
import {EReg} from "../EReg"

export const Url_Impl_ = Register.global("$hxClasses")["tink._Url.Url_Impl_"] = 
class Url_Impl_ {
	static resolve(this1, that) {
		if (that.scheme != null) {
			return that;
		} else if (that.hosts[0] != null) {
			if (that.scheme != null) {
				return that;
			} else {
				let copy = Reflect.copy(that);
				copy.scheme = this1.scheme;
				return copy;
			};
		} else {
			let parts = {"path": Path_Impl_.join(this1.path, that.path), "payload": "", "scheme": this1.scheme, "query": that.query, "auth": this1.auth, "hosts": this1.hosts, "hash": that.hash};
			Url_Impl_.makePayload(parts);
			let this2 = parts;
			return this2;
		};
	}
	static makePayload(parts) {
		let payload = "";
		let _g = parts.scheme;
		let _g1 = parts.query;
		let _g2 = parts.payload;
		let _g3 = parts.path;
		let _g4 = parts.hosts;
		let _g5 = parts.hash;
		let _g6 = parts.auth;
		if (_g6 == null) {
			if (_g4.length != 0) {
				let v = _g4;
				payload += "//" + v.join(",");
			};
		} else if (_g4.length == 0) {
			let auth = _g6;
			payload += "//" + ((auth == null) ? "null" : (auth == null) ? "" : "" + auth + "@");
		} else {
			let auth = _g6;
			let v = _g4;
			payload += "//" + ((auth == null) ? "null" : (auth == null) ? "" : "" + auth + "@") + v.join(",");
		};
		payload += (parts.path == null) ? "null" : parts.path;
		let _g7 = parts.query;
		if (_g7 != null) {
			let v = _g7;
			payload += "?" + ((v == null) ? "null" : v);
		};
		let _g8 = parts.hash;
		if (_g8 != null) {
			let v = _g8;
			payload += "#" + v;
		};
		parts.payload = payload.toString();
	}
	static toString(this1) {
		if (this1.scheme == null) {
			return this1.payload;
		} else {
			return "" + this1.scheme + ":" + this1.payload;
		};
	}
	static fromString(s) {
		return Url_Impl_.parse(s);
	}
	static noop(_) {
	}
	static parse(s, onError = null) {
		if (s == null) {
			return Url_Impl_.parse("");
		};
		if (onError == null) {
			onError = Url_Impl_.noop;
		};
		s = StringTools.trim(s);
		if (StringTools.startsWith(s, "data:")) {
			let this1 = {"scheme": "data", "payload": HxOverrides.substr(s, 5, null), "hosts": []};
			return this1;
		};
		let FORMAT = new EReg("^(([a-zA-Z][a-zA-Z0-9\\-+.]*):)?((//(([^@/]+)@)?([^/?#]*))?([^?#]*)(\\?([^#]*))?(#(.*))?)$", "");
		let HOST = new EReg("^(\\[(.*)\\]|([^:]*))(:(.*))?$", "");
		FORMAT.match(s);
		let hosts;
		let _g = FORMAT.matched(7);
		if (_g == null) {
			hosts = [];
		} else {
			let v = _g;
			let _g1 = [];
			let _g2 = 0;
			let _g3 = v.split(",");
			while (_g2 < _g3.length) {
				let host = _g3[_g2];
				++_g2;
				HOST.match(host);
				let host1;
				let _g = HOST.matched(2);
				let _g4 = HOST.matched(3);
				if (_g == null) {
					let ipv4 = _g4;
					host1 = ipv4;
				} else if (_g4 == null) {
					let ipv6 = _g;
					host1 = "[" + ipv6 + "]";
				} else {
					onError("invalid host " + host);
					host1 = null;
				};
				let port;
				let _g5 = HOST.matched(5);
				if (_g5 == null) {
					port = null;
				} else {
					let v = _g5;
					let _g = Std.parseInt(v);
					if (_g == null) {
						onError("invalid port " + v);
						port = null;
					} else {
						let p = _g;
						port = p;
					};
				};
				_g1.push(Host_Impl_._new(host1, port));
			};
			hosts = _g1;
		};
		let path = FORMAT.matched(8);
		if (hosts.length > 0 && path.charAt(0) != "/") {
			path = "/" + path;
		};
		let this1 = {"scheme": FORMAT.matched(2), "payload": FORMAT.matched(3), "hosts": hosts, "auth": FORMAT.matched(6), "path": Path_Impl_.ofString(path), "query": FORMAT.matched(10), "hash": FORMAT.matched(12)};
		return this1;
	}
	static make(parts) {
		let parts1 = {"payload": "", "path": parts.path, "query": parts.query, "hosts": parts.hosts, "auth": parts.auth, "scheme": parts.scheme, "hash": parts.hash};
		Url_Impl_.makePayload(parts1);
		let this1 = parts1;
		return this1;
	}
	static fromRepresentation(v) {
		return Url_Impl_.parse(Representation_Impl_.get(v));
	}
	static get __name__() {
		return "tink._Url.Url_Impl_"
	}
	get __class__() {
		return Url_Impl_
	}
}

