import {DelegateProcessors_Impl_} from "../why/auth/plugins/tink/http/DelegateProcessors"
import {Status, StatusTools} from "../why/auth/Status"
import {AmplifyDelegate} from "../why/auth/AmplifyDelegate"
import {PromiseDelegate, User as User__1, DelegateBase} from "../why/Delegate"
import {Remote0} from "../tink/web/proxy/Remote0"
import {Scheme_Impl_} from "../tink/web/proxy/Remote"
import {Host_Impl_} from "../tink/url/Host"
import {AutoObservable, Computation_Impl_} from "../tink/state/internal/AutoObservable"
import {Observable_Impl_, Transform_Impl_} from "../tink/state/Observable"
import {CustomClient} from "../tink/http/Client"
import {Promise_Impl_} from "../tink/core/Promise"
import {Outcome} from "../tink/core/Outcome"
import {LazyConst} from "../tink/core/Lazy"
import {SyncFuture, Future_Impl_, SuspendableFuture} from "../tink/core/Future"
import {LinkPair} from "../tink/core/Callback"
import {GuestDelegate} from "./api/auth/GuestDelegate"
import {Option} from "../haxe/ds/Option"
import {Register} from "../genes/Register"

export const PublicApi = Register.global("$hxClasses")["letzbig.PublicApi"] = 
class PublicApi extends Register.inherits() {
	new(config) {
		let endpoint = Host_Impl_._new(config.host, config.port);
		let endpoint1 = Scheme_Impl_.fromString(config.scheme);
		let _g = [];
		let _g1 = 0;
		let _g2 = config.path.split("/");
		while (_g1 < _g2.length) {
			let v = _g2[_g1];
			++_g1;
			if (v != "") {
				_g.push(v);
			};
		};
		let _this = _g;
		let result = new Array(_this.length);
		let _g3 = 0;
		let _g4 = _this.length;
		while (_g3 < _g4) {
			let i = _g3++;
			let this1 = _this[i];
			result[i] = this1;
		};
		let endpoint2 = {"host": endpoint, "scheme": endpoint1, "path": result};
		this.auth = new PromiseDelegate(Promise_Impl_.next(new Remote0(config.client, endpoint2).configs().auth(), function (o) {
			let cognito = o.cognito;
			let __o0 = cognito;
			AmplifyDelegate.configure({"userPoolId": __o0.userPoolId, "region": __o0.region, "appClientId": __o0.appClientId});
			return new SyncFuture(new LazyConst(Outcome.Success(new CompositeDelegate(GuestDelegate.INST, new Delegate(AmplifyDelegate.instance)))));
		}));
		let this1 = config.client;
		let pipeline = DelegateProcessors_Impl_._new(this.auth);
		this.remote = new Remote0(CustomClient.create(this1, pipeline.before, pipeline.after), endpoint2);
	}
	static get __name__() {
		return "letzbig.PublicApi"
	}
	get __class__() {
		return PublicApi
	}
}


export const CompositeSignUpInfo = 
Register.global("$hxEnums")["letzbig.CompositeSignUpInfo"] = 
{
	__ename__: "letzbig.CompositeSignUpInfo",
	
	Guest: Object.assign((deviceId) => ({_hx_index: 0, __enum__: "letzbig.CompositeSignUpInfo", deviceId}), {_hx_name: "Guest", __params__: ["deviceId"]}),
	Amplify: Object.assign((credentials) => ({_hx_index: 1, __enum__: "letzbig.CompositeSignUpInfo", credentials}), {_hx_name: "Amplify", __params__: ["credentials"]})
}
CompositeSignUpInfo.__constructs__ = ["Guest", "Amplify"]
CompositeSignUpInfo.__empty_constructs__ = []

export const CompositeCredentials = 
Register.global("$hxEnums")["letzbig.CompositeCredentials"] = 
{
	__ename__: "letzbig.CompositeCredentials",
	
	Guest: {_hx_name: "Guest", _hx_index: 0, __enum__: "letzbig.CompositeCredentials"},
	Amplify: Object.assign((credentials) => ({_hx_index: 1, __enum__: "letzbig.CompositeCredentials", credentials}), {_hx_name: "Amplify", __params__: ["credentials"]})
}
CompositeCredentials.__constructs__ = ["Guest", "Amplify"]
CompositeCredentials.__empty_constructs__ = [CompositeCredentials.Guest]

export const CompositeProfile = 
Register.global("$hxEnums")["letzbig.CompositeProfile"] = 
{
	__ename__: "letzbig.CompositeProfile",
	
	Guest: Object.assign((v) => ({_hx_index: 0, __enum__: "letzbig.CompositeProfile", v}), {_hx_name: "Guest", __params__: ["v"]}),
	Amplify: Object.assign((v) => ({_hx_index: 1, __enum__: "letzbig.CompositeProfile", v}), {_hx_name: "Amplify", __params__: ["v"]})
}
CompositeProfile.__constructs__ = ["Guest", "Amplify"]
CompositeProfile.__empty_constructs__ = []

export const MappedUser = Register.global("$hxClasses")["letzbig.MappedUser"] = 
class MappedUser extends Register.inherits() {
	new(user, mapper) {
		this.user = user;
		this.profile = Observable_Impl_.map(user.profile, Transform_Impl_.plain(mapper));
	}
	getToken() {
		return this.user.getToken();
	}
	static get __name__() {
		return "letzbig.MappedUser"
	}
	static get __interfaces__() {
		return [User__1]
	}
	get __class__() {
		return MappedUser
	}
}


export const CompositeDelegate = Register.global("$hxClasses")["letzbig.CompositeDelegate"] = 
class CompositeDelegate extends Register.inherits(DelegateBase) {
	new(guest, amplify) {
		this.guest = guest;
		this.amplify = amplify;
		super.new(new AutoObservable(Computation_Impl_.sync(function () {
			let _g = Observable_Impl_.get_value(guest.status);
			let _g1 = Observable_Impl_.get_value(amplify.status);
			switch (_g1._hx_index) {
				case 0:
					switch (_g._hx_index) {
						case 0:
							return Status.Initializing;
							break
						case 2:
							let guestUser = _g.profile;
							return Status.SignedIn(new MappedUser(guestUser, CompositeProfile.Guest));
							break
						case 3:
							let e = _g.e;
							return Status.Errored(e);
							break
						default:
						return Status.Initializing;
						
					};
					break
				case 1:
					switch (_g._hx_index) {
						case 0:
							return Status.Initializing;
							break
						case 1:
							return Status.SignedOut;
							break
						case 2:
							let guestUser1 = _g.profile;
							return Status.SignedIn(new MappedUser(guestUser1, CompositeProfile.Guest));
							break
						case 3:
							let e1 = _g.e;
							return Status.Errored(e1);
							break
						
					};
					break
				case 2:
					let amplifyUser = _g1.profile;
					return Status.SignedIn(new MappedUser(amplifyUser, CompositeProfile.Amplify));
					break
				case 3:
					let _g2 = _g1.e;
					switch (_g._hx_index) {
						case 0:
							let e2 = _g2;
							return Status.Errored(e2);
							break
						case 2:
							let guestUser2 = _g.profile;
							return Status.SignedIn(new MappedUser(guestUser2, CompositeProfile.Guest));
							break
						case 3:
							let _g3 = _g.e;
							let e3 = _g2;
							return Status.Errored(e3);
							break
						default:
						let e4 = _g2;
						return Status.Errored(e4);
						
					};
					break
				
			};
		}), null, null));
	}
	signUp(info) {
		switch (info._hx_index) {
			case 0:
				let id = info.deviceId;
				return this.guest.signUp(id);
				break
			case 1:
				let creds = info.credentials;
				return this.amplify.signUp(creds);
				break
			
		};
	}
	signIn(credentials) {
		let _gthis = this;
		switch (credentials._hx_index) {
			case 0:
				return this.guest.signIn(null);
				break
			case 1:
				let credentials1 = credentials.credentials;
				return Promise_Impl_.next(this.signInAmplify(credentials1), function (amplifyUser) {
					let _g = Observable_Impl_.get_value(_gthis.guest.status);
					if (_g._hx_index == 2) {
						let _g1 = _g.profile;
						let f = function (_) {
							return _gthis.amplify.signOut();
						};
						return Promise_Impl_.next(Future_Impl_.flatMap(Promise_Impl_.next(amplifyUser.getToken(), (o=>Register.bind(o, o.link))(_gthis.guest)), function (o) {
							switch (o._hx_index) {
								case 0:
									let d = o.data;
									return new SyncFuture(new LazyConst(o));
									break
								case 1:
									let e = o.failure;
									return f(e);
									break
								
							};
						}), function (_) {
							return _gthis.guest.signOut();
						});
					} else {
						return Promise_Impl_.NOISE;
					};
				});
				break
			
		};
	}
	signOut() {
		return Promise_Impl_.noise(Promise_Impl_.inParallel([this.guest.signOut(), this.amplify.signOut()]));
	}
	signInAmplify(credentials) {
		let _gthis = this;
		let f = function (resolve, reject) {
			let binding = Observable_Impl_.getNext(_gthis.amplify.status, null, function (s) {
				if (s._hx_index == 2) {
					let amplifyUser = s.profile;
					return Option.Some(amplifyUser);
				} else {
					return Option.None;
				};
			}).handle(resolve);
			let b = _gthis.amplify.signIn(credentials).handle(function (o) {
				switch (o._hx_index) {
					case 0:
						let _g = o.data;
						break
					case 1:
						let e = o.failure;
						if (binding != null) {
							binding.cancel();
						};
						reject(e);
						break
					
				};
			});
			return new LinkPair(binding, b);
		};
		let this1 = new SuspendableFuture(function (cb) {
			return f(function (v) {
				cb(Outcome.Success(v));
			}, function (e) {
				cb(Outcome.Failure(e));
			});
		});
		let this2 = this1;
		return this2;
	}
	static get __name__() {
		return "letzbig.CompositeDelegate"
	}
	static get __super__() {
		return DelegateBase
	}
	get __class__() {
		return CompositeDelegate
	}
}


export const Delegate = Register.global("$hxClasses")["letzbig.Delegate"] = 
class Delegate extends Register.inherits(DelegateBase) {
	new(delegate) {
		this.delegate = delegate;
		super.new(Observable_Impl_.map(delegate.status, Transform_Impl_.plain(function (status) {
			return StatusTools.map(status, function (user) {
				return new User(user);
			});
		})));
	}
	signUp(info) {
		return this.delegate.signUp({"username": info.email, "password": info.password});
	}
	signIn(credentials) {
		return this.delegate.signIn({"username": credentials.email, "password": credentials.password});
	}
	signOut() {
		return this.delegate.signOut();
	}
	static get __name__() {
		return "letzbig.Delegate"
	}
	static get __super__() {
		return DelegateBase
	}
	get __class__() {
		return Delegate
	}
}


export const User = Register.global("$hxClasses")["letzbig.User"] = 
class User extends Register.inherits() {
	new(user) {
		this.user = user;
		this.profile = Observable_Impl_.map(user.profile, Transform_Impl_.plain(function (profile) {
			return {"id": profile.inst.get("sub"), "email": profile.inst.get("email")};
		}));
	}
	getToken() {
		return this.user.getToken();
	}
	static get __name__() {
		return "letzbig.User"
	}
	static get __interfaces__() {
		return [User__1]
	}
	get __class__() {
		return User
	}
}

