import {AutoObservable, Computation_Impl_} from "../../../../tink/state/internal/AutoObservable"
import {State_Impl_} from "../../../../tink/state/State"
import {PromisedTools} from "../../../../tink/state/Promised"
import {ConstObservable, Observable_Impl_, Transform_Impl_} from "../../../../tink/state/Observable"
import {ClassName_Impl_} from "../../../../tink/domspec/ClassName"
import {Signal_Impl_} from "../../../../tink/core/Signal"
import {Promise_Impl_} from "../../../../tink/core/Promise"
import {Outcome} from "../../../../tink/core/Outcome"
import {LazyConst} from "../../../../tink/core/Lazy"
import {SyncFuture, Future_Impl_, SuspendableFuture} from "../../../../tink/core/Future"
import {TypedError} from "../../../../tink/core/Error"
import {ReactType_Impl_} from "../../../../react/ReactType"
import * as React from "react"
import {TextButton} from "./TextButton"
import {Timer} from "../../../../haxe/Timer"
import {Exception} from "../../../../haxe/Exception"
import {Register} from "../../../../genes/Register"
import {Slot} from "../../../../coconut/ui/internal/Slot"
import {View} from "../../../../coconut/react/View"
import {Html} from "../../../../coconut/react/Html"
import {Annex} from "../../../../coconut/data/helpers/Annex"
import {Model} from "../../../../coconut/data/Model"
import {StringTools} from "../../../../StringTools"
import {Std} from "../../../../Std"
import {HxOverrides} from "../../../../HxOverrides"
import {Close, Pause, Stop, MicNone} from "@material-ui/icons"
import * as Styles from "@material-ui/core/styles"
import {Typography, Container, Modal} from "@material-ui/core"

export const Recorder = Register.global("$hxClasses")["letzbig.ui.component.app.Recorder"] = 
class Recorder extends Register.inherits(View) {
	new(__coco_data_) {
		this.model = new RecorderModel();
		this.__coco_classes = new Slot(this);
		let _gthis = this;
		this.__coco_open = new Slot(this, null, new ConstObservable(false, null));
		this.__coco_onComplete = new Slot(this, null, null);
		this.__coco_onClose = new Slot(this, null, null);
		this.__coco_duration = State_Impl_._new(0, null);
		this.__coco_status = State_Impl_._new(0, null);
		this.__initAttributes(__coco_data_);
		let snapshot = null;
		super.new(function () {
			return _gthis.__coco_render();
		}, null, null, null, null);
	}
	onComplete(a0) {
		(Observable_Impl_.get_value(this.__coco_onComplete))(a0);
	}
	onClose() {
		(Observable_Impl_.get_value(this.__coco_onClose))();
	}
	__coco_render() {
		let _gthis = this;
		let props = {"open": Observable_Impl_.get_value(this.__coco_open), "onClose": Register.bind(this, this.close)};
		let props1 = {"className": Observable_Impl_.get_value(this.__coco_classes).container, "maxWidth": "sm", "disableGutters": true};
		let attributes = {"className": Observable_Impl_.get_value(this.__coco_classes).content};
		let __r = [];
		__r.push(Html.h("div", {"className": Observable_Impl_.get_value(this.__coco_classes).closeButton, "onClick": Register.bind(this, this.close)}, [React.createElement(ReactType_Impl_.fromComp(Close), {})]));
		let _g = Observable_Impl_.get_value(this.model.__coco_recorder);
		switch (_g._hx_index) {
			case 0:
				break
			case 1:
				let recorder = _g.result;
				let attributes1 = {"className": Observable_Impl_.get_value(this.__coco_classes).titleContainer};
				let __r1 = [];
				let props2 = {"className": Observable_Impl_.get_value(this.__coco_classes).title, "variant": "h6"};
				let __r2 = [];
				switch (State_Impl_.get_value(this.__coco_status)) {
					case 0:
						__r2.push("Press to Start");
						break
					case 1:
						__r2.push("Recording...");
						break
					case 2:
						__r2.push(React.createElement(ReactType_Impl_.fromComp(Pause), {"color": "secondary", "style": {"fontSize": 20}}));
						__r2.push(" Paused");
						break
					case 3:
						__r2.push(React.createElement(ReactType_Impl_.fromComp(Stop), {"color": "secondary", "style": {"fontSize": 20}}));
						__r2.push(" Stopped");
						break
					
				};
				let children = [ReactType_Impl_.fromComp(Typography), props2].concat(__r2);
				__r1.push(React.createElement.apply(React, children));
				if (State_Impl_.get_value(this.__coco_status) != 0) {
					let props = {"className": Observable_Impl_.get_value(this.__coco_classes).timer, "variant": "h4"};
					let children = [Recorder.formatDuration(State_Impl_.get_value(this.__coco_duration))];
					let children1 = [ReactType_Impl_.fromComp(Typography), props].concat(children);
					__r1.push(React.createElement.apply(React, children1));
				};
				__r.push(Html.h("div", attributes1, __r1));
				let attributes2 = {"className": Observable_Impl_.get_value(this.__coco_classes).buttonContainer};
				let __r3 = [];
				if (State_Impl_.get_value(this.__coco_status) != 0) {
					__r3.push(Html.h("div", {"className": ClassName_Impl_.ofString("layer")}, [Html.h("div", {"className": ClassName_Impl_.add(Observable_Impl_.get_value(this.__coco_classes).bubble, ClassName_Impl_.when(Observable_Impl_.get_value(this.__coco_classes).bubbleAnimation, State_Impl_.get_value(this.__coco_status) == 1))}, null)]));
				};
				let attributes3 = {"className": ClassName_Impl_.ofString("layer")};
				let attributes4 = Observable_Impl_.get_value(this.__coco_classes);
				let _g1 = Register.bind(this, this.click);
				let recorder1 = recorder;
				let attributes5 = function () {
					_g1(recorder1);
				};
				__r3.push(Html.h("div", attributes3, [Html.h("div", {"className": attributes4.button, "onClick": attributes5}, [(State_Impl_.get_value(this.__coco_status) == 1) ? React.createElement(ReactType_Impl_.fromComp(Pause), {"style": {"fontSize": 40}}) : (State_Impl_.get_value(this.__coco_status) == 3) ? React.createElement(ReactType_Impl_.fromComp(Stop), {"style": {"fontSize": 40}}) : React.createElement(ReactType_Impl_.fromComp(MicNone), {"style": {"fontSize": 40}})])]));
				__r.push(Html.h("div", attributes2, __r3));
				let attributes6 = {"className": Observable_Impl_.get_value(this.__coco_classes).footerContainer};
				let children1;
				if (State_Impl_.get_value(this.__coco_status) == 2 || State_Impl_.get_value(this.__coco_status) == 3) {
					let children = new ConstObservable("DONE", null);
					let children2 = new AutoObservable(Computation_Impl_.sync(function () {
						let _g = Register.bind(_gthis, _gthis.done);
						let recorder1 = recorder;
						return function () {
							_g(recorder1);
						};
					}), null, null);
					children1 = TextButton.fromHxx({}, {"label": children, "onClick": children2});
				} else {
					children1 = null;
				};
				__r.push(Html.h("div", attributes6, [children1]));
				break
			case 2:
				let e = _g.error;
				let attributes7 = {"className": Observable_Impl_.get_value(this.__coco_classes).titleContainer};
				let props3 = {"className": Observable_Impl_.get_value(this.__coco_classes).title, "variant": "h6"};
				let children2 = [e.message];
				let children3 = [ReactType_Impl_.fromComp(Typography), props3].concat(children2);
				__r.push(Html.h("div", attributes7, [React.createElement.apply(React, children3)]));
				break
			
		};
		let children4 = [Html.h("div", attributes, __r)];
		let children5 = [ReactType_Impl_.fromComp(Container), props1].concat(children4);
		let children6 = React.createElement.apply(React, children5);
		let tmp = [ReactType_Impl_.fromComp(Modal), props].concat(children6);
		return React.createElement.apply(React, tmp);
	}
	click(recorder) {
		try {
			switch (State_Impl_.get_value(this.__coco_status)) {
				case 0:
					this.start(recorder);
					let param = 1;
					this.__coco_status.set(param);
					break
				case 1:
					let canResume = this.pause(recorder);
					let param1 = (canResume) ? 2 : 3;
					this.__coco_status.set(param1);
					break
				case 2:
					this.resume(recorder);
					let param2 = 1;
					this.__coco_status.set(param2);
					break
				case 3:
					break
				
			};
		}catch (_g) {
			let e = Exception.caught(_g);
			window.alert(Std.string(e));
		};
	}
	start(recorder) {
		this.baseDuration = 0;
		this.baseTimestamp = HxOverrides.now() / 1000;
		this.timer = new Timer(250);
		let _gthis = this;
		this.timer.run = function () {
			let param = _gthis.baseDuration + HxOverrides.now() / 1000 - _gthis.baseTimestamp | 0;
			_gthis.__coco_duration.set(param);
		};
		recorder.start();
	}
	resume(recorder) {
		this.baseDuration = State_Impl_.get_value(this.__coco_duration);
		this.baseTimestamp = HxOverrides.now() / 1000;
		this.timer = new Timer(250);
		let _gthis = this;
		this.timer.run = function () {
			let param = _gthis.baseDuration + HxOverrides.now() / 1000 - _gthis.baseTimestamp | 0;
			_gthis.__coco_duration.set(param);
		};
		recorder.resume();
	}
	
	/**
	* return canResume
	*/
	pause(recorder) {
		this.timer.stop();
		let param = this.baseDuration + HxOverrides.now() / 1000 - this.baseTimestamp | 0;
		this.__coco_duration.set(param);
		return recorder.pause();
	}
	close() {
		this.cancel(PromisedTools.orNull(Observable_Impl_.get_value(this.model.__coco_recorder)));
		this.onClose();
	}
	cancel(recorder = null) {
		if (this.timer != null) {
			this.timer.stop();
		};
		if (recorder != null && State_Impl_.get_value(this.__coco_status) != 0) {
			let this1 = recorder.stop();
			this1.eager();
		};
		this.reset();
	}
	done(recorder) {
		this.timer.stop();
		let _gthis = this;
		let param = this.baseDuration + HxOverrides.now() / 1000 - this.baseTimestamp | 0;
		this.__coco_duration.set(param);
		recorder.stop().handle(function (o) {
			switch (o._hx_index) {
				case 0:
					let blob = o.data;
					_gthis.onComplete(blob);
					_gthis.reset();
					break
				case 1:
					let e = o.failure;
					console.log("letzbig/ui/component/app/Recorder.hx:301:",e);
					break
				
			};
		});
	}
	reset() {
		let _g = this.model;
		_g.set_revision(State_Impl_.get_value(_g.__coco_revision) + 1);
		let param = 0;
		this.__coco_status.set(param);
		this.__coco_duration.set(0);
	}
	__initAttributes(attributes) {
		this.__coco_open.setData(attributes.open);
		this.__coco_onComplete.setData(attributes.onComplete);
		this.__coco_onClose.setData(attributes.onClose);
		let value = attributes.classes;
		this.__coco_classes.setData(new ConstObservable(value, null));
	}
	static styles(theme) {
		let tmp = {"padding": theme.spacing(2), "display": "flex", "flexDirection": "column", "justifyContent": "center", "height": "100vh"};
		let tmp1 = {"position": "relative", "borderRadius": theme.spacing(1), "backgroundColor": "white"};
		let tmp2 = {"margin": theme.spacing(2), "alignSelf": "flex-start", "display": "flex", "flexDirection": "column", "alignItems": "center", "justifyContent": "center", "width": theme.spacing(4), "height": theme.spacing(4), "borderRadius": theme.spacing(2), "backgroundColor": "rgba(130,130,133,.2)", "color": "rgb(130,130,133)", "cursor": "pointer"};
		let tmp3 = {"marginTop": theme.spacing(2), "fontWeight": 500};
		let tmp4 = theme.spacing(2);
		let tmp5 = theme.spacing(2);
		return {"@keyframes example": {"0%": {"transform": "scale(1, 1)"}, "20%": {"transform": "scale(1.05, 1.05)"}, "35%": {"transform": "scale(.95, .95)"}, "60%": {"transform": "scale(1.03, 1.03)"}, "85%": {"transform": "scale(.97, .97)"}, "100%": {"transform": "scale(1, 1)"}}, "container": tmp, "content": tmp1, "closeButton": tmp2, "titleContainer": {"height": 80, "display": "flex", "flexDirection": "column", "alignItems": "center", "justifyContent": "center"}, "title": {"color": "rgb(130,130,133)", "fontWeight": 400, "display": "flex", "alignItems": "center"}, "timer": tmp3, "buttonContainer": {"position": "relative", "height": 200, "marginTop": tmp4, "marginBottom": tmp5, "&>.layer": {"position": "absolute", "display": "flex", "flexDirection": "column", "alignItems": "center", "justifyContent": "center", "top": 0, "right": 0, "bottom": 0, "left": 0}}, "bubble": {"width": 176, "height": 176, "borderRadius": 88, "backgroundColor": theme.palette.secondary.light}, "bubbleAnimation": {"animation": "$" + "example 4s infinite alternate"}, "button": {"width": 80, "height": 80, "borderRadius": 40, "backgroundColor": theme.palette.secondary.main, "display": "flex", "flexDirection": "column", "alignItems": "center", "justifyContent": "center", "color": "white", "cursor": "pointer"}, "footerContainer": {"height": 60, "display": "flex", "flexDirection": "column", "alignItems": "center", "justifyContent": "center"}};
	}
	static formatDuration(v) {
		let m = v / 60 | 0;
		let s = v % 60;
		return StringTools.lpad("" + m, "0", 2) + ":" + StringTools.lpad("" + s, "0", 2);
	}
	static fromHxx(hxxMeta, attributes) {
		return {"$$typeof": View.TRE, "type": Recorder.__hoc, "props": attributes, "key": hxxMeta.key, "ref": (!hxxMeta.ref) ? null : hxxMeta.ref};
	}
	static get __name__() {
		return "letzbig.ui.component.app.Recorder"
	}
	static get __super__() {
		return View
	}
	get __class__() {
		return Recorder
	}
}


Recorder.__hoc = (Styles.withStyles(Recorder.styles))(Recorder)
export const RecorderModel = Register.global("$hxClasses")["letzbig.ui.component.app._Recorder.RecorderModel"] = 
class RecorderModel extends Register.inherits() {
	new() {
		this._updatePerformed = Signal_Impl_.trigger();
		this.__coco_revision = State_Impl_._new(0, null, null, null);
		let _gthis = this;
		let before = AutoObservable.cur;
		AutoObservable.cur = null;
		let ret = new AutoObservable(Computation_Impl_.async(function () {
			if (!("mediaDevices" in window.navigator)) {
				return new SyncFuture(new LazyConst(Outcome.Failure(new TypedError(null, "MediaDevices not supported on this browser", {"fileName": "letzbig/ui/component/app/Recorder.hx", "lineNumber": 323, "className": "letzbig.ui.component.app._Recorder.RecorderModel", "methodName": "new"}))));
			} else if (!("MediaRecorder" in window)) {
				return new SyncFuture(new LazyConst(Outcome.Failure(new TypedError(null, "MediaRecorder not supported on this browser", {"fileName": "letzbig/ui/component/app/Recorder.hx", "lineNumber": 325, "className": "letzbig.ui.component.app._Recorder.RecorderModel", "methodName": "new"}))));
			} else {
				State_Impl_.get_value(_gthis.__coco_revision);
				return Promise_Impl_.next(Future_Impl_.ofJsPromise(window.navigator.mediaDevices.getUserMedia({"audio": true})), function (stream) {
					let recorder = new MediaRecorder(stream);
					let chunks = [];
					let onDataAvailable = function (e) {
						chunks.push(e.data);
					};
					recorder.addEventListener("dataavailable", onDataAvailable);
					if (Register.bind(recorder, recorder.pause) == null || Register.bind(recorder, recorder.resume) == null) {
						let resume = function () {
							if (recorder.state == "inactive") {
								recorder.start(500);
							};
						};
						return new SyncFuture(new LazyConst(Outcome.Success({"start": resume, "pause": function () {
							if (recorder.state == "recording") {
								recorder.stop();
							};
							return false;
						}, "resume": resume, "stop": function () {
							let f = function (resolve, reject) {
								let onStop = function () {
									resolve(new Blob(chunks, {"type": recorder.mimeType}));
									let _g = 0;
									let _g1 = stream.getTracks();
									while (_g < _g1.length) {
										let track = _g1[_g];
										++_g;
										track.stop();
									};
								};
								if (recorder.state == "inactive") {
									onStop();
								} else {
									recorder.addEventListener("error", function (e) {
										reject(TypedError.withData(500, e.message, e, {"fileName": "letzbig/ui/component/app/Recorder.hx", "lineNumber": 354, "className": "letzbig.ui.component.app._Recorder.RecorderModel", "methodName": "new"}));
									});
									recorder.addEventListener("stop", onStop);
									recorder.stop();
								};
								return null;
							};
							let this1 = new SuspendableFuture(function (cb) {
								return f(function (v) {
									cb(Outcome.Success(v));
								}, function (e) {
									cb(Outcome.Failure(e));
								});
							});
							let this2 = this1;
							return this2;
						}})));
					} else {
						let _g = Register.bind(recorder, recorder.start);
						let timeSlice = 500;
						return new SyncFuture(new LazyConst(Outcome.Success({"start": function () {
							_g(timeSlice);
						}, "pause": function () {
							recorder.pause();
							return true;
						}, "resume": Register.bind(recorder, recorder.resume), "stop": function () {
							let f = function (resolve, reject) {
								recorder.addEventListener("error", function (e) {
									reject(TypedError.withData(500, e.message, e, {"fileName": "letzbig/ui/component/app/Recorder.hx", "lineNumber": 371, "className": "letzbig.ui.component.app._Recorder.RecorderModel", "methodName": "new"}));
								});
								recorder.addEventListener("stop", function () {
									resolve(new Blob(chunks, {"type": recorder.mimeType}));
									let _g = 0;
									let _g1 = stream.getTracks();
									while (_g < _g1.length) {
										let track = _g1[_g];
										++_g;
										track.stop();
									};
								});
								recorder.stop();
								return null;
							};
							let this1 = new SuspendableFuture(function (cb) {
								return f(function (v) {
									cb(Outcome.Success(v));
								}, function (e) {
									cb(Outcome.Failure(e));
								});
							});
							let this2 = this1;
							return this2;
						}})));
					};
				});
			};
		}), null, null);
		AutoObservable.cur = before;
		this.__coco_recorder = ret;
		this.__coco_transitionCount = State_Impl_._new(0);
		this.errorTrigger = Signal_Impl_.trigger();
		this.transitionErrors = this.errorTrigger;
		this.annex = new Annex(this);
		this.observables = {"recorder": this.__coco_recorder, "isInTransition": Observable_Impl_.map(this.__coco_transitionCount, Transform_Impl_.plain(function (count) {
			return count > 0;
		}))};
	}
	set_revision(param) {
		this._updatePerformed.handlers.invoke({"revision": param});
		this.__coco_revision.set(param);
		return param;
	}
	static get __name__() {
		return "letzbig.ui.component.app._Recorder.RecorderModel"
	}
	static get __interfaces__() {
		return [Model]
	}
	get __class__() {
		return RecorderModel
	}
}

