import {Remote9} from "./Remote9"
import {RemoteBase, RemoteEndpoint_Impl_} from "./Remote"
import {Portion_Impl_} from "../../url/Portion"
import {Register} from "../../../genes/Register"

export const Remote14 = Register.global("$hxClasses")["tink.web.proxy.Remote14"] = 
class Remote14 extends Register.inherits(RemoteBase) {
	new(client, endpoint) {
		super.new(client, endpoint);
	}
	ofId(id) {
		return new Remote9(this.client, RemoteEndpoint_Impl_.sub(this.endpoint, {"path": [Portion_Impl_.ofString(id)], "query": [], "headers": [].concat([])}));
	}
	static get __name__() {
		return "tink.web.proxy.Remote14"
	}
	static get __super__() {
		return RemoteBase
	}
	get __class__() {
		return Remote14
	}
}

