import {List_Impl_} from "../../tink/pure/List"
import {Representation_Impl_} from "../../tink/json/Representation"
import {Register} from "../../genes/Register"

export const Media_Impl_ = Register.global("$hxClasses")["letzbig.types._Media.Media_Impl_"] = 
class Media_Impl_ {
	static ofRepresentation(rep) {
		return List_Impl_.fromArray(Representation_Impl_.get(rep));
	}
	static get __name__() {
		return "letzbig.types._Media.Media_Impl_"
	}
	get __class__() {
		return Media_Impl_
	}
}

