import {FetchResponse_Impl_, Fetch} from "../../tink/http/Fetch"
import {Progress_Impl_} from "../../tink/core/Progress"
import {Register} from "../../genes/Register"

export const Uploader = Register.global("$hxClasses")["why.fs.Uploader"] = 
class Uploader {
	static upload(header, body) {
		return Progress_Impl_.make(function (progress, finish) {
			let url = header.url;
			let header1 = header.method;
			let _g = [];
			let _g1_current = 0;
			let _g1_array = header.fields;
			while (_g1_current < _g1_array.length) {
				let f = _g1_array[_g1_current++];
				_g.push(f);
			};
			return FetchResponse_Impl_.all(Fetch.fetch(url, {"method": header1, "headers": _g, "body": body, "handlers": {"upload": function (v) {
				progress(v.a, v.b);
			}}})).handle(finish);
		});
	}
	static get __name__() {
		return "why.fs.Uploader"
	}
	get __class__() {
		return Uploader
	}
}

